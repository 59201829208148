import { Injectable, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppTitleService implements OnDestroy {

  private titleSubject =
        new BehaviorSubject<{ title: string }>(
                { title: 'Inicio' }
        );
    private titleChanges = this.titleSubject.asObservable();

  constructor(
    private title: Title
  ) { }

  ngOnDestroy(): void {
    this.titleSubject.unsubscribe();
  }

  public setTitle(newTitle: string): void {
    this.title.setTitle(newTitle + ' | CaliWallet');
    this.titleSubject.next({ title: newTitle });
  }

  public listenChanges(): Observable<{ title: string }> {
    if (this.titleSubject.closed) {
      this.resetSubscription();
    }

    return this.titleChanges;
  }

  private resetSubscription(): void {
    this.titleSubject =
        new BehaviorSubject<{ title: string }>(
          { title: 'Mis tarjetas | CaliWallet' }
        );

    this.titleChanges = this.titleSubject.asObservable();
  }

  // public get currentTitle(): string {
  //   return this.titleSubject.value.title;
  // }
}
