import { Injectable, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Overlay } from '@angular/cdk/overlay';
import {
    BreakpointObserver,
    BreakpointState,
    Breakpoints
} from '@angular/cdk/layout';

export interface MatToolbarInterface {
    title: string;
    action: string;
    route: string;
    icon: string;
    color: string;
    isButtonVisible: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class DashboardService implements OnInit {
    public executeFn$ = new Subject<string>();
    // public routerStack: string[] = [];
    title: string;
    isMobile$: Subject<boolean> = new Subject();
    isMobile: boolean;
    // private overlayTopRef = this.cdkOverlayCreate();
    public matMenuObs$: Subject<MatToolbarInterface> = new Subject<
        MatToolbarInterface
    >();
    // public backNavigator$: Subject<string> = new Subject<string>();
    // public toolbarShadow$: Subject<string> = new Subject<string>();
    spin$: Subject<boolean> = new Subject();

    // progress$: Subject<boolean> = new Subject();

    constructor(
        private overlay: Overlay,
        private breakpointObserver: BreakpointObserver,
    ) {
        this.layoutSize();
    }

    ngOnInit() {}

    public setTitle(title: string) {
        this.title = title;
    }

    // Crea el overlay para el spinner
    // private cdkOverlayCreate() {
    //     return this.overlay.create({
    //         hasBackdrop: true,
    //         positionStrategy: this.overlay
    //             .position()
    //             .global()
    //             .centerHorizontally()
    //             .centerVertically()
    //     });
    // }

    // private showSpinner() {
    //     this.overlayTopRef.attach(new ComponentPortal(MatSpinner));
    // }
    //
    // private stopSpinner() {
    //     this.overlayTopRef.detach();
    // }

    // private showProgress() {
    //     this.overlayTopRef.attach(new ComponentPortal(MatProgressBar));
    // }
    //
    // private stopProgress() {
    //     this.overlayTopRef.detach();
    // }

    private layoutSize(): void {
        this.breakpointObserver
            .observe(Breakpoints.Handset)
            .subscribe((state: BreakpointState) => {
                this.isMobile = state.matches;
                this.isMobile$.next(state.matches);
            });
    }
}
