import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MCConfigGroup} from '../../models/mc.group.config.model';
import {MCAddress} from '../../models/mc.address.model';
import {MatDialog} from '@angular/material/dialog';
import {ProfileDeleteModalComponent} from '../../profile-delete-modal/profile-delete-modal.component';
import {AddressAutofillComponent} from '../../address-autofill/address-autofill.component';
import {ProfileManagerService} from '../../profile-manager.service';

@Component({
    selector: 'app-address-item',
    templateUrl: './address-item.component.html',
    styleUrls: ['./address-item.component.scss']
})
export class AddressItemComponent implements OnInit {

    @Input() address: MCAddress = new MCAddress();
    @Input() scope = 1;
    @Input() config: MCConfigGroup | null;
    @Input() allowUpdate = true;
    @Input() allowDelete = true;

    public showMore = false;

    public addressComponent = AddressAutofillComponent;

    @Output() requestDeletion = new EventEmitter<any>();

    constructor(
        private dialog: MatDialog,
        public profileManager: ProfileManagerService,
    ) {}

    ngOnInit(): void {}

    deleteDialog(): void {
        const dialog = this.dialog.open(ProfileDeleteModalComponent);
        const instance = dialog.componentInstance;

        instance['type'] = this.config.type;
        instance['payload'] = this.address;
        instance['model'] = this.config;

        dialog.afterClosed().subscribe(result => {
            const { canceled } = result;

            if (canceled) {
                return;
            }

            this.requestDeletion.emit(result);
        });
    }
}
