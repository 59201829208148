<div class="container">
    <div class="add-info-header">
        <div class="d-flex flex-row align-items-center">
            <span class="material-symbols-rounded">home</span>
            <div class="ms-3">
                <p class="m-0">
                    <strong>{{ (action === 'UP') ? 'Editar' : 'Agregar' }} Dirección</strong>
                </p>
                <p class="small-label">Presiona confirmar para {{
                    (action === 'UP')
                        ? 'guardar cambios.'
                        : 'agregar esta dirección.'
                    }}
                </p>
            </div>
        </div>
        <div>
          <button mat-dialog-close
              class="dialog-close"
              aria-hidden="true">
          </button>
        </div>
    </div>
    <form [formGroup]="addressForm" class="edition-form">
        <div class="edition-form-content">
            <div class="edition-item">
                <mat-label class="small-label">
                    Calle
                </mat-label>
                <mat-form-field floatLabel="never">
                    <input matInput
                        type="text"
                        id="calle"
                        formControlName="calle">
                </mat-form-field>
            </div>
            <div class="edition-item">
                <mat-label class="small-label">
                    Numero Ext.
                </mat-label>
                <mat-form-field floatLabel="never">
                    <input matInput
                        type="text"
                        id="ext"
                        formControlName="numero_ext">
                </mat-form-field>
            </div>
            <div class="edition-item">
                <mat-label class="small-label">
                    Numero Int. (Opcional)
                </mat-label>
                <mat-form-field floatLabel="never">
                    <input matInput
                        type="text"
                        id="int"
                        formControlName="numero_int">
                </mat-form-field>
            </div>
            <div class="edition-item">
                <mat-label class="small-label">
                    Codigo Postal
                </mat-label>
                <mat-form-field floatLabel="never">
                    <input matInput
                        inputmode="numeric"
                        formControlName="codigo_postal">
                </mat-form-field>
            </div>
            <div class="edition-item">
                <mat-label class="small-label">
                    Colonia o fraccionamiento
                </mat-label>
                <mat-form-field floatLabel="never">
                    <mat-select formControlName="colonia">
                        <mat-option *ngFor="let suburb of suburbs" [value]="suburb.nombre | uppercase">
                            {{ suburb.nombre | uppercase }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="edition-item">
                <mat-label class="small-label">
                    Ciudad
                </mat-label>
                <mat-form-field floatLabel="never">
                    <mat-select formControlName="ciudad_id">
                        <mat-option *ngFor="let city of cities" [value]="city.ciudad">
                            {{ city.nombre | uppercase }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="edition-item">
                <mat-label class="small-label">
                    Entidad federativa
                </mat-label>
                <mat-form-field floatLabel="never">
                    <mat-select formControlName="estado_id">
                        <mat-option *ngFor="let state of states" [value]="state.estado_pais">
                            {{ state.nombre | uppercase }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </form>
    <div class="add-info-bottom multiple-separation-bottom-actions">
        <button mat-flat-button
                class="attenuated-accent-primary-btn w-100"
                type="button"
                (click)="onSubmit()">
            <strong>{{ (action === 'UP') ? 'Confirmar' : 'Agregar' }}</strong>
        </button>
        <button mat-flat-button
            [mat-dialog-close]="result"
            class="attenuated-accent-red-btn w-100"
            type="button">
                <strong>Cancelar</strong>
        </button>
        <button *ngIf="action === 'UP'"
            mat-flat-button
            class="attenuated-accent-red-btn w-100"
            type="button"
            (click)="openDeletionAddressModal()">
                <span class="material-symbols-rounded">delete</span>
                <strong>Eliminar</strong>
        </button>
    </div>

    <ng-template #confirmDeletion>
        <div class="p-3">
            <div class="add-info-header">
                <div class="d-flex flex-row align-items-center">
                    <span class="material-symbols-rounded">delete</span>
                    <div class="ms-3">
                        <p class="m-0"><strong>¿Desea eliminar la siguiente Direccion?</strong></p>
                        <p class="small-label m-0">Esta acción no puede deshacerse.</p>
                    </div>
                </div>
            </div>
            <p class="py-4"><strong>{{ payload[model.type] }}</strong></p>
<!--            <p class="small-label text-danger alert alert-danger" *ngIf="model.group === 'contact'">-->
<!--                Los datos de contacto como usuario no pueden ser eliminados.-->
<!--            </p>-->
            <div class="multiple-separation-bottom-actions">
                <button mat-flat-button
                        class="btn btn-danger w-100"
                        (click)="deleteAddress()">
                    <strong>Eliminar</strong>
                </button>
                <button mat-flat-button
                    (click)="dialog.closeAll()"
                    class="bg-transparent accent-color w-100">
                    <strong>Cancelar</strong>
                </button>
            </div>
        </div>
    </ng-template>
</div>