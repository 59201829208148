import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-rfc',
  templateUrl: './rfc.component.html',
  styleUrls: ['./rfc.component.css']
})
export class RFCComponent {

  @Output() enterEvent = new EventEmitter<any>();
  @Input() viewElementRef: ElementRef<any>;

  constructor(public container: ControlContainer) { }

  get invalidRfc(): boolean {
    return (this.container.control.get('rfc').invalid && this.container.control.get('rfc').touched)
      || (!!this.container.control.errors && this.container.control.errors.invalidRfc);
  }

  onEnterEvent(): void {
    this.enterEvent.emit();
  }
}
