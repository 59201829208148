import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DashboardService} from '../../../dashboard.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {SPJS100Out} from '../../../models/sp_js_100';
import {VirtualCardComponent} from '../../../virtual-card/virtual-card.component';
import {PinSessionManagerService} from '../../../pin-session-manager.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {decrypt, encrypt, mobile} from 'src/app/consts/utilities';
import {CardService} from '../../../card.service';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss'],
    animations: [
        trigger('fade', [
            state(
                'void',
                style({ opacity: 0, transform: 'translateX(-50px)' })
            ),
            transition(':enter, :leave', [
                animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')
            ])
        ])
    ]
})
export class CardComponent implements OnInit, OnChanges, OnDestroy {
    cardAmount: number;
    viewDigitalCard = false;
    viewCardLast4 = false;

    @Input() card: SPJS100Out;
    @Input() cardIndex: number;

    dialogRef: MatDialogRef<ElementRef>;

    @ViewChild('alertDialog', { static: true }) alertDialog: TemplateRef<any>;
    @ViewChild('barcodeModal', { static: true }) barcodeModal: TemplateRef<any>;
    @ViewChild('savePinModal', { static: true }) savePinModal: TemplateRef<any>;
    @ViewChild('resetPinModal', { static: true }) resetPinModal: TemplateRef<any>;

    @ViewChild('virtualCard', { static: true }) virtualCard: VirtualCardComponent;

    @ViewChild('cardBottomSheet', { static: true }) cardBottomSheet: TemplateRef<any>;
    @ViewChild('cardRemoveDialog', { static: true }) cardRemoveSheet: TemplateRef<any>;

    @Input() index: Number;
    @Input() cardNoSelected: string;
    @Input() pinValidationIn: boolean;

    // Emit value to parent >> Parent send value to another cards
    @Output() genericTermPref = new EventEmitter<number>();
    @Output() selectedCard = new EventEmitter<string>();
    @Output() requestedDeletion = new EventEmitter<any>();

    // Child receive terms pref value from parent here
    @Input() newTermPref: number;

    constructor(
        public router: Router,
        public renderer: Renderer2,
        private bottomSheet: MatBottomSheet,
        public dialog: MatDialog,
        public dashboard: DashboardService,
        private cardService: CardService,
        private pinSessionService: PinSessionManagerService
    ) {}

    ngOnInit() {
        this.newTermPref = this.card.sw_terminos;
        switch (this.card.id_tc) {
            case 1:
                this.cardAmount = this.card.sdo_vales;
                break;
            case 2:
                this.cardAmount = this.card.sdo_certificado;
                break;
            case 3:
                this.cardAmount = this.card.sdo_tregalo;
                break;
            case 4:
                if (this.card.req_sdo_tclub === 2) {
                    this.cardAmount = this.card.sdo_tclub;
                }
                break;
            case 5:
                this.cardAmount = this.card.sdo_monedero;
                break;
            case 6:
                this.cardAmount = this.card.sdo_vales;
                break;
        }

        this.pinSessionService.listenChanges().subscribe((value: { isAuthenticated: boolean }) => {
            this.pinValidationIn = value.isAuthenticated;
            this.viewDigitalCard = value.isAuthenticated;
        });
    }

    ngOnChanges(changes?: SimpleChanges) {
        const termPref = changes.newTermPref.currentValue;

        if (termPref) {
            this.card.sw_terminos = termPref;
        }
    }

    ngOnDestroy(): void {
        this.pinSessionService.ngOnDestroy();
    }

    updateTermPref(event) {
        if (event) {
            this.card.sw_terminos = event;
            this.genericTermPref.emit(event);
            // console.log(`Evento: ${event}`);
        }
    }

    openCardBottom(): void {
        this.bottomSheet.open(this.cardBottomSheet);
    }

    openCardRemoveDialog(): void {
        if (this.mobile) {
            this.bottomSheet.open(this.cardRemoveSheet);
        }

        if (!this.mobile) {
            this.dialog.open(this.cardRemoveSheet);
        }
    }

    public removeCard(): void {
        this.dismissDialog();

        const { id } = this.card;

        this.cardService.deleteCardByNumber(id).subscribe(
            (response) => this.onRemovedCardResponse(response),
            (error) => this.onRemovedCardError(error)
        );
    }

    onRemovedCardResponse(response: any): void {
        const { data } = response;
        const { codigo } = data;

        if (codigo === HttpStatusCode.Ok) {
            this.requestedDeletion.emit(this.card);
        }
    }

    onRemovedCardError(error: HttpErrorResponse): void {
        const { status } = error;

        if (status === HttpStatusCode.InternalServerError) {}
    }

    toDigitalCard(): void {
        this.closeBottomMenu();
        this.virtualCard.open(this.card.sw_terminos);
    }

    goToCardDetails(cardId) {
        this.closeBottomMenu();
        sessionStorage.setItem('detalles_tarjeta', JSON.stringify(this.card));
        this.router.navigate([`/app/dashboard/cards/details/card/${cardId}`]);
    }

    goToCardReport(cuenta_r): void {
        this.closeBottomMenu();
        const route = `/app/dashboard/cards/report/card/${cuenta_r}`;

        sessionStorage.setItem('reporte_tarjeta', JSON.stringify(this.card));
        sessionStorage.setItem('reporte_tarjeta_saldo', `${this.cardAmount}`);

        this.router.navigate([route]);
    }

    validateReportFeature(): boolean {
        return (this.card.id_tc === 1 || this.card.id_tc === 6);
    }

    decrypt(value: string): string {
        return decrypt(value);
    }

    setPinValidationOut(value): void {
        this.pinSessionService.setAuthState(value);
    }

    closeBottomMenu(): void {
        this.bottomSheet.dismiss();
    }

    dismissDialog(): void {
        if (this.mobile) {
            this.bottomSheet.dismiss();
        }

        if (!this.mobile) {
            this.dialog.closeAll();
        }
    }

    get mobile(): boolean {
        return mobile();
    }
}
