import { Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import {AbstractControl, ControlContainer, Validators} from '@angular/forms';
import { AppUtils } from 'src/app/app-utils';
import { IconType } from '../dashboard-banner/dashboard-banner.component';

export enum InputType {
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC'
}

@Component({
  selector: 'app-responsive-input',
  templateUrl: './responsive-text-input.component.html',
  styleUrls: ['./responsive-text-input.component.scss']
})
export class ResponsiveTextInputComponent implements OnInit {

  // Directives
  @Input() id = '';
  @Input() controlName = '';
  @Input() type = 'TEXT';
  @Input() mask = '';
  @Input() placeholder = '';
  @Input() showIcon = false;
  @Input() enableLabel = false;
  @Input() labelText = 'New Input';
  @Input() icon = null;
  @Input() iconType: IconType = IconType.MATERIAL_SYMBOL;
  @Input() enableEnterEvent = false;
  @Input() viewElementRef: ElementRef<any> = null;
  @Input() pattern = '';
  @Input() minLength = 0;
  @Input() maxLength = 0;

  // Events
  @Output() enter = new EventEmitter<boolean>();

  constructor(
    private renderer: Renderer2,
    public controlContainer: ControlContainer
  ) {}

  ngOnInit() {
      const vals = [];

      if (this.minLength > 0) {
          vals.push(Validators.minLength(this.minLength));
      }

      if (this.maxLength > 0) {
          vals.push(Validators.maxLength(this.maxLength));
      }

      this.controlContainer.control.addValidators(vals);
  }

  onFocusedEvent(): void {
    if (AppUtils.mobile()) {
      this.renderer.addClass(this.viewElementRef.nativeElement, 'fit-height');
    }
  }

  onFocusedOutEvent(): void {
    if (AppUtils.mobile()) {
      this.renderer.removeClass(this.viewElementRef.nativeElement, 'fit-height');
    }
  }

  onEnterEvent(): void {
    this.enter.emit();
  }

}
