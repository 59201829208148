import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {UserIntegrityService} from '../../user-integrity.service';
import {LinkCardService} from '../../link.card.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {mobile} from '../../consts/utilities';
import {HttpStatusCode} from '@angular/common/http';
import {AppTitleService} from '../../app-title.service';

@Component({
    selector: 'app-required-card-step',
    templateUrl: './required-card-step.component.html',
    styleUrls: ['./required-card-step.component.scss']
})
export class RequiredCardStepComponent implements OnInit {
    public userOkAnswer = false;
    public userNoAnswer = false;

    @ViewChild('addedDigitalCard', { static: true })
    addedCardRef: TemplateRef<any>;

    @ViewChild('failedToGenCard', { static: true })
    failedGenCardRef: TemplateRef<any>;

    constructor(
        private router: Router,
        private titleService: AppTitleService,
        private userIntegrityService: UserIntegrityService,
        private linkCardService: LinkCardService,
        private bottomSheet: MatBottomSheet,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.titleService.setTitle('Agregar tarjeta');
    }

    onPrimaryAction(): void {
        this.userOkAnswer = true;
    }

    onSecondaryAction(): void {
        // this.userNoAnswer = true;
        this.onSkipCard();
    }

    private onSkipCard(): void {
        this.linkCardService.generateDigitalCard().subscribe(
            (response) => {
                // On generated card success
                const { statusCode } = response;

                if (statusCode !== HttpStatusCode.Ok) {
                    return;
                }

                if (mobile()) {
                    this.bottomSheet.open(this.addedCardRef);
                }

                if (!mobile()) {
                    this.dialog.open(this.addedCardRef);
                }
            },
            (error) => {
                // On generated card error
                if (mobile()) {
                    this.bottomSheet.open(this.failedGenCardRef);
                }

                if (!mobile()) {
                    this.dialog.open(this.failedGenCardRef);
                }
            }
        );
    }

    public userHasAddedCardsOk(): void {
        this.dismissMessage();

        const checkerValue = this.userIntegrityService.currentValue;
        const { email, zip } = checkerValue;

        if (zip === 1 && email === 1) {
            this.router.navigate(['/app/dashboard/cards']);
            return;
        }

        this.userIntegrityService.checkNow();
    }

    public dismissMessage(): void {
        if (mobile()) {
            this.bottomSheet.dismiss();
        }

        if (!mobile()) {
            this.dialog.closeAll();
        }
    }
}
