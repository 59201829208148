import {Component, Input, OnInit, TemplateRef} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';

@Component({
    selector: 'app-bottom-menu',
    templateUrl: './bottom-menu.component.html',
    styleUrls: ['./bottom-menu.component.scss']
})
export class BottomMenuComponent implements OnInit {
    @Input() menu: TemplateRef<any>;

    constructor(
        private matBottomSheet: MatBottomSheet,
    ) {
    }

    ngOnInit(): void {
    }

    public openMenu(): void {
        this.matBottomSheet.open(this.menu);
        // this.sidenav.open().then(_ => this.panelOpenState = true);
    }

}
