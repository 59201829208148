import { Component, OnInit } from '@angular/core';
import {AppTitleService} from '../../app-title.service';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit {

  constructor(private titleService: AppTitleService) {
      this.titleService.setTitle('Cupones');
  }

  ngOnInit(): void {
  }

}
