import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
// import { AppUtils } from 'src/app/app-utils';
// import { AccessToken } from 'src/app/models/accessToken';

@Component({
  selector: 'app-decision-component',
  templateUrl: './decision-component.component.html',
  styleUrls: ['./decision-component.component.scss']
})
export class DecisionComponentComponent implements OnInit {

  @Input() userName: string;
  // decodeToken: AccessToken;

  @Output() byCard = new EventEmitter<boolean>();

  constructor() {
    // this.userName = AppUtils.getUserFromToken().sub;
  }

  ngOnInit() {}

  getRegisterType(value: boolean): void {
    this.byCard.emit(value);
  }

}
