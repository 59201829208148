<!-- <div (click)="onVirtualClick(card.sw_terminos)">
    <span class="material-symbols-rounded">barcode_scanner</span>
</div> -->

<!-- Modal para validar pin -->
<ng-template #barcodeModal>
    <div *ngIf="!pinValidationIn && !code_sent" class="pin-container">
        <p>Ingresa tu <strong>PIN</strong> para continuar</p>
        <form [formGroup]="pinGroup">
            <mat-form-field floatLabel="never" class="w-100 p-0">
                <mat-label>PIN</mat-label>
                <input matInput
                       id="pin"
                       type="password"
                       inputmode="numeric"
                       mask="0000"
                       class="input-pin"
                       formControlName="pin"
                       (keyup.enter)="pinValidation()">
                <button #pinBtn
                        mat-icon-button
                        class="bg-transparent primary-icon"
                        matSuffix>
                    <span class="material-symbols-rounded">arrow_forward</span>
                </button>
            </mat-form-field>
        </form>
        <p *ngIf="error_message !== ''" class="text-error">
            {{ error_message }}
        </p>
        <p [hidden]="!pinValidationIn">
            Presiona Enter para ver tarjeta
        </p>
        <button class="bg-transparent text-danger" mat-button (click)="sendResetCode()">
            ¿Olvidaste tu PIN?
        </button>
    </div>
    <div [hidden]="!pinValidationIn">
        <div class="emp-card">
            <div>
                <p class="emp-card-label small-label text-uppercase m-0">Tarjeta digital</p>
                <div class="marquee-container">
                    <div class="marquee app-module-subtitle">
                        <div class="d-flex flex-row justify-content-between">
                            <span>{{ card.desc_tarjeta | cardDesc | uppercase }}</span>
                            <span>{{ 'Escanea en caja' | uppercase }}</span>
                            <span>{{ card.desc_tarjeta | cardDesc | uppercase }}</span>
                            <span>{{ 'Escanea en caja' | uppercase }}</span>
                        </div>
                    </div>
                </div>
                <div class="emp-card-header"></div>
                <div class="emp-card-content">
                    <div></div>
                    <div class="emp-card-picture" #empCardPicture [style.background-image]="picture"></div>
                    <div class="emp-card-fullname">
                        <p class="medium-text emp-card-name"><strong>{{ name | titlecase }} {{ lastname | titlecase }}</strong></p>
                        <!--                        <p class="small-label emp-card-last">{{ card.apellido_p | titlecase }}</p>-->
                        <p class="header-pill" *ngIf="userIsBeneficiary">Beneficiario</p>
                    </div>
                    <div></div>
                </div>
                <div class="emp-card-footer">
                    <!-- Barcode scan image -->
                    <ngx-barcode class="emp-card-barcode"
                                 [bc-width]="2"
                                 [bc-value]="decrypt(card.cuenta_r)"
                                 [bc-format]="'CODE128'"
                                 [bc-display-value]="false">
                    </ngx-barcode>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="code_sent" class="pin-container">
        <p class="large-title"><strong>Código de verificación enviado</strong></p>
        <p class="small-label">Se ha enviado un código a tu correo o teléfono registrado para crear un nuevo PIN.</p>
        <div class="dialog-actions">
            <button class="reset-pin" mat-flat-button
                    (click)="resetPinBtn()">
                Siguiente
            </button>
        </div>
    </div>
</ng-template>

<!-- Modal para crear pin -->
<ng-template #savePinModal>
    <div class="save-pin-form">
        <div *ngIf="start">
            <p>
                Genera un número de identificación personal PIN como medida de seguridad al mostrar tu tarjeta virtual.
                <br><br>
                <button mat-flat-button
                        (click)="setStart()">
                    Comenzar
                </button>
                <br><br>
                <!-- Button to re-open and modify terms preference -->
                <button mat-flat-button
                        mat-dialog-close
                        (click)="open(0)">Volver a ver Terminos y Condiciones
                </button>
            </p>
        </div>
        <div *ngIf="next1">
            <p>Captura tu PIN</p>
            <mat-form-field floatLabel="never" class="w-100 p-0">
                <mat-label>PIN</mat-label>
                <input matInput #createPin
                       type="password"
                       mask="0000"
                       class="input-pin"
                       (keyup.enter)="setPin(createPin.value)">
                <button mat-icon-button matSuffix
                        class="bg-transparent"
                        (click)="setPin(createPin.value)">
                    <span class="material-symbols-rounded primary-icon">send</span>
                </button>
            </mat-form-field>
        </div>
        <div *ngIf="next2">
            <p>Confirma tu PIN</p>
            <mat-form-field floatLabel="never" class="w-100 p-0">
                <mat-label>PIN</mat-label>
                <input matInput #confirmPin
                       type="password"
                       mask="0000"
                       class="input-pin"
                       (keyup.enter)="pinConfirm(confirmPin.value, false)">
                <button mat-icon-button matSuffix
                        class="bg-transparent"
                        (click)="pinConfirm(confirmPin.value, false)">
                    <span class="material-symbols-rounded primary-icon">send</span>
                </button>
            </mat-form-field>
            <p *ngIf="error_message !== ''" class="text-error">
                {{ error_message }}
            </p>
        </div>
        <div *ngIf="end">
            <div *ngIf="!pinValidationIn">
                <p>{{ error_message }}</p>
                <button mat-button color="primary" (click)="setEnd()">Entendido</button>
            </div>
            <div *ngIf="pinValidationIn">
                <p>Tu pin ha sido generado correctamente, ingrésalo al momento de pagar con tu tarjeta virtual.</p>
                <button mat-button color="primary" (click)="setEnd()">Ver Tarjeta</button>
            </div>
        </div>
    </div>
</ng-template>

<!-- Modal para reiniciar pin -->
<ng-template #resetPinModal>
    <div class="save-pin-form" >
        <div *ngIf="start">
            <div class="d-flex flex-row align-content-center">
                <img src="../../assets/img/locked-64.png"
                     width="32"
                     height="32"
                     class="me-3"
                     alt="Secured account icon">
                <p><strong>Confirma el código</strong></p>
            </div>
            <p class="small-label mb-3">Ingresa el código de 6 digitos que fue enviado a tu correo o teléfono registrado.</p>
            <mat-form-field floatLabel="never" class="w-100 p-0">
                <mat-label>Código</mat-label>
                <input matInput #resetPin
                       type="numeric"
                       mask="000000"
                       class="input-pin"
                       (keyup.enter)="pinCode(resetPin.value)">
                <button mat-icon-button matSuffix
                        class="bg-transparent primary-icon"
                        (click)="pinCode(resetPin.value)">
                    <span class="material-symbols-rounded">send</span>
                </button>
            </mat-form-field>
            <p *ngIf="error_message !== ''" class="text-danger">
                <img src="../../assets/img/error-circle-100.svg"
                     width="32"
                     height="32"
                     alt="Account Error">
                {{ error_message }}
            </p>
            <p><strong>¿No llegó el codigo?</strong></p>
            <button mat-button
                    class="bg-transparent text-danger"
                    (click)="sendResetCode()">
                Reenviar código
            </button>
        </div>
        <div *ngIf="next1">
            <p class="large-title m-0">Restablecer PIN</p>
            <p class="small-label">Digita un nuevo PIN</p>
            <mat-form-field floatLabel="never" class="w-100 p-0">
                <mat-label>PIN</mat-label>
                <input matInput #newPin
                       type="password"
                       mask="0000"
                       class="input-pin"
                       (keyup.enter)="setPin(newPin.value)">
                <button mat-icon-button matSuffix (click)="setPin(newPin.value)">
                    <span class="material-symbols-rounded">send</span>
                </button>
            </mat-form-field>
            <p *ngIf="error_message !== ''" class="text-error">
                {{ error_message }}
            </p>
        </div>
        <div *ngIf="next2">
            <p class="large-title m-0">Restablecer PIN</p>
            <p class="small-label"><strong>Confirma el nuevo PIN</strong></p>
            <mat-form-field floatLabel="never" class="w-100 p-0">
                <mat-label>PIN</mat-label>
                <input matInput #confirmNewPin
                       type="password"
                       mask="0000"
                       class="input-pin"
                       (keyup.enter)="pinConfirm(confirmNewPin.value, true)">
                <button mat-icon-button matSuffix (click)="pinConfirm(confirmNewPin.value, true)">
                    <span class="material-symbols-rounded">send</span>
                </button>
            </mat-form-field>
            <p *ngIf="error_message !== ''" class="text-error">
                {{ error_message }}
            </p>
        </div>
        <div *ngIf="end">
            <p class="large-title m-0"><strong>¡Tu PIN se restablecio!</strong></p>
            <p>Se ha restablecido tu nuevo PIN correctamente, ingrésalo al momento de pagar con tu tarjeta digital.</p>
            <button mat-button color="primary" (click)="setEnd()">Ver Tarjeta</button>
        </div>
    </div>
</ng-template>