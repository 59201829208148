import {BreakpointObserver} from '@angular/cdk/layout';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {CardService} from '../card.service';
import {DashboardService} from '../dashboard.service';
import {SPJS100Out} from '../models/sp_js_100';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TermsConditionsComponent} from '../policies/terms-and-conditions/terms-conditions.component';
import {AnalyticsReporterService} from '../services/analytics-reporter.service';
import {HttpStatusCode} from '@angular/common/http';
import {decrypt} from '../consts/utilities';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {VirtualEmpCardService} from '../virtual-emp-card.service';

@Component({
    selector: 'app-virtual-card',
    templateUrl: './virtual-card.component.html',
    styleUrls: ['./virtual-card.component.scss']
})
export class VirtualCardComponent implements OnInit {

    @Input() card: SPJS100Out;
    @Input() pinValidationIn: boolean;
    @Output() termOptionToCard = new EventEmitter<number>();
    @Output() pinValidationOut = new EventEmitter<boolean>();

    @ViewChild('alertDialog', {static: true}) alertDialog: TemplateRef<any>;
    @ViewChild('barcodeModal', {static: true}) barcodeModal: TemplateRef<any>;
    @ViewChild('savePinModal', {static: true}) savePinModal: TemplateRef<any>;
    @ViewChild('resetPinModal', {static: true}) resetPinModal: TemplateRef<any>;

    @ViewChild('empCardPicture', { static: true}) empCardPicture: ElementRef;

    @ViewChild('pinBtn', {}) pinBtn: ElementRef;

    pin_exists = false;
    pin1: string = null;
    pin2: string = null;
    start = true;
    next1 = false;
    next2 = false;
    end = false;
    error_message = '';
    // btnNext1 = true;
    // btnNext2 = true;
    code_sent = false;
    reset_pin = false;
    name = '';
    lastname = '';
    picture: any = `url('../../assets/img/customer-100.png')`;
    userIsBeneficiary = false;

    pinGroup: FormGroup;

    constructor(
        public router: Router,
        private cardService: CardService,
        private analyticsService: AnalyticsReporterService,
        private virtualCardService: VirtualEmpCardService,
        public dialog: MatDialog,
        public bottomSheet: MatBottomSheet,
        public dashboard: DashboardService,
        private breakpointObserver: BreakpointObserver,
        private renderer: Renderer2,
        private sanitization: DomSanitizer,
    ) {
        this.pinGroup = new FormGroup({
            pin: new FormControl('', Validators.pattern(/([0-9]){4}/))
        });
    }

    ngOnInit() {
        this.userLoggedIsBeneficiary();
    }

    open(cardTermStatus = 0) {
        if (cardTermStatus === 0) {
            this.openTermsModal();
        } else {
            this.launchBarcode();
        }
    }

    openTermsModal(): void {
        const termsModal = this.dialog.open(TermsConditionsComponent);
        const termsInstance = termsModal.componentInstance;

        // Instance input values
        termsInstance.card = this.card;

        termsModal.afterClosed().subscribe(termPref => {
            // Emit Term Status Value to all cards
            this.emitTermValue(termPref);

            if (termPref === 1) {
                this.pinExists();
            }
        });
    }

    emitTermValue(event: number) {
        this.termOptionToCard.emit(event);
    }

    private launchBarcode(): void {
        const { id, sw_terminos } = this.card;
        const beneficiaryId = +localStorage.getItem('logged_beneficiary_id') || 0;

        this.setCardData(id, beneficiaryId);
        this.emitTermValue(sw_terminos);

        this.analyticsService.logEvent('user_onvirtualcard');
    }

    pinExists() {
        this.resetVariables();
        this.cardService.pinExists().subscribe(
            response => {
                if (response.statusCode === 200) {
                    const {data} = response;
                    const {codigo} = data;

                    if (codigo === 0 || codigo === 8) {
                        this.pin_exists = true;
                        this.launchBarcode();
                    }

                    if (codigo === 7) {
                        this.pin_exists = false;
                        this.launchBarcode();
                    }
                }
            },
            _ => {
                this.pin_exists = false;
                this.launchBarcode();
            }
        );
    }

    private userLoggedIsBeneficiary(): void {
        const userType = localStorage.getItem('userType');

        if (userType === 'b') {
            this.userIsBeneficiary = true;
        }
    }

    private setCardData(cardId: number, beneficiaryId: number): void {
        this.virtualCardService.getCardByUserType(cardId, beneficiaryId).subscribe(
            (response) => {
                const payload = response.data[0];

                if (this.userIsBeneficiary) {
                    const { benf_nombre, benef_apellidos } = payload;

                    this.name = benf_nombre;
                    this.lastname = benef_apellidos;
                    this.picture =
                        this.sanitization.bypassSecurityTrustStyle(
                            `url("${payload.benef_picture}")`
                        );
                }

                if (!this.userIsBeneficiary) {
                    this.name = this.card.nombre;
                    this.lastname = this.card.apellido_p;

                    if (payload.picture === null) {
                        return;
                    }

                    this.picture =
                        this.sanitization.bypassSecurityTrustStyle(
                            `url("${payload.picture}")`
                        );
                }

                this.dialog.open(this.barcodeModal, {
                    maxWidth: '350px',
                    width: '350px',
                    height: '500px'
                });

                this.renderer.setStyle(
                    this.empCardPicture.nativeElement,
                    'background',
                    this.picture
                );
            },
            (error) => {

            }
        );
    }

    // valida si el pin capturado es valido
    pinValidation(): void {
        const pin = this.pinGroup.get('pin').value;

        this.cardService.pinValidation(pin).subscribe(
            response => {
                if (response.statusCode === HttpStatusCode.Ok) {
                    const {data} = response;
                    const {codigo} = data;

                    if (codigo === 0) {
                        this.pinValidationOut.emit(true);
                        this.pinValidationIn = true;
                    }

                    if (codigo !== 0) {
                        this.error_message = 'El PIN no es válido.';
                    }
                }
            },
            () => this.error_message = 'El PIN no es válido.'
        );
    }

    // captura el pin a guardar
    setPin(pin_number: string): void {
        this.pin1 = pin_number;

        if (this.pin1.length === 4 && this.pin1 !== '0000') {
            this.setNext1();
        }
    }

    // confirma los dos pin capturados sean iguales y guarda el pin capturado
    pinConfirm(pin_number: string, isReset: boolean): void {
        this.reset_pin = isReset;
        this.pin2 = pin_number;
        this.error_message = '';

        if (this.pin1 === this.pin2) {
            if (!this.reset_pin) {
                this.createPin(this.pin1);
            } else {
                this.updatePin(this.pin1);
            }
        } else {
            this.error_message = 'Los PIN\'s no coinciden';
        }
    }

    // crea un nuevo pin
    createPin(pin_number: string): void {
        this.cardService.savePin(pin_number).subscribe(
            _ => {
                this.pinValidationIn = true;
                this.setNext2();
            },
            error => {
                if (error.error.length > 0) {
                    this.error_message = error.error[0].mensaje;
                    this.setNext2();
                }
            }
        );
    }

    // actualiza el pin existente
    updatePin(pin_number: string): void {
        this.cardService.resetPin(pin_number).subscribe(
            (response) => {
                if (response.statusCode === 200) {
                    const {data} = response;
                    const {codigo} = data;

                    if (codigo === 0) {
                        this.pinValidationIn = true;
                        this.setNext2();
                    }
                }
            },
            (error) => {
                if (error.error.length > 0) {
                    this.error_message = error.error[0].mensaje;
                    this.next1 = true;
                    this.next2 = false;
                }
            }
        );
    }

    // envia una peticion para reiniciar el pin
    sendResetCode(): void {
        this.cardService.sendResetCode().subscribe(
            response => {
                if (response.statusCode === HttpStatusCode.Ok) {
                    this.code_sent = true;
                }
            },
            error => console.log(error)
        );
    }

    // redirigue a la pantalla de reinicio del pin
    resetPinBtn(): void {
        this.error_message = '';
        this.router.navigate(['/dashboard/cards']);

        this.dialog.closeAll();
        this.dialog.open(this.resetPinModal);
    }

    // Confirma que el codigo de seguridad sea valido para generar un nuevo pin
    pinCode(code: string): void {
        if (code.length === 6) {
            this.cardService.validateResetCode(code).subscribe(
                (response) => {
                    if (response.statusCode === 200) {
                        this.error_message = '';
                        this.setStart();
                    } else {
                        this.error_message = response.mensaje;
                    }
                },
                () => this.error_message = 'Ocurrio un error, intente generar otro código.'
            );
        }
    }

    setStart(): void {
        this.start = false;
        this.next1 = true;
    }

    setNext1(): void {
        this.next1 = false;
        this.next2 = true;
    }

    setNext2(): void {
        this.next2 = false;
        this.end = true;
    }

    resetVariables(): void {
        this.code_sent = false;
        this.start = true;
        this.next1 = false;
        this.next2 = false;
        this.end = false;
        this.error_message = '';
    }

    setEnd(): void {
        if (this.pinValidationIn) {
            this.dialog.closeAll();
            this.code_sent = false;
            this.pinValidationIn = true;
            this.dialog.open(this.barcodeModal);
            this.resetVariables();
        } else {
            this.dialog.closeAll();
            this.resetVariables();
        }
    }

    // static function
    decrypt(value: string): string {
        return decrypt(value);
    }
}
