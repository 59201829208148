import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as randomBytes from 'random-bytes-js';
import { BaseLoginProvider } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';

declare let AppleID: any;

@Injectable({
  providedIn: 'root'
})
export class AppleLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'APPLE';

  protected auth2: any;

  constructor(
    private clientId: string,
    private _initOptions:  any = { scope: 'email name' }
  ) {
    super();
  }

  private get baseUrl(): string {
    return environment.serverURL;
  }

  public initialize(): Promise<void> {
    return new Promise((resolve, _reject) => {
      this.loadScript(
        AppleLoginProvider.PROVIDER_ID,
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
        () => {
          AppleID.auth.init({
            clientId: this.clientId,
            scope: 'name email',
            redirectURI: `${this.baseUrl}/auth/sign-in-with-apple`,
            state: `${randomBytes.randHex(25)}`, // Used to prevent CSFR
            usePopup: true,
          });
          resolve();
        }
      );
    });
  }

  public getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      resolve(new SocialUser());
    });
  }

  public async signIn(signInOptions?: any): Promise<SocialUser> {
    try {
      const data = await AppleID.auth.signIn();
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }

  public signOut(revoke?: boolean): Promise<any> {
    return new Promise((resolve, reject) => {
      // AppleID doesnt have revoke method
      resolve(true);
    });
  }
}
