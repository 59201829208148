export const environment = {
  production: true,
  // serverURL: `http://10.10.254.127:3002`,
  serverURL: `https://caliwallet.mx/api`,
  // serverURL: `https://cw.calimaxjs.com/api`,
  // serverURL: `http://10.10.250.168:3002`,
  // serverURL: `http://localhost:3000/api`,
  // serverURL: 'https://192.168.100.5:3000/api',
  version: '4.4.2',
  phrase: 'c@liMx%$^js2021',
  useEmulators: false,
  firebaseOptions: {
    apiKey: 'AIzaSyC-eo0JYqZT652omnwJgAb8tfNvKB7lLpo',
    authDomain: 'caliwallet-e97fa.firebaseapp.com',
    projectId: 'caliwallet-e97fa',
    storageBucket: 'caliwallet-e97fa.appspot.com',
    messagingSenderId: '532114709141',
    appId: '1:532114709141:web:c16894b3f7d3fce8bf599c',
    measurementId: 'G-DWE2YLVHMK'
  },
  ckPublicKey: 'key_QLwAWpyKmvk8K94fA194G20',
  mapsApiKey: 'AIzaSyBGb5cjxzLpPXjwKfQ_eRdT_kKnigC8UVQ',
  infocodeToken: '95fd70c6c84c2c6b2cdba8b3926b8f2c9000e3234d01a114da11f1d3460877e1',
  // tslint:disable: max-line-length
  barcodeLicense: 'AVjRkT+jG8twDeDnlECQua8/Tzm5RWh56ySmvg5DwLjtdLlfClixs0xp1zurT7m+BHuY17kLDxtmAsmv32046KEIX/w1RCpzayY07Gtg8x1VUIPihnT4ZzJPosi9ZFSUzkuxKZFbmZ9GYPBO1VAbVI0abZN1IoUCfigNULEAEdmsb+8RpsGp2FOm9glWsdhqVUvzuvhxrY4++mxML7a4jI4L8GmXA3zz9fduonLe8wIiYMsTIpXtMnjFbEoH5YATZ/XiFUPFE+6FHUOp5fgoGo/0oS+0YFBpUUr9RGW0PVKcL3oodIiR7EtuVvYuVq9S20Hju2h1D9DXjsjyR3lPNhU1D5E5haZOEZEBS8c6h2yUoQxok8T06Qn0sYJIzxucRIrHboPM8l7HFnY8ytsnM3ErJdg0AQL97/uOVZbCtuWxz+OsFoEifKq3ldPZIW/cJgnuY+9Y2qOgKtyvC9llkvYh+Zg9iyxM31HjXMOfVGGD5cxVt/wN2P30yTQnILV7U/uPEYHkbo6gLrBE9C3L+Iqi9ysvbS+qw/YvO6hsQhDx6iaTr2vopEfh6nPo0lBe25e0zlpP4ZRVd9NKtQNZmuFVqtgpYYXMwijB4m/Kn2QJeKWXMsgLnWEnKGPlrZafCmvrxxrsLOq7tYao23Lo18ZtF3iSppSB0eQy1hVYxCaK5FmUvOONzbVRMnwoVMwcrx1oAM1wF3XowOH3zRmPhbPVB+eEC3n14LKBZibEERhT6AoD6dMaQgOZcwgfFgmR04eji4KOZ6HNCOZKQE7k3NWq7Tx+HSb1AhGM+XkBVbVhA9s/nHiAq7rKG7TqiATIJ+6hFW9/79wvAg4bVJov8w==',
  barcodeLicenseProd: 'AWlhfYuBH3R+PC4AHRd59gwAfJjxNLB+twIab0NAzbI7RB9IRl0dmn1vJnYMNJ02aEyowQxufjmpTKHTNkqQ/vxFDgPfTMa+gXd5E7dcL93GTyAN8FRwww98MNfEXJy2Hlb2jAtRvdlEOdRUIUJhvEwXn3u9M318t/kPbO1F1Q38fdxuD74MdlXNKsJHDjsZUGp0b3v65u4/yYgWgNuxKwJ950pe/pydZtQVehjo2xoP6udWYXdnSNaSi9qpPZmst2iywtnhtQhTl/uzqkPF9mO9Wfnu+grUTdCdfFaEsQ935AvycCIvdwNxQlP1sLxHk1WEXOQMJmzeAJzUcZsISWThZ/wmHDXjtEPQjxEVF7KOUXznK5asRBPLqR6fdnSIKUXpuyi35lEcHm0cwTsKgMEUGKjReSJJJgmGxp4QUPecBovQNBTjQ5D+0fPdqMHT8EoNTupF0w/USSxEPW1Lo0sO/viosUS+GvQLL+y+qV+STYuA/kX78EiWzYIYIPrUmLKCk5E6uENj401QdSr2IheoHDH4/mQTJo0FyF+oQ4qgb9zsfXJ20u8FjT4NqEtqjS3S9EYstCHfv9ZuX7vslaMB7byA2peKyyih7kWozpiPXTwqn+Kjvp4pCI9UmJpMcTZ5LVISfarJCoeWtEjul7KV1FMKbLKlQdtMvWQ9EmdjM5I9XKQla+V2Hpdyh40mMMy7xCwBAmDhhycmnOvleVOHsKdhg7jE6EO14qsRjZ2ZkxYb726u5QHZwDMw1tcigYDe/sFjy0h1Ouf4LZYAp4SynfkCnqRnjdf6U903TtgWE3TrFDdlUIMR4prPDCgOQaMaIQtksJw='
};
