<ng-container *ngIf="!userOkAnswer && !userNoAnswer">
    <app-user-question title="¿Cuentas con tarjetas Calimax?"
                       description="Si no cuentas con tarjeta te generaremos una tarjeta digital."
                       iconPath="../../../../../assets/img/calimax_logo_square.svg"
                       primaryActionText="Si"
                       secondaryActionText="No tengo"
                       (primaryAction)="onPrimaryAction()"
                       (secondaryAction)="onSecondaryAction()">
    </app-user-question>
</ng-container>
<ng-container *ngIf="userOkAnswer">
    <app-registro-tarjeta
        [userNotAnswered]="false"
        [enableSkipButton]="true"
        [disableEndRedirection]="true"
        (userHasEndedCardStep)="userHasAddedCardsOk()">
    </app-registro-tarjeta>
</ng-container>

<ng-template #addedDigitalCard>
    <div class="unable-to-deliver-content p-3">
        <div>
            <p class="medium-text fw-bold m-0 pb-3">Se genero una tarjeta digital</p>
        </div>
        <div>
            <p class="small-label pb-3">
                Puedes mostrar esta tarjeta en cajas y obtener descuentos, ofertas, acumular monedero y mucho mas.
            </p>
        </div>
        <div>
            <button mat-flat-button
                    class="role-action-btn w-100"
                    (click)="userHasAddedCardsOk()">
                Aceptar
            </button>
        </div>
    </div>
</ng-template>
<ng-template #failedToGenCard>
    <div class="unable-to-deliver-content p-3">
        <div>
            <p class="medium-text fw-bold m-0 pb-3">No se pudo generar la tarjeta</p>
        </div>
        <div>
            <p class="small-label pb-3">Estamos teniendo problemas para generar tu tarjeta digital. Intenta mas tarde.</p>
        </div>
        <div>
            <button mat-flat-button
                    class="role-action-btn w-100"
                    (click)="dismissMessage()">
                Aceptar
            </button>
        </div>
    </div>
</ng-template>
