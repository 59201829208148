import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MCConfigGroup } from '../models/mc.group.config.model';
import {ProfileManagerService} from '../profile-manager.service';
import {ProfileDeleteModalComponent} from '../profile-delete-modal/profile-delete-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/overlay';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-profile-info-item',
  templateUrl: './profile-info-item.component.html',
  styleUrls: ['./profile-info-item.component.scss']
})
export class ProfileInfoItemComponent implements OnInit {
    @Input() isSelected = false;

    @Input() title: string;
    @Input() identifier: string;
    @Input() value: any;
    @Input() scope: number;
    @Input() payload: any;
    @Input() config: MCConfigGroup | null;
    @Input() type: 'text' | 'date' | 'numeric' = 'text';
    @Input() verified = false;
    @Input() showUnverifiedPill = false;
    @Input() showUpdateBtn = false;
    @Input() showDeleteBtn = false;

    @Output() requestEdition = new EventEmitter<any>();
    @Output() requestDeletion = new EventEmitter<any>();

    @Input() control = new FormControl();

    public editing = false;

    @Input() emitSelection = false;
    @Output() selected = new EventEmitter<any>();

    constructor(
        public profileManager: ProfileManagerService,
        private dialog: MatDialog,
    ) {}

    ngOnInit() {
        if (!this.control) {
            this.control = new FormControl(this.identifier, []);
        }
        this.control.setValue(this.value, { emitViewToModelChange: true });

        // if (this.type === 'date') {
        //     this.control.setValue(this.dtPipe.transform(this.value, 'dd/MM/yyyy', 'UTC'));
        // }
    }

    public selectedItem(): void {
        this.selected.emit(this.payload);
    }

    public onEditionBtnClick(): void {
        if (this.editing && this.control.dirty) {
            switch (this.identifier) {
                case 'telefono':
                    this.payload.telefono = this.control.value;
                    break;
                case 'email':
                    this.payload.email = this.control.value;
                    break;
            }

            this.requestEdition.emit(this.payload);

            this.control.markAsPristine();
        }

        this.editing = !this.editing;
    }

    public openEditorMode(
        type: any,
        req: number,
        data: any,
        config: MCConfigGroup,
        customEditionComponent?: ComponentType<any>,
    ): void {
        if (!this.showUpdateBtn) {
            return;
        }

        if (customEditionComponent) {
            this.profileManager.loadEditor(type, req, data, config, customEditionComponent);
            return;
        }

        this.profileManager.loadEditor(type, req, data, config);
    }

    deleteDialog(): void {
        const dialog = this.dialog.open(ProfileDeleteModalComponent);
        const instance = dialog.componentInstance;

        instance['type'] = this.config.type;
        instance['payload'] = this.payload;
        instance['model'] = this.config;

        dialog.afterClosed().subscribe(result => {
           const { canceled } = result;

           if (canceled) {
               return;
           }

           this.requestDeletion.emit(result);
        });
    }
}
