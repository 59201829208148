import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-user-question',
    templateUrl: './user-question.component.html',
    styleUrls: ['./user-question.component.scss']
})
export class UserQuestionComponent implements OnInit {

    @Input() title = '';
    @Input() description = '';
    @Input() iconPath = '';
    @Input() primaryActionText = 'Continuar';
    @Input() secondaryActionText = 'Cancelar';

    @Input() showPrimaryAction = true;
    @Input() showSecondaryAction = true;

    @Output() primaryAction = new EventEmitter<any>();
    @Output() secondaryAction = new EventEmitter<any>();

    constructor() {}

    ngOnInit(): void {
        if (!this.title) {
            this.title = 'No se añadio titulo';
        }

        if (!this.description) {
            this.description = 'No se añadio descripcion';
        }
    }

    public onPrimaryAction(): void {
        this.primaryAction.emit();
    }

    public onSecondaryAction(): void {
        this.secondaryAction.emit();
    }
}
