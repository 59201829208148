/*
    *** ROUTE STAGES ***
    You can write stages for show hamburger menu in some views dynamically according to your logic in code.

    STAGE OBJECT DECLARATION

    const stage = {
        route: string
        activeAsMain: Predicate / boolean
    };
*/

import {AppUtils} from '../app-utils';
import {environment} from '../../environments/environment';

const stages: any[] = [
    {
        route: '/app/dashboard',
        requireLogic: false,
        activeAsMain: true
    },
    {
        route: '/app/dashboard/cards',
        requireLogic: false,
        activeAsMain: true
    },
    {
        route: '/app/scan-and-go',
        requireLogic: false,
        activeAsMain: true
    },
    {
        route: '/app/in-store-shopping',
        requireLogic: false,
        activeAsMain: true
    },
    {
        route: '/app/dashboard/saldos-y-movimientos',
        requireLogic: false,
        activeAsMain: true
    },
    {
        route: '/app/dashboard/cards/add/card/owner',
        requireLogic: true,
        activeAsMain: undefined,
        config: {
            origins: [
                {
                    class: AppUtils,
                    method: 'userHaveEnterpriseId',
                    result: undefined,
                    desired: true
                },
                {
                    class: AppUtils,
                    method: 'userHaveCards',
                    result: undefined,
                    desired: false
                }
            ]
        }
    }
];

export const MainRouteMatch = (url: string) => {
    let isAMainRoute = false;
    const parsedRoute = url.replace(`v${environment.version}`, '');
    const stage = stages.find(s => s.route === parsedRoute);
    const stageIndex = stages.findIndex(s => s.route === parsedRoute);

    if (stage === undefined && stageIndex === -1) {
        return false;
    }

    const requireLogic = stage.requireLogic;

    if (requireLogic) {
        let originIndex = 0;
        let passedStages = 0;
        const origins = stage.config.origins;

        origins.forEach(origin => {
            const result: boolean = origin.class[`${origin.method}`]();

            stages[stageIndex].config.origins[originIndex].result = result;

            const desired =
                stages[stageIndex].config.origins[originIndex].desired;

            // console.log(`Origin ${originIndex} result: `, result);
            // console.log(`Origin ${originIndex} desired: `, desired);

            if (result === desired) { passedStages++; }

            originIndex++;
        });

        // If all stages accomplish with desired return it's a main route
        isAMainRoute = (passedStages === origins.length);
    } else {
        isAMainRoute = true;
    }

    // if (isAMainRoute) {
    //     console.log('Identified main route: ', url);
    // }

    return isAMainRoute;
};
