import {Injectable, OnDestroy} from '@angular/core';
import {fetchAndActivate, getRemoteConfig, getValue, RemoteConfig} from '@firebase/remote-config';
import {initializeApp} from 'firebase/app';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

export enum ConfigValueType {
    Boolean = 1,
    Number = 2,
    String = 3
}

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {

    constructor() {}

    getConfig(): RemoteConfig {
        const app = initializeApp(environment.firebaseOptions);
        const remoteConfig: any = getRemoteConfig(app);

        remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

        return remoteConfig;
    }

    getConfigValue(config_name: string, type: ConfigValueType): Observable<string | number | boolean> {
        const instance = this.getConfig();
        return new Observable<string | number | boolean>((observer) => {
            fetchAndActivate(instance).then(() => {
                console.log('Successful fetched data from remote config');
                const value = getValue(instance, config_name);

                if (type === ConfigValueType.String) {
                    observer.next(value.asString());
                    observer.unsubscribe();
                }

                if (type === ConfigValueType.Number) {
                    observer.next(value.asNumber());
                    observer.unsubscribe();
                }

                if (type === ConfigValueType.Boolean) {
                    observer.next(value.asBoolean());
                    observer.unsubscribe();
                }
            }).catch(err => {
                observer.error(err);
                observer.complete();
            });
        });
    }
}
