import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, Validators } from '@angular/forms';
import { CustomMask } from '../../../../models/CustomMask';

@Component({
  selector: 'app-card-item',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardLinkComponent implements OnInit {

  mask = '0000000000000';
  cardType = '';
  cardIcon = '';
  cardNumber: string;

  @Output() enterEvent = new EventEmitter<any>();
  @Input() viewElementRef: ElementRef<any>;

  constructor(public container: ControlContainer) { }

  ngOnInit() {
    this.cardListener();
  }

  get invalidCard(): boolean {
    return this.container.control.get('cardNumber').invalid && this.container.control.get('cardNumber').touched;
  }

  cardListener() {
    this.container.control.valueChanges.subscribe(control => {
        const value = `${control.cardNumber}`;

        const stage1 = control.cardNumber.substring(0, 4);
        const stage2 = control.cardNumber.substring(0, 3);

        this.cardNumber = control.cardNumber;

        // if (length < 9) { return; }

        const maskData = this.maskDetection(value);

        // Set mask data
        if (maskData === null) {
            this.cardIcon = '';
            this.cardType = '';
            return;
        }

        this.cardType = maskData.description;
        this.cardIcon = maskData.icon;
        this.mask = maskData.mask;

        if (maskData.isVoucher) {
          this.container.control.get('cardNumber').setValidators([
            Validators.required,
            Validators.pattern(/[0-9-]{1,12}/),
            Validators.minLength(1),
            Validators.maxLength(12)
          ]);
        } else if ((stage1 === '2390' || stage2 === '220') && (!maskData.isVoucher)) {
          this.container.control.get('cardNumber').setValidators([
            Validators.required,
            Validators.minLength(9),
            Validators.maxLength(13)
          ]);
        }

        this.container.control.get('cardNumber')
          .updateValueAndValidity({ onlySelf: true });

    });
  }

  // Disassembly in small modules or integrate in calimax-card-mask lib
  private maskDetection(value: string): any {
      const allowedChars = /[-_]/;
      const valueIncludeChars = allowedChars.test(value);

      let result = {
          icon: '',
          description: '',
          isVoucher: false,
          mask: null
      };

      const voucherMasks: CustomMask[] = [
          new CustomMask('0', 'Empleado', 'badge', false),
          new CustomMask('00', 'Empleado', 'badge', false),
          new CustomMask('000', 'Empleado', 'badge', false),
          new CustomMask('0000', 'Empleado', 'badge', false),
          new CustomMask('00000', 'Empleado', 'badge', false),
          new CustomMask('000000', 'Empleado', 'badge', false),
          new CustomMask(
              (valueIncludeChars) ? '00000-0000' : '000000000',
              'Vales',
              'credit_card',
              valueIncludeChars
          ),
          new CustomMask(
              (valueIncludeChars) ? '000000-0000' : '0000000000',
              'Vales',
              'credit_card',
              valueIncludeChars
          ),
          new CustomMask(
              (valueIncludeChars) ? '0000000-0000' : '00000000000',
              'Vales',
              'credit_card',
              valueIncludeChars
          )
      ];

      const clubMasks = [
          // tslint:disable:max-line-length
          new CustomMask('0000000000000', 'Club', 'groups', false, null, null, false, '2390'),
          new CustomMask('0000000000000', 'Regalo', 'redeem', false, null, null, false, '220')
      ];

      const valueFormattedToMask =
          value.replace(/[0-9]/g, '0');

      // Check in voucher cards
      const result1 =
          voucherMasks.find(mask => mask.value === valueFormattedToMask);

      // If voucher array.find method return a result, then return result and skip pending search processes
      if (result1 !== undefined) {
          result = {
              icon: result1.icon,
              description: result1.description,
              isVoucher: true,
              mask: result1.value
          };

          return result;
      }

      const result2 =
          clubMasks.find(mask => {
              const stageStart = (mask.fromLast) ? value.length : 0;
              const stageEnd = (mask.fromLast) ? (value.length - mask.stage.length) : mask.stage.length;
              const stage = (mask.fromLast)
                  ? value.substring(stageStart, stageEnd)
                  : value.substring(0, stageEnd);

              if (stage === mask.stage && valueFormattedToMask === mask.value) {
                  result = {
                      icon: mask.icon,
                      description: mask.description,
                      isVoucher: false,
                      mask: mask.value
                  };
              }
          });

      if (result2 !== undefined) {
          result = {
              icon: result2.icon,
              description: result2.description,
              isVoucher: true,
              mask: result2.value
          };

          return result;
      }

      if (result.mask === null) {
          // result.mask = this.maskDigitReplicator('0', length);
          // result.description = null;
          // result.icon = null;
          result = null;
      }

      return result;
  }

  private maskDigitReplicator(digit: string, length: number): string {
      let output = '';

      Array(length).fill(digit).forEach(_ => {
          output += digit;
      });

      return output;
  }

  onEnterEvent(): void {
    this.enterEvent.emit();
  }
}
