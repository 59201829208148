import { McCard } from '../models/McCard.model';

export const McCardConst: any = {
    action: 'INK',
    id_usu: 0,
    id_sesion: 0,
    cardno: '',
    nombre: '',
    paterno: '',
    materno: '',
    fecha_nac: null,
    pais_nac: '',
    domicilio: '',
    telefono: '',
    curp: '',
    rfc: '',
    id_ident: 0,
    num_ident: 0,
    email: '',
    pip: '',
    pso: '',
    papp: '',
};
