import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cardDesc'
})
export class CardDescPipe implements PipeTransform {
    transform(value: string): any {
        const subValue: string = value.replace('Tarjeta ', '');
        const returnValue: string = subValue.replace('de ', '');

        return (value.includes('Tarjeta de ') || value.includes('Tarjeta '))
            ? returnValue
            : value;
    }
}
