import {userEnterpriseId} from './consts/utilities';

export class AppUtils {
    static userHaveEnterpriseId(): boolean {
        const empId = +userEnterpriseId();
        return empId !== null && empId > 0;
    }

    static userHaveCards(): boolean {
        const haveCards = sessionStorage.getItem('have_cards');
        return haveCards === 'true';
    }

    static mobile(): boolean {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    static onDigitInput(event: any): void {
        let element;

        if (event.code !== 'Backspace') {
            element = event.srcElement.nextElementSibling;
        }

        if (event.code === 'Backspace') {
            element = event.srcElement.previousElementSibling;
        }

        if (element == null) {
            return;
        } else {
            element.focus();
        }
    }
}
