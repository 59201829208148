import { McAddress } from '../models/McAddress.model';

export const McAddressConst: McAddress = {
  accion: 'IN',
  cliente_id: 0,
  domicilio_id: 0,
  calle: '',
  numero_ext: '',
  numero_int: '',
  colonia: '',
  codigo_postal: '',
  ciudad_id: 0,
  estado_id: 0,
  pais_id: 0,
  referencias: '',
  usuario_id: '',
};
