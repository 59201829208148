<div>
    <div class="card-item card-theme-color theme-shadow"
         mat-ripple
         matRippleColor="#ffffff35">
        <div class="card-item-left">
            <div class="d-flex flex-row align-items-center">
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <div class="card-brand-icon-container">
                        <img src="../../../../assets/img/calimax_logo_square.svg"
                             width="32"
                             height="32"
                             alt="Card Logo">
                    </div>
                </div>
                <div class="card-type ms-4">
                    {{ card.desc_tarjeta | cardDesc | uppercase }}
                </div>
            </div>
            <span class="m-3"></span>
            <div class="d-flex flex-row">
                <div class="d-flex flex-row mt-2">
                    <div class="w-100">
                        <p class="card-available gradient-accent-color">{{ cardAmount | currency }}</p>

                        <!--                        <div class="card-number">-->
                        <!--                            {{-->
                        <!--                            (viewCardNumbers)-->
                        <!--                                ? (decrypt(card.cuenta_r) | cardNumber)-->
                        <!--                                : (decrypt(card.cuenta_r) | hideCardNumber)-->
                        <!--                            }}-->
                        <!--                        </div>-->
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column">
                <div class="d-flex justify-content-between align-items-center mb-4">
                    <div>
                        <div class="card-holder-name" *ngIf="card.nombre">
                            {{ card.nombre | uppercase  }}
                            {{ card.apellido_p | uppercase }}
                        </div>
                        <div class="card-holder-name" [hidden]="card.nombre">
                            Nombre no disponible
                        </div>
                    </div>
                </div>
                <!--                <div class="d-flex flex-row align-items-center" *ngIf="mobile && card.bnd_codb === 1">-->
                <!--                    <button mat-icon-button-->
                <!--                        (click)="setPinValidationOut(false)"-->
                <!--                        [style.color]="'#0c4d94'">-->
                <!--                            <span class="material-symbols-rounded">-->
                <!--                                {{ (viewDigitalCard || pinValidationIn) ? 'lock_open' : 'lock' }}-->
                <!--                            </span>-->
                <!--                    </button>-->
                <!--                    <p class="card-digital-card-status m-0">{{ (viewDigitalCard || pinValidationIn) ? 'Tarjeta digital desbloqueada' : 'Tarjeta digital bloqueada' }}</p>-->
                <!--                </div>-->
                <div class="card-options-panel mt-2">
                    <button mat-flat-button
                            class="card-item-btn x-small-accent card-theme-border-color"
                            *ngIf="mobile"
                            (click)="openCardBottom()">
                        <img src="../../../../assets/img/rounded-more-options.png"
                             width="16"
                             height="16">
                        Ver más
                    </button>
                    <button mat-flat-button
                            class="card-item-btn x-small-accent card-theme-border-color"
                            [hidden]="mobile"
                            [matMenuTriggerFor]="menu">
                        <img src="../../../../assets/img/rounded-more-options.png"
                             width="16"
                             height="16">
                        Ver más
                    </button>
                    <button mat-flat-button
                            class="card-item-btn x-small-accent card-theme-border-color"
                            *ngIf="mobile && card.bnd_codb === 1"
                            (click)="toDigitalCard()">
                        Ver tarjeta digital
                    </button>
                    <!--                    <button mat-flat-button-->
                    <!--                        class="card-item-btn"-->
                    <!--                        (click)="goToCardDetails(card.id)">-->
                    <!--                        <img src="../../assets/img/movements_color.svg"-->
                    <!--                             width="16"-->
                    <!--                             height="16">-->
                    <!--                        Ver movimientos-->
                    <!--                    </button>-->
                </div>
            </div>
        </div>
        <div class="card-item-right"></div>
    </div>
    <mat-menu #menu="matMenu">
        <p class="small-label p-3">Transacciones</p>
        <button class="mat-menu-bold" mat-menu-item
                (click)="goToCardDetails(card.id)">
            <span class="material-symbols-rounded">search</span>
            Ver movimientos
        </button>
        <ng-container *ngIf="mobile && card.bnd_codb === 1">
            <button class="mat-menu-bold" mat-menu-item
                    (click)="virtualCard.open(card.sw_terminos)">
                <span class="material-symbols-rounded">credit_card</span>
                Tarjeta digital
            </button>
        </ng-container>
        <button class="mat-menu-bold" mat-menu-item
            (click)="openCardRemoveDialog()">
            <span class="material-symbols-rounded">delete</span>
            Eliminar tarjeta
        </button>
        <ng-container *ngIf="validateReportFeature()">
            <hr class="app-module-subtitle"/>
            <p class="small-label p-3">Ayuda</p>
            <button class="mat-menu-bold" mat-menu-item
                    (click)="goToCardReport(decrypt(card.cuenta_r))" >
                <span class="material-symbols-rounded">report</span>
                Reportar
            </button>
        </ng-container>
    </mat-menu>
    <ng-template #cardBottomSheet>
        <div class="card-line-details">
            <div class="card-line-left">
                <p class="card-line-type medium-text">{{ card.desc_tarjeta | cardDesc }}</p>
                <p class="card-line-last4 small-label">Termina en {{ decrypt(card.cuenta_r) | cardLast4 }}</p>
            </div>
            <div class="card-line-right">
<!--                <p class="card-line-currency medium-text">-->
<!--                    <strong>{{ cardAmount | currency }}</strong>-->
<!--                </p>-->
                <button mat-icon-button class="card-theme-color ms-2"
                        (click)="closeBottomMenu()">
                    <span class="material-symbols-rounded">expand_more</span>
                </button>
            </div>
        </div>
        <hr class="app-module-subtitle"/>
        <mat-nav-list>
            <p class="x-small-label text-uppercase spaced-label p-3">Transacciones</p>
            <button class="mat-menu-bold" mat-menu-item
                    (click)="goToCardDetails(card.id)">
                <span class="material-symbols-rounded">search</span>
                Ver movimientos
            </button>
            <ng-container *ngIf="mobile && card.bnd_codb === 1">
                <button class="mat-menu-bold" mat-menu-item
                        (click)="toDigitalCard()">
                    <span class="material-symbols-rounded">credit_card</span>
                    Tarjeta digital
                </button>
            </ng-container>
            <ng-container *ngIf="validateReportFeature()">
                <hr class="app-module-subtitle"/>
                <p class="x-small-label text-uppercase spaced-label p-3">Ayuda</p>
                <button class="mat-menu-bold" mat-menu-item
                        (click)="goToCardReport(decrypt(card.cuenta_r))" >
                    <span class="material-symbols-rounded">report</span>
                    Reportar
                </button>
            </ng-container>
            <hr class="app-module-subtitle"/>
            <p class="x-small-label text-uppercase spaced-label p-3">Borrar tarjeta</p>
            <button class="mat-menu-bold" mat-menu-item
                (click)="openCardRemoveDialog()">
                <span class="material-symbols-rounded delete">delete</span>
                Eliminar tarjeta
            </button>
        </mat-nav-list>
    </ng-template>
    <ng-template #cardRemoveDialog>
        <div>
            <div class="card-line-details">
                <div class="card-line-left">
                    <h5 class="app-module-title">¿Deseas eliminar la tarjeta de {{ card.desc_tarjeta | cardDesc }} de tu cuenta?</h5>
                    <p class="small-label">Al eliminar la tarjeta quedara lista para ser vinculada a una nueva cuenta.</p>
                </div>
            </div>
            <hr [hidden]="mobile"/>
            <div class="card-actions multiple-separation-bottom-actions">
                <hr class="app-module-subtitle my-0" *ngIf="mobile"/>
                <button mat-flat-button
                    class="w-100 bg-transparent accent-red-color"
                    aria-hidden="true"
                    (click)="removeCard()">
                    Eliminar
                </button>
                <hr class="app-module-subtitle my-0" *ngIf="mobile"/>
                <button mat-flat-button
                    class="w-100 bg-transparent accent-color"
                    type="button"
                    (click)="dismissDialog()">
                    Cancelar
                </button>
            </div>
        </div>
    </ng-template>
    <app-virtual-card #virtualCard
        [card]="card"
        (termOptionToCard)="updateTermPref($event)"
        (pinValidationOut)="setPinValidationOut($event)"
        [pinValidationIn]="true">
    </app-virtual-card>
</div>