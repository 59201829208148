import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { AppTitleService } from '../../app-title.service';
import { MenuStateService } from '../../dashboard/menu-state.service';
import { AuthService } from '../../auth.service';
import { Location } from '@angular/common';
import { ChangeCompanyDialogComponent } from '../../dashboard-module/change-company-dialog/change-company-dialog.component';
import {getAccount, mobile, userEnterpriseId} from '../../consts/utilities';
import {McPersonalService} from '../../mc-personal.service';
import {SaldosMovimientosService} from '../../dashboard-module/saldos-movimientos/saldos-movimientos.service';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {
    @Input() menu: TemplateRef<any>;
    @Input() panelOpenState: boolean;

    public isOnMainRoute = true;

    @Input() isMultiEmp = false;

    @ViewChild('profileBottomMenu', { static: true })
    public profileBottomMenu: TemplateRef<any>;

    public user: { id: string, name: string, lastName: string, contact: string, picture: any } = {
        id: '',
        name: '',
        lastName: '',
        contact: '',
        picture: './assets/img/customer-100.png'
    };

    public title = 'Mis tarjetas';

    public appVersion = environment.version;

    public menuStateObserver: Subscription = null;

    constructor(
        public authService: AuthService,
        private router: Router,
        private dialog: MatDialog,
        private matBottomSheet: MatBottomSheet,
        private location: Location,
        private titleService: AppTitleService,
        private menuStateService: MenuStateService,
        private mcPersonalService: McPersonalService,
        private saldosMovimientosService: SaldosMovimientosService,
        private sanitizer: DomSanitizer,
    ) {}

    ngOnInit(): void {
        this.listenMenuChanges();
        this.observeTitleChanges();

        this.getUserHeaders();
        this.getCompanyData();
    }

    ngOnDestroy(): void {
        this.menuStateObserver.unsubscribe();
    }

    public openMenu(): void {
        if (mobile()) {
            this.matBottomSheet.open(this.menu);
            return;
        }

        if (!mobile()) {
            this.dialog.open(this.menu, {
                closeOnNavigation: true,
                height: '650px',
            });
            return;
        }

        // this.sidenav.open().then(_ => this.panelOpenState = true);
    }

    public backNavigation(): void {
        this.location.back();
    }

    private listenMenuChanges(): void {
        if (this.menuStateObserver === null || this.menuStateObserver.closed) {
            this.menuStateObserver = this.menuStateService.menuStateChanges.subscribe(value => {
                // console.log(value);
                this.isOnMainRoute = value.isOnMainRoute;
            });
        }
    }

    private observeTitleChanges(): void {
        this.titleService.listenChanges().subscribe((value: { title: string }) => {
            this.title = value.title;
        });
    }

    public openCompaniesDialog(): void {
        this.matBottomSheet.dismiss();
        this.dialog.open(ChangeCompanyDialogComponent, { width: '550px' });
    }

    public openProfileMenu(): void {
        this.matBottomSheet.open(this.profileBottomMenu);
    }

    public logout(): void {
        this.matBottomSheet.dismiss();
        this.dialog.closeAll();
        this.authService.logOut();
    }

    public goToPolicy(): void {
        this.matBottomSheet.dismiss();
        this.dialog.closeAll();
        this.router.navigate(['/app/policies/privacy-policy']);
    }

    public goToProfile(): void {
        this.matBottomSheet.dismiss();
        this.dialog.closeAll();
        this.router.navigate(['/app/profile']);
    }

    private getUserHeaders(): void {
        this.mcPersonalService.getUserHeader().subscribe(
            response => {
                const { data } = response;

                if (data) {
                    const { id_cliente, nombre, apellido_p, picture, email, telefono } = data;

                    this.user = {
                        id: id_cliente,
                        name: nombre,
                        lastName: apellido_p,
                        contact: email || telefono,
                        picture: this.sanitizer.bypassSecurityTrustResourceUrl(picture)
                    };
                }
            },
            error => {
                console.error(error);
            }
        );
    }

    private getCompanyData(): void {
        const account = getAccount();
        const companyId = userEnterpriseId();

        this.saldosMovimientosService.getCompanyData(+companyId, account).subscribe(
            (response) => {
                const companyData = localStorage.getItem('company-data');

                if (!companyData) {
                    const data = {
                        company: response.data.company,
                        company_id: response.data.company_id
                    };

                    if (localStorage.getItem('auth')) {
                        localStorage.removeItem('auth');
                    }

                    localStorage.setItem('company-data', JSON.stringify(data));
                }
            }
        );
    }

    get mobile(): boolean {
        return mobile();
    }
}
