import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { McAddressService } from './mc-address.service';
import { environment } from 'src/environments/environment';
import {McEmailService} from './mc-email.service';
import {McTelephoneService} from './mc-telephone.service';
import {McPersonalService} from './mc-personal.service';
import {ResponseApi} from './shared/models/data/response';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    baseUrl: string;

    constructor(
        private http: HttpClient,
        private mcPersonalService: McPersonalService,
        private mcAddressService: McAddressService,
        private mcEmailService: McEmailService,
        private mcTelephoneService: McTelephoneService,
    ) {
        this.baseUrl = environment.serverURL;
    }

    getProfileHeader(): Observable<any> {
        return this.mcPersonalService.getUserHeader('profile');
    }

    clienteAPI(paction: string, prequest?: string): Observable<any> {
        const request = JSON.parse(prequest);
        let response = this.mcPersonalService.getUser();

        if (paction === 'UP') {
            response = this.mcPersonalService.updateUser(request);
        }

        return response;
    }

    emailAPI(paction: string, prequest: string): Observable<ResponseApi<any> | HttpErrorResponse> {
        if (paction === 'SL') {
            return this.mcEmailService.getEmails();
        }

        let payload = null;

        if (prequest) {
            const request = JSON.parse(prequest);
            payload = {
                pid_email: request.id_email || '',
                pemail: request.email || '',
                ptipo_email: request.tipo_email || '',
                psw_primario: request.sw_primario || '',
                pes_usuario: request.es_usuario || '',
                psw_valida: request.sw_valida || 0,
                pip: null,
                pso: null,
                papp: null
            };
        }

        if (paction === 'IN') {
            return this.mcEmailService.addEmail(payload);
        }

        if (paction === 'UP') {
            return this.mcEmailService.updateEmail(payload);
        }

        if (paction === 'DL') {
            return this.mcEmailService.deleteEmail(payload);
        }
    }

    phoneAPI(paction: string, prequest: string): Observable<ResponseApi<any> | HttpErrorResponse> {
        if (paction === 'SL') {
            return this.mcTelephoneService.getPhones();
        }
        let payload = null;

        if (prequest) {
            const request = JSON.parse(prequest);

            payload = {
                pid_tel:  request.id_tel || '',
                ppais: request.pais || '',
                ptelefono: request.telefono || '',
                ptipo_tel: request.tipo_tel || '',
                psw_primario: request.sw_primario || '',
                pes_usuario: request.es_usuario || '',
                psw_valida: request.sw_valida || 0,
                pip: null,
                pso: null,
                papp: null
            };
        }

        if (paction === 'IN') {
            return this.mcTelephoneService.addPhone(payload);
        }

        if (paction === 'UP') {
            return this.mcTelephoneService.updatePhone(payload);
        }

        if (paction === 'DL') {
            return this.mcTelephoneService.deletePhone(payload);
        }
    }

    addressAPI(paction: string, prequest: any): Observable<ResponseApi<any> | HttpErrorResponse> {
        const address = JSON.parse(prequest);

        if (paction === 'SL') {
            return this.mcAddressService.getAddresses();
        }

        if (paction === 'IN') {
            return this.mcAddressService.postAddress(address);
        }

        if (paction === 'UP') {
            return this.mcAddressService.putAddress(address);
        }

        if (paction === 'DL') {
            return this.mcAddressService.deleteAddress(address);
        }
    }
}
