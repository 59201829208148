import { Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MCEmail } from '../models/mc.email.model';
import { MCConfigGroup } from '../models/mc.group.config.model';
import { MCPhone } from '../models/mc.phone.model';
import { AppTitleService } from '../../app-title.service';
import { MatDialog } from '@angular/material/dialog';
import { ProfileManagerService } from '../profile-manager.service';
import {ProfileDataService} from '../profile-data.service';
import {ProfileActionResult} from '../models/profile.action.result.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-profile-contact',
  templateUrl: './profile-contact.component.html',
  styleUrls: ['./profile-contact.component.scss']
})
export class ProfileContactComponent implements OnInit, OnDestroy {
    public subscriptions: Subscription[] = [];

    public currentPhone = null;
    public currentEmail = null;

    @Input() customTitle: string = null;

    @Input() emails: MCEmail[] = [];
    @Input() phones: MCPhone[] = [];

    @Input() configurations: any = {
        email: new MCConfigGroup(),
        telefono: new MCConfigGroup()
    };

    @Input() observePhoneSelection = false;
    @Input() observeEmailSelection = false;

    @Input() allowEmails = true;
    @Input() allowPhones = true;

    @Output() newPhoneAdded = new EventEmitter<any>();
    @Output() newEmailAdded = new EventEmitter<any>();

    @Output() phoneSelected = new EventEmitter<any>();
    @Output() emailSelected = new EventEmitter<any>();

    @Output() confirmChanges = new EventEmitter<any>();

    constructor(
        private dialog: MatDialog,
        private titleService: AppTitleService,
        private activatedRoute: ActivatedRoute,
        private profileDataService: ProfileDataService,
        public profileManager: ProfileManagerService,
    ) { }

    ngOnInit(): void {
        if (this.allowEmails && !this.allowPhones) {
            this.titleService.setTitle('Admin..correo electronico');
        }

        if (!this.allowEmails && this.allowPhones) {
            this.titleService.setTitle('Admin..num. de telefono');
        }

        this.activatedRoute.data.subscribe(values => {
            const { response } = values;
            const {
                emailResponse,
                emailConfigResponse,
                phoneResponse,
                phoneConfigResponse
            } = response;

            const { data: emails } = emailResponse;
            const { data: emailConfig } = emailConfigResponse;
            const { data: phones } = phoneResponse;
            const { data: phoneConfig } = phoneConfigResponse;

            if (emails) {
                this.emails = emails.filter(email =>  email.codigo === 0);
            }

            if (emailConfigResponse) {
                this.configurations.email = emailConfig[0];
            }

            if (phones) {
                this.phones = phones.filter(telephone =>  telephone.codigo === 0);
            }

            if (phoneConfigResponse) {
                this.configurations.telefono = phoneConfig[0];
            }
        });

        const addingSub = this.profileManager.addingChanges$.subscribe(
            (value: { result, type }) => {
                    console.log(`Adding change ${ JSON.stringify(value.result) }`);
                    const { result, type } = value;
                    this.addInData(result, type);
            }
        );

        this.subscriptions.push(addingSub);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public emitSelection(): void {
        if (this.observePhoneSelection && this.currentPhone) {
            this.phoneSelected.emit(this.currentPhone);
        }

        if (this.observeEmailSelection && this.currentEmail) {
            this.emailSelected.emit(this.currentEmail);
        }
    }

    selectedEmail(email: any): void {
        this.currentEmail = email;
    }

    selectedPhone(phone: any): void {
        this.currentPhone = phone;
    }

    onRequestItemEdition(type: string, payload: any): void {
        const rawPayload = JSON.stringify(payload);

        if (type === 'telefono') {
            this.profileDataService.phoneRequest('UP', rawPayload, (result) => {
                const { data } = result;
            });
        }

        if (type === 'email') {
            this.profileDataService.emailRequest('UP', rawPayload, (result) => {
                const { data } = result;
                console.log(data);
            });
        }
    }

    onRequestItemDeletion(event: ProfileActionResult): void {
        const { type, payload } = event;
        const rawPayload = JSON.stringify(payload);

        if (type === 'telefono') {
            this.profileDataService.phoneRequest('DL', rawPayload, (result) => {
                const { data } = result;
                this.removeFromData(data[0], type);
            });
        }

        if (type === 'email') {
            this.profileDataService.emailRequest('DL', rawPayload, (result) => {
                const { data } = result;
                this.removeFromData(data[0], type);
            });
        }
    }

    // Called from addition processes
    private addInData(item: any, type: string): void {
        switch (type) {
            case 'telefono':
                this.newPhoneAdded.emit(item.data[0]);
                this.phones.push(item.data[0]);
                break;
            case 'email':
                this.newEmailAdded.emit(item.data[0]);
                this.emails.push(item.data[0]);
                break;
        }
    }

    // Called from deletion processes
    private removeFromData(item: any, type: string): void {
        switch (type) {
            case 'telefono':
                this.phones = this.phones.filter((tel) => tel.id_tel !== item.id_tel);
                break;
            case 'email':
                this.emails = this.emails.filter((email) => email.id_email !== item.id_email);
                break;
        }
    }
}
