import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {mobile} from '../../consts/utilities';

@Component({
    selector: 'app-detalle-movimiento',
    templateUrl: './detalle-movimiento.component.html',
    styleUrls: ['./detalle-movimiento.component.scss']
})
export class DetalleMovimientoComponent implements OnInit {
    @ViewChild('movementDetails', {})
    movementDetails: TemplateRef<any>;

    // @Output() month = new EventEmitter<number>();

    @Input() movement: any;

    checkIn: string;
    transaction: string;

    @Input() showLine = false;

    constructor(
        private _dialog: MatDialog,
        private matBottomSheet: MatBottomSheet
    ) {}

    ngOnInit() {
        this.getTransactionFromRef(this.movement.referencia);
        this.getCheckInFromRef(this.movement.referencia);
    }

    viewDetails() {
        if (this.isMobile) {
            this.matBottomSheet.open(this.movementDetails);
        } else {
            this._dialog.open(this.movementDetails);
        }
    }

    public closeDetails(): void {
        if (this.isMobile) {
            this.matBottomSheet.dismiss();
        }

        if (!this.isMobile) {
            this._dialog.closeAll();
        }
    }

    getTransactionFromRef(ref: string): void {
        const data = ref.split(' ');
        const transaction = data.find(d => d.includes('T'));

        if (transaction) {
            this.transaction = transaction;
            return;
        }

        this.transaction = 'No disponible';
    }

    getCheckInFromRef(ref: string): void {
        const data = ref.split(' ');
        const checkIn = data.find(d => d.includes('C'));

        if (checkIn) {
            this.checkIn = checkIn;
            return;
        }

        this.checkIn = 'No disponible';
    }

    get isMobile(): boolean {
        return mobile();
    }
}
