import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalDeleteComponent } from '../modal-delete/modal-delete.component';
import { DashboardService } from '../dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../auth.service';
import { AppTitleService } from '../app-title.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { ModalDeleteService } from '../modal-delete/modal-delete.service';
import {AnalyticsReporterService} from '../services/analytics-reporter.service';
import {mobile} from '../consts/utilities';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    animations: [
        trigger('fadeSlideInOut', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(80px)' }),
                animate('300ms', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
            transition(':leave', [
                animate('300ms', style({ opacity: 0, transform: 'translateY(80px)' })),
            ]),
        ]),
        trigger('fadeSlideInOutHeader', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(200px)' }),
                animate('600ms', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
            transition(':leave', [
                animate('600ms', style({ opacity: 0, transform: 'translateY(200px)' })),
            ]),
        ])
    ]
})
export class ProfileComponent implements OnInit {

    isMobile = false;

    id_cliente = '';
    username = '';
    surname = '';
    lastname = '';
    account = '';
    picture: any = '../../assets/img/customer-100.png';

    @ViewChild('unavailableEdition')
    unavailableEditionModal: TemplateRef<any>;

    @ViewChild('failedUpdate')
    failedUpdate: TemplateRef<any>;

    @ViewChild('failedAdding')
    failedAdding: TemplateRef<any>;

    constructor(
        private authService: AuthService,
        private dashboardService: DashboardService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private titleService: AppTitleService,
        private sanitization: DomSanitizer,
        private dialog: MatDialog,
        private analyticsService: AnalyticsReporterService,
        private deleteService: ModalDeleteService,
        private snackbar: MatSnackBar
    ) {
        this.dashboardService.executeFn$.subscribe(
            _ => this.location.back()
        );

        this.titleService.setTitle('Perfil');

        this.analyticsService.logEvent('user_onprofile');
    }

    ngOnInit() {
        this.isMobile = mobile();
        this.activatedRoute.data.subscribe(
            value => {
                this.onProfileHeadersResponse(value.headers);
            },
            error => {
                this.onProfileHeadersError(error);
            }
        );
    }

    logOut(): void {
        this.authService.logOut();
    }

    deleteAccount(): void {
        const dialog = this.dialog.open(ModalDeleteComponent);

        dialog.afterClosed().subscribe((response) => {
            if (response === 'borrar') {
                this.deleteService.deleteUser(this.id_cliente).subscribe((resp) => {
                    if (resp.data.mensaje === 'OK') {
                        this.snackbar.open('Se elimino exitosamente su usuario.', 'CERRAR', {
                            duration: 5000,
                            horizontalPosition: 'right',
                            verticalPosition: 'bottom'
                        });
                        this.authService.logOut();
                    } else {
                        this.snackbar.open('Ocurrio un error al intentar eliminar su cuenta, por favor intente mas tarde.', 'CERRAR', {
                            duration: 5000,
                            horizontalPosition: 'right',
                            verticalPosition: 'bottom'
                        });
                    }
                });
            }
        });
    }

    private onProfileHeadersResponse(response: any): void {
        const payload = response.data;

        if (payload) {
            this.id_cliente = payload.id_cliente;
            this.username = payload.nombre;
            this.surname = payload.apellido_p;
            this.account = (payload.email) ? payload.email : payload.telefono;
            this.picture =
                this.sanitization.bypassSecurityTrustResourceUrl(
                    payload.picture
                );
        }
    }

    private onProfileHeadersError(error: Error): void {
        console.error(error);
    }
}
