<div class="global-container">
    <ng-template #menu>
        <div class="pill-drag-indicator" *ngIf="mobile"></div>
        <div class="d-flex flex-row justify-content-between w-100 py-4">
            <div class="d-flex flex-row align-items-center">
                <img class="glance-card-theme-color theme-shadow" [src]="user.picture"
                     [style.border-radius]="'12px'"
                     width="48"
                     height="48"
                     alt="User profile image"/>
                <div class="mx-3 w-100">
                    <span class="medium-text" [style.max-width]="'70%'">
                        <strong>
                            {{ user.name | titlecase }}
                            {{ user.lastName | titlecase }}
                        </strong>
                    </span>
                    <p class="small-label" [style.max-width]="'70%'"> {{ user.contact }} </p>
                </div>
            </div>
            <button mat-flat-button
                    class="glance-card-theme-color close-menu-btn"
                    (click)="closeMenu()">
                    <span class="material-symbols-rounded">
                        {{ mobile ? 'expand_more' : 'close' }}
                    </span>
            </button>
        </div>
        <mat-nav-list [@fade-up]="panelOpenState">
            <div [class.extended-menu]="!mobile">
                <div class="submenu-container glance-card-theme-color">
                    <p class="x-small-label text-uppercase p-3 m-0 spaced-label">Tarjetas</p>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/dashboard/cards/add/card/owner')">
                    <span class="material-symbols-rounded">
                        add_card
                    </span>
                        Vincular tarjeta
                    </button>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/dashboard/cards')">
                    <span class="material-symbols-rounded">
                        credit_card
                    </span>
                        Tarjetas
                    </button>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/beneficiaries')">
                    <span class="material-symbols-rounded">
                        account_balance_wallet
                    </span>
                        Beneficiarios
                    </button>
                </div>
                <ng-container *ngIf="isOnEnterprise">
                    <div class="submenu-container glance-card-theme-color">
                        <p class="x-small-label text-uppercase spaced-label p-3 m-0">Administración</p>
                        <button mat-menu-item
                                (click)="onMenuItemClick('/app/dashboard/saldos-y-movimientos')">
                            <span class="material-symbols-rounded">manage_accounts</span>
                            Saldos y movimientos
                        </button>
                    </div>
                </ng-container>
<!--                <div class="submenu-container glance-card-theme-color">-->
<!--                    <p class="x-small-label text-uppercase spaced-label p-3 m-0">Promociones</p>-->
<!--                    <button mat-menu-item-->
<!--                            (click)="onMenuItemClick('/app/scan-and-go')">-->
<!--                        <span class="material-symbols-rounded">-->
<!--                            sell-->
<!--                        </span>-->
<!--                        Promociones-->
<!--                    </button>-->
<!--                </div>-->
                <div class="submenu-container glance-card-theme-color">
                    <p class="x-small-label text-uppercase spaced-label p-3 m-0">Compras en linea</p>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/scan-and-go')">
                        <span class="material-symbols-rounded">
                            barcode_scanner
                        </span>
                        Scan & Go
                    </button>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/scan-and-go/account')">
                    <span class="material-symbols-rounded">
                        shopping_bag
                    </span>
                        Mis compras
                    </button>
                </div>
                <div class="submenu-container glance-card-theme-color">
                    <p class="x-small-label text-uppercase spaced-label p-3 m-0">{{ 'Compras en sucursal' }} </p>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/in-store-shopping')">
                        <span class="material-symbols-rounded">
                            shopping_basket
                        </span>
                        Comprar en tienda
                    </button>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/tickets')">
                    <span class="material-symbols-rounded">
                        receipt_long
                    </span>
                        Tickets
                    </button>
                </div>
                <div class="submenu-container glance-card-theme-color">
                    <p class="x-small-label text-uppercase spaced-label p-3 m-0">Cuenta</p>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/dashboard/link-account')">
                    <span class="material-symbols-rounded">
                        link
                    </span>
                        Agregar cuenta
                    </button>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/profile')">
                    <span class="material-symbols-rounded">
                        account_circle
                    </span>
                        Perfil
                    </button>
                </div>
                <div class="submenu-container glance-card-theme-color">
                    <p class="x-small-label text-uppercase spaced-label p-3 m-0">Aplicación</p>
                    <button mat-menu-item
                            (click)="openThemeSettings()">
                        <span *ngIf="syncThemeWithOs"
                              class="material-symbols-rounded">
                            auto_mode
                        </span>
                        <span [hidden]="syncThemeWithOs"
                              class="material-symbols-rounded">
                            {{ darkModeState ? 'dark_mode' : 'light_mode' }}
                        </span>
                        <div>
                            <p class="small-text" appMenuItemFontSize="16">Apariencia</p>
                            <p class="small-label" *ngIf="syncThemeWithOs">
                                Modo dinamico
                            </p>
                            <p class="small-label" [hidden]="syncThemeWithOs">
                                {{ darkModeState ? 'Modo nocturno' : 'Modo dia' }}
                            </p>
                        </div>
                    </button>
                    <button mat-menu-item
                            (click)="onMenuItemClick('/app/policies/privacy-policy')">
                        <span class="material-symbols-rounded">shield</span>
                        Politica de privacidad
                    </button>
                    <button mat-menu-item
                            (click)="logout()">
                    <span class="material-symbols-rounded">
                        logout
                    </span>
                        Cerrar sesión
                    </button>
                </div>
            </div>
        </mat-nav-list>
    </ng-template>
    <ng-template #themeSettings>
        <h5 class="text-uppercase spaced-label small-label pb-4"><strong>Apariencia</strong></h5>
        <div
            class="d-flex flex-column align-items-center justify-content-between submenu-container glance-card-theme-color">
            <div class="d-flex flex-row w-100 px-3 justify-content-between align-items-center">
                <span class="material-symbols-rounded">auto_mode</span>
                <p class="small-label p-3">Usar tema del sistema</p>
                <ng-toggle [value]="syncThemeWithOs"
                           [color]="{
                        unchecked: '#666666',
                        checked: '#658ab6'
                    }"
                           (change)="toggleSyncTheme()">
                </ng-toggle>
            </div>
            <div class="w-100 " *ngIf="!syncThemeWithOs">
                <hr class="app-module-subtitle"/>
                <div class="d-flex flex-row w-100 px-3 justify-content-between align-items-center">
                    <span class="material-symbols-rounded">
                    {{ darkModeState ? 'dark_mode' : 'light_mode' }}
                </span>
                    <p class="small-label m-0 p-3">
                        {{ darkModeState ? 'Modo nocturno activado' : 'Modo dia activado' }}
                    </p>
                    <ng-toggle [value]="darkModeState"
                               [color]="{
                        unchecked: '#FB8E26FF',
                        checked: '#658ab6'
                    }"
                               (change)="toggleTheme()">
                    </ng-toggle>
                </div>
            </div>
        </div>
        <button mat-flat-button class="role-action-btn w-100"
            (click)="closeMenu()">
            Confirmar
        </button>
    </ng-template>
    <div>
        <app-navigation
            [menu]="menu"
            [panelOpenState]="panelOpenState"
            [isMultiEmp]="multiEnterprise">
        </app-navigation>
        <router-outlet></router-outlet>
        <app-bottom-menu
            [hidden]="!mobile"
            [menu]="menu">
        </app-bottom-menu>
    </div>
</div>
