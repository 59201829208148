<div class="my-3">
    <form [formGroup]="container.control">
        <div class="form-responsive">
            <div class="my-2">
                <app-responsive-input id="name"
                    [hidden]="personalDataConfig.hideName"
                    [class.is-invalid]="invalidName"
                    controlName="name"
                    type="text"
                    placeholder="Nombre"
                    [showIcon]="false"
                    [enableLabel]="true"
                    labelText="Nombre"
                    [enableEnterEvent]="false"
                    [viewElementRef]="viewElementRef">
                </app-responsive-input>

                <mat-error *ngIf="invalidName">
                    <img src="../../assets/img/error-circle-100.svg"
                        width="32"
                        height="32"
                        alt="Account Error">
                    Nombre invalido
                </mat-error>
            </div>
            <div class="my-2">
                <app-responsive-input id="lastName"
                    [hidden]="personalDataConfig.hideLastName"
                    [class.is-invalid]="invalidLastName"
                    controlName="lastName"
                    type="text"
                    placeholder="Apellido paterno"
                    [showIcon]="false"
                    [enableLabel]="true"
                    labelText="Apellido Paterno"
                    [enableEnterEvent]="false"
                    [viewElementRef]="viewElementRef">
                </app-responsive-input>

                <mat-error *ngIf="invalidLastName">
                    <img src="../../assets/img/error-circle-100.svg"
                        width="32"
                        height="32"
                        alt="Account Error">
                    Apellido paterno invalido
                </mat-error>
            </div>
            <div class="my-2">
                <app-responsive-input id="motherLastName"
                    [hidden]="personalDataConfig.hideMotherLastName"
                    [class.is-invalid]="invalidMotherLastName"
                    controlName="motherLastName"
                    type="text"
                    placeholder="Apellido materno"
                    [showIcon]="false"
                    [enableLabel]="true"
                    labelText="Apellido Materno"
                    [enableEnterEvent]="false"
                    [viewElementRef]="viewElementRef">
                </app-responsive-input>

                <mat-error *ngIf="invalidMotherLastName">
                    <img src="../../assets/img/error-circle-100.svg"
                        width="32"
                        height="32"
                        alt="Account Error">
                    Apellido materno invalido
                </mat-error>
            </div>
            <div class="my-2">
                <mat-label class="small-label">Fecha de nacimiento</mat-label>
                <mat-form-field [hidden]="personalDataConfig.hideBirthday"
                    class="pt-2"
                    floatLabel="never">
                        <input matInput
                            id="bithday"
                            placeholder="Fecha de nacimiento"
                            formControlName="birthday"
                            [class.is-invalid]="invalidBirthday"
                            [matDatepicker]="picker"/>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                </mat-form-field>

                <mat-error *ngIf="invalidBirthday">
                    <img src="../../assets/img/error-circle-100.svg"
                        width="32"
                        height="32"
                        alt="Account Error">
                    Fecha de naciemiento invalida
                </mat-error>
            </div>
        </div>
    </form>
</div>
