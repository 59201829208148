import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsConditionsComponent} from './terms-and-conditions/terms-conditions.component';
import {SharedModule} from '../dashboard-module/utils/shared.module';
import {PoliciesRoutingModule} from './policies.routing.module';


@NgModule({
    declarations: [
        PrivacyPolicyComponent,
        TermsConditionsComponent,
    ],
    imports: [
        CommonModule,
        PoliciesRoutingModule,
        SharedModule,
    ],
    exports: [
        PrivacyPolicyComponent,
        TermsConditionsComponent,
    ]
})
export class PoliciesModule {
}
