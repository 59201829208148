import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {DashboardService} from './dashboard.service';

import {UrlMatchWithExcludedEndpoint} from './consts/http.load.bar.excludes';
import {catchError} from 'rxjs/operators';
import {AuthService} from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

    constructor(
        private dashboardService: DashboardService,
        private authService: AuthService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const endpoint = req.url;
        const excludeFromLoadBar = UrlMatchWithExcludedEndpoint(endpoint);

        if (!excludeFromLoadBar) {
            this.dashboardService.spin$.next(true);
        }

        return next.handle(req.clone({ withCredentials: true })).pipe(
            catchError((error: HttpErrorResponse) => {
                const { status } = error;
                const isLoggedIn = this.authService.loggedIn;

                if (status === HttpStatusCode.Unauthorized && isLoggedIn) {
                    this.authService.invalidateSession();
                }

                return throwError(error);
            }),
        );
    }
}
