<div class="my-3">
  <form [formGroup]="container.control">
    <div class="form-responsive">
      <!-- Postal code -->
      <div class="my-2">
        <p class="small-label pb-2">Codigo Postal</p>
        <mat-form-field floatLabel="never">
          <input matInput
            type="text"
            id="zip"
            placeholder="Código postal"
            formControlName="zip"
            [class.is-invalid]="invalidZip"
            [hidden]="hideZip"/>
        </mat-form-field>
        <mat-error *ngIf="invalidZip">
          <img src="../../assets/img/error-circle-100.svg" 
            width="32" 
            height="32" 
            alt="Account Error">
          Código postal invalido
        </mat-error>
      </div>
      <!-- Country -->
      <div class="my-2">
          <p class="small-label pb-2">Pais</p>
          <mat-form-field floatLabel="never">
            <mat-select id="country"
              placeholder="País"
              formControlName="country"
              [class.is-invalid]="invalidCountry"
              [hidden]="hideCountry">
              <!-- <mat-option value="1" disabled selected hidden>Pais</mat-option> -->
              <mat-option *ngFor="let country of countries" [value]="country.code">
                {{ country.name | uppercase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error *ngIf="invalidCountry">
            <img src="../../assets/img/error-circle-100.svg" 
              width="32" 
              height="32" 
              alt="Account Error">
            Debe seleccionar una país
          </mat-error>
      </div>
    </div>

    <div class="form-responsive">
      <!-- Street -->
      <div class="my-2">
        <p class="small-label pb-2">Calle</p>
        <mat-form-field floatLabel="never">
          <input matInput
            type="text"
            id="street"
            placeholder="Calle"
            formControlName="street"
            [class.is-invalid]="invalidStreet"
            [hidden]="hideStreet"/>
        </mat-form-field> 
        <mat-error *ngIf="invalidStreet">
          <img src="../../assets/img/error-circle-100.svg" 
            width="32" 
            height="32" 
            alt="Account Error">
          Calle invalida
        </mat-error>
      </div>
      <!-- Ext -->
      <div class="my-2">
        <p class="small-label pb-2">Numero exterior</p>
        <mat-form-field floatLabel="never">
          <input matInput
            type="text"
            id="extNumber"
            placeholder="Número"
            formControlName="extNumber"
            [class.is-invalid]="invalidExtNumber"
            [hidden]="hideExtNumber"/>
        </mat-form-field>
        <mat-error *ngIf="invalidExtNumber">
          <img src="../../assets/img/error-circle-100.svg" 
            width="32" 
            height="32" 
            alt="Account Error">
          Número invalido
        </mat-error>
      </div>
      <!-- Int -->
      <div class="my-2">
        <p class="small-label pb-2">Numero Interior</p>
        <mat-form-field floatLabel="never">
          <input matInput
            type="text"
            id="intNumber"
            placeholder="Interior"
            formControlName="intNumber"
            [class.is-invalid]="invalidIntNumber"
            [hidden]="hideIntNumber"/>
        </mat-form-field>
        <mat-error *ngIf="invalidIntNumber">
          <img src="../../assets/img/error-circle-100.svg" 
            width="32" 
            height="32" 
            alt="Account Error">
          Número interior invalido
        </mat-error>
      </div>
    </div>

    <div class="form-responsive">
      <!-- Locality -->
      <div class="my-2">
          <p class="small-label pb-2">Colonia</p>
          <ng-container *ngIf="!openControls; else openSuburb">
            <mat-form-field floatLabel="never">
              <mat-select id="suburbs"
                placeholder="Colonia"
                formControlName="suburb"
                [class.is-invalid]="invalidSuburb"
                [hidden]="hideSuburb">
                <mat-option value="" disabled selected hidden>Colonia</mat-option>
                <mat-option *ngFor="let suburb of suburbs" value="{{ suburb.nombre }}">
                  {{ suburb.nombre }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-template #openSuburb>
            <mat-form-field floatLabel="never">
              <input matInput
                type="text"
                id="suburbs"
                placeholder="Colonia"
                formControlName="suburb"
                [class.is-invalid]="invalidSuburb"
                [hidden]="hideSuburb"/>
            </mat-form-field>
          </ng-template>
          <mat-error *ngIf="invalidSuburb">
            <img src="../../assets/img/error-circle-100.svg" 
              width="32" 
              height="32" 
              alt="Account Error">
            Debe seleccionar una colonia
          </mat-error>
      </div>
      <!-- City -->
      <div class="my-2">
        <div>
          <p class="small-label pb-2">Ciudad</p>
          <ng-container *ngIf="!openControls; else openCity">
            <mat-form-field>
              <mat-select id="city"
                formControlName="city"
                [class.is-invalid]="invalidCity"
                [hidden]="infoCity">
                <mat-option *ngFor="let c of cities" [value]="c.ciudad">
                  {{ c.nombre | uppercase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-template #openCity>
              <input matInput
                type="text"
                id="city"
                placeholder="Ciudad"
                formControlName="city"
                [class.is-invalid]="invalidCity"
                [hidden]="hideCity"/>

          </ng-template>

          <mat-error *ngIf="invalidCity">
            <img src="../../assets/img/error-circle-100.svg" 
              width="32" 
              height="32" 
              alt="Account Error">
            Debe seleccionar una ciudad
          </mat-error>
        </div>
      </div>
      <!-- State -->
      <div class="my-2">
        <div>
          <p class="small-label pb-2">Estado</p>
          <ng-container *ngIf="!openControls; else openState">
            <mat-form-field floatLabel="never">
              <mat-select id="states"
                placeholder="Estado"
                formControlName="state"
                [class.is-invalid]="invalidState"
                [hidden]="hideState">
                <mat-option *ngFor="let state of states" [value]="state.estado_pais">
                  {{ state.nombre | uppercase }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-template #openState>
            <input matInput
              type="text"
              id="state"
              placeholder="Estado"
              formControlName="state"
              [class.is-invalid]="invalidState"
              [hidden]="hideState"/>
          </ng-template>

          <mat-error *ngIf="invalidState">
            <img src="../../assets/img/error-circle-100.svg" 
              width="32" 
              height="32" 
              alt="Account Error">
            Debe seleccionar una estado
          </mat-error>
        </div>
      </div>
    </div>
  </form>
</div>
