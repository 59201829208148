import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config.service';
import { Card } from './card.model';

@Injectable({
    providedIn: 'root'
})

export class ReporteResolverService implements Resolve<any> {
    private readonly BASE_URL: string;
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {
        this.BASE_URL = `${this.config.serverURL}/card-reporter`;
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Card> {
        return this.http.get<any>(
            `${this.BASE_URL}/get-card-data/${route.params.cardId}`
        );
    }

    requestReport(payload: any): Observable<any> {
        return this.http.post<any>(`${this.BASE_URL}/request-report`, payload);
    }
}
