import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddressAutofillComplementService {

  constructor(
    private http: HttpClient
  ) {}

  get authToken(): string {
    return localStorage.getItem('authToken');
  }

  get baseUrl(): string {
    return environment.serverURL;
  }

  autoFillSuburbs(postalCode: string): Observable<any> {
    const response = this.http.get(
      `${this.baseUrl}/mc-address/suburbs/${postalCode}`
    );

    return response;
  }

  autoFillStates(countryId: number, stateId?: number): Observable<any> {
    const response = this.http.get(
      `${this.baseUrl}/mc-address/states/${countryId}/${stateId}`
    );

    return response;
  }

  autoFillCities(stateId: number): Observable<any> {
    const response = this.http.get(
      `${this.baseUrl}/mc-address/cities/${stateId}`
    );

    return response;
  }
}
