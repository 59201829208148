import { Injectable } from '@angular/core';
import {HttpClient, HttpStatusCode} from '@angular/common/http';
import { RouterStateSnapshot, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ConfigService } from '../../../config.service';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ProfileService} from '../../../profile.service';
import {ProfileItemModelService} from '../../../profile-item-model.service';

@Injectable({
    providedIn: 'root'
})
export class ConsultarTarjetasResolverService implements Resolve<Observable<any>> {
    constructor(
        private http: HttpClient,
        private profileService: ProfileService,
        private profileModelService: ProfileItemModelService,
        private config: ConfigService
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return forkJoin({
            cards: this.http.get(`${this.config.serverURL}/cards/get-client-cards`).pipe(
                map(response => {
                    const { statusCode, data } = <any>response;

                    if (statusCode === HttpStatusCode.NotFound) {
                        sessionStorage.setItem('no-cards', '1');
                    }

                    const cardsCount = data.length;

                    sessionStorage.setItem('no-cards', cardsCount > 0 ? '0' : '1');

                    return response;
                }),
                catchError(error => of({ error: error }))
            ),
        });
    }
}
