import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CardsModule } from './cards/cards.module';
import { SaldosMovimientosComponent } from './saldos-movimientos/saldos-movimientos.component';
import { FiltersComponent } from './saldos-movimientos/components/filters/filters.component';
import { SummaryCardsComponent } from './saldos-movimientos/components/summary-cards/summary-cards.component';
import { MovimientosComponent } from './saldos-movimientos/movimientos/movimientos.component';
import { TarjetasComponent } from './saldos-movimientos/tarjetas/tarjetas.component';
import { CardActivationDialogComponent } from './saldos-movimientos/components/card-activation-dialog/card-activation-dialog.component';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { ChangeCompanyDialogComponent } from './change-company-dialog/change-company-dialog.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {DashboardMaterialModule} from './dashboard-material.module';
import {MaterialModule} from '../material.module';
import { CouponsComponent } from './coupons/coupons.component';

@NgModule({
    declarations: [
        SaldosMovimientosComponent,
        FiltersComponent,
        SummaryCardsComponent,
        MovimientosComponent,
        TarjetasComponent,
        CardActivationDialogComponent,
        ChangeCompanyDialogComponent,
        CouponsComponent
    ],
    imports: [
        CardsModule,
        CommonModule,
        DashboardMaterialModule,
        DashboardRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        SharedComponentsModule,
        NgxMaskModule.forChild(),
        NgxMatSelectSearchModule,
        MaterialModule,
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [],
})
export class DashboardModule { }
