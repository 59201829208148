<div class="policy-container container my-4" @fadeSlideInOut>
    <div class="policy-header">
        <div class="ms-3">
            <h5 class="app-module-title m-0">Politica de privacidad</h5>
            <p class="small-label app-module-subtitle">Esta es la politica para usuarios de plataformas de e-commerce.</p>
        </div>
    </div>
    <div class="policy-content">
        <div>
            <div class="policy-paragraph">
                <h4 class="medium-text mb-3">
                    <div class="d-flex flex-row align-items-center">
                        <span class="material-symbols-rounded square-icon me-3">person</span>
                        <strong>Datos Personales</strong>
                    </div>
                </h4>
                <p class="small-label">
                    <strong>Calimax</strong> recabará los datos personales que sean necesarios para procesar de manera
                    adecuada sus compras a través de nuestros portales de internet o tiendas en el Interior
                    de la República ya sea (i) directa o personalmente (ii) a través de sus agentes,
                    promotores, comisionistas o socios comerciales. Los datos que solicitamos son los
                    siguientes:
                </p>
                <br>
                <p class="small-label ms-3">
                    <strong>● Datos de identificación:</strong> nombre completo, dirección fiscal, teléfono de casa y/o
                    de trabajo, teléfono móvil personal y/o de trabajo, Registro Federal de
                    Contribuyentes (RFC), correo electrónico(s) para hacerle llegar el CFDI.
                </p>
                <br>
                <p class="small-label ms-3">
                    <strong>● Datos de facturación y razón social.</strong>
                </p>
            </div>
            <div class="policy-paragraph">
                <h4 class="medium-text mb-3">
                    <div class="d-flex flex-row align-items-center">
                        <span class="material-symbols-rounded square-icon me-3">cookie</span>
                        <strong>
                            Datos recabados por medio de cookies, web beacons
                            o similares
                        </strong>
                    </div>
                </h4>
                <p class="small-label">
                    Datos recabados por medio de cookies, web beacons o similares
                    Los cookies son un conocido mecanismo que almacena información sobre un
                    usuario de internet en su propio ordenador y se suelen emplear para asignar a
                    los visitantes de un sitio de internet un número de identificación individual para
                    su reconocimiento subsiguiente. Sin embargo, la existencia de los cookies y su
                    uso generalmente no están ocultos al usuario, quien puede desactivar el acceso
                    a la información de los cookies.
                </p>
                <br>
                <p class="small-label">
                    Sin embargo, dado que un sitio Web puede emplear un identificador cookie para
                    construir un perfil del usuario y este no conoce la información que se añade a
                    este perfil, se puede considerar a los cookies una forma de spyware. Por
                    ejemplo, una página con motor de búsqueda puede asignar un número de
                    identificación individual al usuario la primera vez que visita la página, y puede
                    almacenar todos sus términos de búsqueda en una base de datos con su
                    número de identificación como clave en todas sus próximas visitas (hasta que el
                    cookie expira o se borra).
                </p>
                <br>
                <p class="small-label">
                    Estos datos pueden ser empleados para seleccionar los anuncios publicitarios
                    que se mostrarán al usuario, o pueden ser transmitidos (legal o ilegalmente) a
                    otros sitios u organizaciones.
                </p>
            </div>
            <div class="policy-paragraph">
                <h4 class="medium-text mb-3">
                    <div class="d-flex flex-row align-items-center">
                        <span class="material-symbols-rounded square-icon me-3">analytics</span>
                        <strong>
                            Uso de la información recabada y transferencia de
                            datos con terceros
                        </strong>
                    </div>
                </h4>
                <p class="small-label">
                    <strong>Calimax</strong> utilizará los cookies solo con fines estadísticos para mejorar estrategias
                    comerciales.
                    En el caso de empleo de cookies, el botón de “ayuda” que se encuentra en la barra de
                    herramientas de la mayoría de los navegadores, le dirá cómo evitar aceptar nuevos
                    cookies, cómo hacer que el navegador le notifique cuando recibe un nuevo cookie o
                    cómo deshabilitar todos los cookies.
                </p>
                <br>
                <p class="small-label">
                    <strong>Calimax</strong> utilizará la información recabada de los usuarios para generar una cuenta con
                    contraseña y puedan realizar sus pedidos, procesar sus compras, responder dudas o
                    comentarios, también tenemos el compromiso de brindar el mejor servicio y dar
                    cumplimiento a las obligaciones contraídas con nuestros clientes así como informar
                    sobre cambios de nuestros productos o servicios.
                </p>
                <br>
                <p class="small-label">
                    <strong>Calimax</strong> no realiza transferencias de información con terceros. Calimax no compartirá
                    información confidencial con terceras partes, excepto que tenga expresa autorización
                    del mismo usuario o por orden judicial para cumplir con las disposiciones procesales.
                </p>
            </div>
            <div class="policy-paragraph">
                <h4 class="medium-text mb-3">
                    <div class="d-flex flex-row align-items-center">
                        <span class="material-symbols-rounded square-icon me-3">shield</span>
                        <strong>Protección</strong>
                    </div>
                </h4>
                <p class="small-label">
                    Calimax Se compromete a asegurar la privacidad de la información obtenida a través de
                    este medio. Sugerimos leer atentamente este aviso de privacidad para hacerlo
                    consciente de cómo se usarán los datos personales proporcionados. Del mismo modo,
                    hacemos de su conocimiento que el llenado de cualquier formulario o la remisión de un
                    correo electrónico a cualquiera de nuestros buzones implica la aceptación del aviso de
                    privacidad. Todos los datos obtenidos a través de su compra por este medio, serán
                    utilizados para el envío de correos electrónicos y el producto (s) comprado, sin mayor
                    responsabilidad para Calimax.
                    Con el simple hecho de visitar nuestra página web, el usuario no está obligado a facilitar
                    información personal. Una vez que usted elija el producto es indispensable dar clic en
                    “comprar” a partir de ese momento Calimax le solicitará información personal.
                    Calimax se compromete a salvaguardar la máxima protección y confidencialidad sobre
                    la información que usted nos facilite por medio del sitio y a utilizarla únicamente para
                    los fines indicados.
                </p>
            </div>
            <div class="policy-paragraph">
                <h4 class="medium-text mb-3">
                    <div class="d-flex flex-row align-items-center">
                        <span class="material-symbols-rounded square-icon me-3">info</span>
                        <strong>Limitación de uso y divulgación de información</strong>
                    </div>
                </h4>
                <p class="small-label">
                    Para limitar el uso y divulgación de sus datos, solicitarlos a través del correo
                    soporte@calimax.com.mx para ejercer los Derechos ARCO (acceso, rectificación,
                    cancelación y oposición) conforme a la Ley Federal de Protección de Datos Personales
                    en Posesión de los Particulares y su Reglamento, el titular de los Datos Personales, por
                    sí o mediante representante legal, debiendo identificarse o acreditarse correctamente,
                    podrá ejercer sus derechos de acceso, rectificación, cancelación y oposición (“Derechos
                    ARCO”), directamente llamando a los números telefónicos de la Unidad Especial de
                    Atención al Cliente, describiendo de manera clara y precisa los datos personales
                    respecto de los que se busca ejercer alguno de los Derechos ARCO.
                </p>
                <br>
                <p class="small-label">
                    I) Acceso.- Usted podrá solicitar en todo momento conocer qué datos suyos han sido
                    recabados y que son conservados por el responsable, así como información relativa a
                    las condiciones y generalidades del tratamiento.
                </p>
                <br>
                <p class="small-label">
                    II) Rectificación.- En caso de que alguno de sus datos sea inexacto o incompleto podrá
                    solicitar su corrección, señalando de que datos se trata, debiendo adjuntar la
                    documentación que acredite o ampare dicha modificación.
                </p>
                <br>
                <p class="small-label">
                    III) Cancelación.- Podrá solicitarse en todo momento la cancelación de los datos; en
                    caso de ser procedente la solicitud, iniciará un período de bloqueo en el cual no podrán
                    ser tratados de ninguna manera sus Datos Personales para su posterior supresión.
                </p>
                <br>
                <p class="small-label">
                    IV) Oposición.- En todo momento el titular podrá oponerse por causa legítima al
                    tratamiento de sus Datos Personales o solicitar que cese dicho tratamiento.
                    Los derechos ARCO podrá ejercerlos, de manera gratuita, llamando al teléfono de la
                    unidad especial de atención al Cliente. Es importante mencionarle que en la llamada
                    para ejercer derechos ARCO deberá proporcionar una dirección de correo electrónico
                    y/o número de teléfono a efecto de que podamos notificarle la respuesta a su solicitud
                    o poder contactarlo en caso de requerir información adicional en relación con su
                    petición.
                </p>
                <p class="small-label">
                    Asimismo, debe tener en cuenta que en caso de no proporcionar una dirección de
                    correo electrónico y/o número de teléfono en la llamada para ejercer derechos ARCO, su
                    petición se tendrá por no presentada, tal como lo dispone el artículo 94 del reglamento
                    de la ley federal de protección de datos personales en posesión de los particulares.
                    PONEMOS A SU DISPOSICIÓN EL SIGUIENTE CORREO ELECTRÓNICO PARA EJERCER
                    SUS DERECHOS DE ARCO
                </p>
                <p class="small-label">
                    Su solicitud deberá ir acompañada de los argumentos por los que desea ejercer
                    cualquiera de sus derechos, junto con una identificación oficial del titular de los datos o
                    su apoderado, su petición será atendida en un plazo no mayor a 20 días hábiles.
                    Asimismo, en cualquier momento podrá revocar el consentimiento al tratamiento de
                    sus datos, por el mismo medio antes señalado.
                </p>
            </div>
            <div class="policy-paragraph">
                <h4 class="medium-text mb-3">
                    <div class="d-flex flex-row align-items-center">
                        <span class="material-symbols-rounded square-icon me-3">info</span>
                        <strong>Datos Personales Sensibles</strong>
                    </div>
                    <strong></strong>
                </h4>
                <p class="small-label">
                    <strong>Calimax</strong> no recaba datos sensibles directamente desde nuestros sitios WEB, todas las
                    operaciones financieras en línea están protegidas por un servidor seguro bajo el
                    protocolo Let´s Encrypt (<strong>SSL</strong>), el cual podrá corroborar al momento de realizar su
                    compra en la barra de su navegador, dichos datos serán encriptados para asegurar su
                    resguardo.
                </p>
                <p class="small-label">
                    ● Calimax no captura, solicita ni almacena datos sensibles de nuestros clientes.
                </p>
            </div>
            <div class="policy-paragraph">
                <h4 class="medium-text mb-3">
                    <div class="d-flex flex-row align-items-center">
                        <span class="material-symbols-rounded square-icon me-3">info</span>
                        <strong>Cambios al Aviso de Privacidad</strong>
                    </div>
                </h4>
                <p class="small-label">
                    Cualquier cambio sustancial o total que se realice al presente Aviso de Privacidad podrá
                    ser realizado por la Sociedad y será comunicado mediante una publicación en un
                    periódico de amplia circulación, a través de nuestra páginas <a href="https://calimax.com.mx">www.calimax.com.mx</a> y/o
                    <a href="https://tienda.calimax.com.mx">https://tienda.calimax.com.mx</a>, o mediante correo electrónico a la dirección del titular
                    que para tales efectos mantenga la Sociedad registrada. Unidad Especial de Atención al
                    Público.
                </p>
            </div>
            <div class="policy-paragraph">
                <h4 class="medium-text mb-3">
                    <div class="d-flex flex-row align-items-center">
                        <span class="material-symbols-rounded square-icon me-3">info</span>
                        <strong>Autoridad</strong>
                    </div>
                </h4>
                <p class="small-label">
                    Si el usuario considera que han sido vulnerados sus derechos respecto de la protección
                    de datos personales, tiene el derecho de acudir a la autoridad correspondiente para
                    defender su ejercicio. La autoridad es el Instituto Nacional de Transparencia, Acceso a
                    la Información y Protección de Datos Personales (<strong>INAI</strong>), su sitio web es:
                    <a href="https://home.inai.org.mx">https://home.inai.org.mx</a>.
                </p>
            </div>
        </div>
    </div>
    <div class="policy-bottom"></div>
</div>
