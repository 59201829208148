import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {environment} from '../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UserIntegrityService {
    private readonly BASE_URL: string;
    private checkerSub: Subject<any> = new Subject<any>();
    public checker$: Observable<any> = this.checkerSub.asObservable();
    private checkerValue: any = { card: 1, phone: 1, zip: 1, personal: 1, email: 1 };

    constructor(private http: HttpClient) {
        this.BASE_URL = environment.serverURL;
    }

    public checkNow(): void {
        this.checkerSub.next();
    }

    public saveState(value: any): void {
        this.checkerValue = value;
    }

    public checkIntegrity(): Observable<any> {
        return this.http.get(`${this.BASE_URL}/data-checker/check-user`);
    }

    public get currentValue(): any {
        return this.checkerValue;
    }
}
