<div class="contact-info-container container pt-4">
    <div class="contact-info-header">
<!--        <span class="material-symbols-rounded">contacts</span>-->
        <div class="ms-3">
            <h5 class="app-module-title m-0" *ngIf="allowEmails && allowPhones && !customTitle">
                Datos de contacto
            </h5>
            <h5 class="app-module-title m-0" *ngIf="allowEmails && !allowPhones && !customTitle">
                Agrega / edita correo electrónico
            </h5>
            <h5 class="app-module-title m-0" *ngIf="!allowEmails && allowPhones && !customTitle">
                Agrega / edita núm. de teléfono
            </h5>
            <h5 class="app-module-title m-0" *ngIf="customTitle">
                {{ customTitle }}
            </h5>
            <p class="small-label m-0">Aquí podrás administrar tus datos de contacto</p>
        </div>
    </div>
    <div class="contact-info-content">
        <div class="profile-subgroup" *ngIf="allowEmails">
            <div class="profile-subgroup-item" *ngFor="let email of emails">
                <app-profile-info-item
                    [emitSelection]="observeEmailSelection"
                    [isSelected]="email === currentEmail"
                    title="Correo electrónico"
                    [value]="email.email"
                    [payload]="email"
                    [scope]="1"
                    identifier="email"
                    [verified]="email.sw_valida === 1"
                    [showUnverifiedPill]="true"
                    [showUpdateBtn]="email.es_usuario !== 1"
                    [showDeleteBtn]="email.sw_valida !== 1 && email.sw_primario !== 1"
                    [config]="configurations.email"
                    (requestEdition)="onRequestItemEdition('email', $event)"
                    (requestDeletion)="onRequestItemDeletion($event)"
                    (selected)="selectedEmail($event)">
                </app-profile-info-item>
            </div>
        </div>
        <div class="profile-subgroup" *ngIf="allowPhones">
            <div class="profile-subgroup-item" *ngFor="let phone of phones">
                <app-profile-info-item
                    [emitSelection]="observePhoneSelection"
                    [isSelected]="phone === currentPhone"
                    title="Número de teléfono"
                    [value]="phone.telefono"
                    [payload]="phone"
                    [scope]="1"
                    identifier="telefono"
                    [verified]="phone.sw_valida === 1"
                    [showUnverifiedPill]="true"
                    [showUpdateBtn]="phone.es_usuario !== 1"
                    [showDeleteBtn]="phone.sw_valida !== 1 && phone.sw_primario !== 1"
                    [config]="configurations.telefono"
                    (requestEdition)="onRequestItemEdition('telefono', $event)"
                    (requestDeletion)="onRequestItemDeletion($event)"
                    (selected)="selectedPhone($event)">
                </app-profile-info-item>
            </div>
        </div>
    </div>
    <div class="contact-info-bottom">
        <div class="multiple-separation-bottom-actions flex-grow-1">
            <button mat-flat-button
                    class="link-action-btn w-100"
                    *ngIf="allowEmails"
                    (click)="profileManager.loadAdding(
                    'email',
                    'MCEmail',
                    configurations['email'])
                ">
                <span class="material-symbols-rounded me-2">email</span>
                Agregar Correo
            </button>
            <button mat-flat-button
                    *ngIf="allowPhones"
                    class="link-action-btn w-100"
                    (click)="profileManager.loadAdding(
                    'telefono',
                    'MCPhone',
                    configurations['telefono'])
                ">
                <span class="material-symbols-rounded me-2">phone</span>
                Agregar Teléfono
            </button>
            <button mat-flat-button
                    class="role-action-btn w-100"
                    [disabled]="(observeEmailSelection && !currentEmail) || (observePhoneSelection && !currentPhone)"
                    *ngIf="observePhoneSelection || observeEmailSelection"
                    (click)="emitSelection()">
                Seleccionar
            </button>
        </div>
    </div>
</div>