import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SearchFilterByPipe } from '../../search-filter-by.pipe';
import { SaldosMovimientosService } from '../saldos-movimientos/saldos-movimientos.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-company-dialog',
  templateUrl: './change-company-dialog.component.html',
  styleUrls: ['./change-company-dialog.component.scss']
})
export class ChangeCompanyDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<ChangeCompanyDialogComponent>,
    private _saldosMovimientos: SaldosMovimientosService,
    private _searchPipe: SearchFilterByPipe,
    private _router: Router
  ) {
    this.getCompanies();
   }

  get selectSearch() {
    return this.form.get('selectSearch');
  }

  get searchCtrl() {
    return this.form.get('searchCtrl');
  }
  filteredCompanies: Observable<any>;
  companyName: string;

  form = new FormGroup({
    selectSearch: new FormControl(),
    searchCtrl: new FormControl()
  });

  ngOnInit() {
  }

  async getCompanies() {
    await this._saldosMovimientos.getCompanies().subscribe(
      response => {
        const companies = response.data;
        this.startCompaniesSelect(companies);
      }
    );
  }

  close() {
    this._dialogRef.close();
  }

  startCompaniesSelect(companies: any[]) {
    this.filteredCompanies = this.searchCtrl.valueChanges
      .pipe(
        startWith(''),
        map(company => company
          ? this._searchPipe.transform(companies, company, 'company_name')
          : companies.slice())
      );
  }

  getCompany() {
    localStorage.removeItem('company-data');

    const data = {
      company: this.companyName,
      company_id: this.selectSearch.value
    };

    localStorage.setItem('company-data', JSON.stringify(data));

    this.close();
    this._router.navigate(['/app/dashboard/saldos-y-movimientos']);
  }

  selectedCompany(event) {
    const target = event.source.selected._element.nativeElement;
    this.companyName = target.innerText.trim();
  }

}
