import {Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {BreakpointObserver} from '@angular/cdk/layout';
import {AuthService} from '../auth.service';
import {Router} from '@angular/router';
import {mobile, multiEmpEnabled, userHaveEnterpriseId} from '../consts/utilities';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {McPersonalService} from '../mc-personal.service';
import {DomSanitizer} from '@angular/platform-browser';
import {DarkModeService} from '../dark-mode.service';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {MatDialog} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
    animations: [
        trigger('fade', [
            state(
                'void',
                style({ opacity: 0, transform: 'translateY(55px)' })
            ),
            transition(':enter, :leave', [animate('600ms ease-in')])
        ]),
        trigger('fade-up', [
            state(
                'void',
                style({ opacity: 0, transform: 'translateY(105px)' })
            ),
            transition(':enter, :leave', [animate('300ms ease-in')])
        ]),
    ],
    encapsulation: ViewEncapsulation.None
})
export class LayoutComponent implements OnInit, OnDestroy {
    // @ViewChild('drawer', { static: true }) sidenav: MatSidenav;
    @ViewChild('menu', { static: true }) menu: TemplateRef<any>;

    @ViewChild(
        'profileBottomMenu', { static: true }
    ) profileBottomMenu: TemplateRef<any>;

    @ViewChild('themeSettings', { static: true }) themeSettings: TemplateRef<any>;

    public panelOpenState = false;

    public isOnEnterprise: boolean;
    public multiEnterprise = false;

    public user: { id: string, name: string, lastName: string, contact: string, picture: any } = {
        id: '',
        name: '',
        lastName: '',
        contact: '',
        picture: './assets/img/customer-100.png'
    };

    public darkModeState = false;
    public syncThemeWithOs = false;

    // layout subscriptions
    private subs: Subscription[] = [];

    constructor(
        private router: Router,
        public authService: AuthService,
        private mcPersonalService: McPersonalService,
        private breakpointObserver: BreakpointObserver,
        private sanitizer: DomSanitizer,
        private bottomSheet: MatBottomSheet,
        private darkModeService: DarkModeService,
        private dialog: MatDialog,
    ) {}

    public ngOnInit(): void {
        this.darkModeState = this.darkModeService.getDarkModeFromStorage();
        this.syncThemeWithOs = this.darkModeService.getSyncThemeModeFromStorage();
        this.isOnEnterprise = userHaveEnterpriseId();
        this.multiEnterprise = multiEmpEnabled();

        this.getUserHeaders();

        if (!this.syncThemeWithOs) {
            const dkMode$ = this.darkModeService.darkMode$.subscribe((value) => {
                this.darkModeState = value;
            });

            this.subs.push(dkMode$);
        }

        const dkSync$ = this.darkModeService.syncMode$.subscribe((value) => {
            this.syncThemeWithOs = value;

            if (!value) {
                const dkMode$ = this.darkModeService.darkMode$.subscribe((val) => {
                    this.darkModeState = val;
                });

                this.subs.push(dkMode$);
            }
        });

        this.subs.push(dkSync$);

        document.addEventListener('backbutton', () => {});
    }

    ngOnDestroy() {
        this.subs.forEach(sub => {
           sub.unsubscribe();
        });
    }

    public toggleTheme(): void {
        this.darkModeService.toggle();
    }

    public toggleSyncTheme(): void {
        this.darkModeService.toggleSync();
    }

    private getUserHeaders(): void {
        const userDataObs$ = this.mcPersonalService.getUserHeader().subscribe(
            response => {
                const { data } = response;

                if (data) {
                    const { id_cliente, nombre, apellido_p, picture, email, telefono } = data;

                    this.user = {
                        id: id_cliente,
                        name: nombre,
                        lastName: apellido_p,
                        contact: email || telefono,
                        picture: this.sanitizer.bypassSecurityTrustResourceUrl(picture),
                    };
                }
            },
            error => {
                console.error(error);
            }
        );

        this.subs.push(userDataObs$);
    }

    public closeMenu(): void {
        if (mobile()) {
            this.bottomSheet.dismiss();
        }

        if (!mobile()) {
            this.dialog.closeAll();
        }
        // this.sidenav.close().then(_ => this.panelOpenState = false);
    }

    public logout(): void {
        this.closeMenu();
        this.authService.logOut();
    }

    public onMenuItemClick(route: string): void {
        this.closeMenu();
        this.router.navigateByUrl(route)
            .then(_ => console.log(`Navigated to ${route}`));
    }

    public openThemeSettings(): void {
        this.closeMenu();
        this.dialog.open(this.themeSettings);
    }

    get mobile(): boolean {
        return mobile();
    }
}
