/*
    You can write endpoints that not trigger load bar on app while request is in progress.
*/

export const EXCLUDED_ENDPOINTS: string[] = [
    'api/cart/add-product',
    'api/checkout/generate-token',
    'api/users/get-user-headers',
    'api/manager/get-company-data',
    'api/users/get-country-code'
];

export const UrlMatchWithExcludedEndpoint = (url: string) => {
    const index = url.lastIndexOf('api');
    const matches = EXCLUDED_ENDPOINTS.find((route: string) => route === url.substr(index, url.length));

    if (matches) {
        console.log('Se omitira la barra de carga para el endpoint: ', url);
    }

    return matches;
};
