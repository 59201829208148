import { Component, OnInit } from '@angular/core';
import { SaldosMovimientosService } from '../../saldos-movimientos.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-summary-cards',
  templateUrl: './summary-cards.component.html',
  styleUrls: ['./summary-cards.component.scss']
})
export class SummaryCardsComponent implements OnInit {
  card_details: any = {};

  constructor(
    private _saldosMovimientosServices: SaldosMovimientosService,
    private _route: ActivatedRoute
  ) {

    this._route.paramMap.subscribe(params => {
        this.getCardDetails(params.get('customer_id'));
    });
  }

  ngOnInit() {
  }

  getCardDetails(customer_id: string) {
    this._saldosMovimientosServices.getCardDetails(customer_id).subscribe(
      response => {
        this.card_details = response.data[0];
      }
    );
  }

}
