import * as config from '../../environments/environment';
import jwt_decode from 'jwt-decode';
import {AccessToken} from '../models/accessToken';
import * as crypto from 'crypto-js';

export const decrypt = (value: string) => {
    const phrase = config.environment.phrase;
    const bytes = crypto.AES.decrypt(value, phrase);
    return bytes.toString(crypto.enc.Utf8);
};

export const encrypt = (value: string) => {
    const phrase = config.environment.phrase;
    return crypto.AES.encrypt(value, phrase).toString();
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
export const isNil = (value: any): value is null | undefined => {
    return value === null || value === undefined;
};

// export const authToken = () => sessionStorage.getItem('access_token');

// export const getUserFromToken = () => {
//     const token = sessionStorage.getItem('user');
//     return jwt_decode<AccessToken>(token);
// };

export const getAccount = () => {
    return localStorage.getItem('user_account');
};

export const getUserId = () => {
    return localStorage.getItem('user_id');
};

export const userEnterpriseId = () => {
    const enterpriseId = localStorage.getItem('user_enterprise_id');
    return enterpriseId;
};

export const userHaveEnterpriseId = () => {
    const enterpriseId = localStorage.getItem('user_enterprise_id');
    return enterpriseId !== null && +enterpriseId > 0;
};

export const multiEmpEnabled = () => {
    const multiEmp = localStorage.getItem('user_multi_enterprise');
    return +multiEmp > 0;
};

export const userHaveCards = () => {
    const haveCards = sessionStorage.getItem('have_cards');
    return haveCards === 'true';
};

export const mobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const onDigitInput = (event: any) => {
    let element;

    if (event.code !== 'Backspace') {
        element = event.srcElement.nextElementSibling;
    }

    if (event.code === 'Backspace') {
        element = event.srcElement.previousElementSibling;
    }

    if (element == null) {
        return;
    } else {
        element.focus();
    }
};

export const itHasOwnProperty = (obj: Object, property: string) => {
    return obj.hasOwnProperty(property);
};

export const getNumbersFromString = (resource: string) => {
    const res = resource.replace(/[^0-9]/g, '');
    return Number(res);
};

export const darkModeStatus = () => {
    const dkMode = localStorage.getItem('dark-mode');
    let isEnabled = false;

    try {
        const parsedValue = JSON.parse(dkMode);
        isEnabled = parsedValue.darkMode;
    } catch (err) {
        console.error(err);
    }

    return isEnabled;
};
