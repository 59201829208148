<form [formGroup]="cardActivationForm" (ngSubmit)="submit()">
  <h2 mat-dialog-title class="text-center">Vinculación de tarjeta</h2>

  <mat-dialog-content>
    <div class="row">
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" [ngModel]="data.email">
        <mat-error *ngIf="emailErrors">
          {{ getEmailErrors() }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Confirmar Email</mat-label>
        <input matInput formControlName="emailConfirm">
        <mat-error *ngIf="emailNotMatch">
          {{ getEmailConfirmError() }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Teléfono</mat-label>
        <input matInput formControlName="phone" [ngModel]="data.phone">
        <mat-error *ngIf="phoneErros">
          {{ getPhoneErrors() }}
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field>
        <mat-label>Confirmar Teléfono</mat-label>
        <input matInput formControlName="phoneConfirm">
        <mat-error *ngIf="phoneNotMatch">
          {{ getPhoneConfirmError() }}
        </mat-error>
      </mat-form-field>
    </div>

   <div class="row">
    <mat-form-field>
      <mat-label>Selecione un metodo de vinculación</mat-label>
      <mat-select formControlName="method">
        <mat-option *ngFor="let method of methods" [value]="method.value">
          {{method.viewValue}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="cardActivationForm.controls['method'].invalid">
        {{ getMethodErrors() }}
      </mat-error>
    </mat-form-field>
   </div>

  </mat-dialog-content>

  <mat-dialog-actions class="d-flex">
    <button mat-raised-button class="ms-auto" (click)="close()">Cerrar</button>
    <button mat-button
      type="submit"
      color="primary"
      class="mat-raised-button">
      Vincular
    </button>
  </mat-dialog-actions>
</form>