import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxBarcodeModule } from 'ngx-barcode';
import { CardsRoutingModule } from './cards-routing.module';
import { ConsultarTarjetasComponent } from './consultar-tarjetas/consultar-tarjetas.component';
import { SharedComponentsModule } from '../../shared-components/shared-components.module';
import { HttpClientModule } from '@angular/common/http';

import { CardComponent } from 'src/app/dashboard-module/cards/card/card.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DetallesTarjetasComponent } from 'src/app/detalles-tarjetas/detalles-tarjetas.component';
import { ReporteComponent } from 'src/app/reporte/reporte.component';
import { SharedModule } from '../utils/shared.module';
import { LayoutModule } from '@angular/cdk/layout';
import { RoleElementsDirective } from 'src/app/role-elements.directive';
import { NgxUpperCaseDirectiveModule } from 'ngx-upper-case-directive';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DetalleMovimientoComponent } from 'src/app/detalles-tarjetas/detalle-movimiento/detalle-movimiento.component';
import { VirtualCardComponent } from 'src/app/virtual-card/virtual-card.component';
import { PersonalInformationComponent } from './registro/personal-information/personal-information.component';
import { TaxInformationComponent } from './registro/tax-information/tax-information.component';
import { RFCComponent } from './registro/rfc/rfc.component';
import { ContactInformationComponent } from './registro/contact-information/contact-information.component';
import { AddressComponent } from './registro/address/address.component';
import { RegistroTarjetaComponent } from './registro/registro-tarjeta/registro-tarjeta.component';
import { CardLinkComponent } from './registro/card/card.component';
import { DecisionComponentComponent } from './registro/decision-component/decision-component.component';
import { MessageComponent } from './registro/message/message.component';
import { TelefonoregistroComponent } from './registro/phone-registration/1.2telefonoregistro.component';
import {SwiperModule} from 'swiper/angular';
import {CardsMaterialModule} from './cards-material.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ProfileModule} from '../../profile/profile.module';

@NgModule({
    declarations: [
        PersonalInformationComponent,
        TaxInformationComponent,
        RFCComponent,
        ContactInformationComponent,
        AddressComponent,
        ConsultarTarjetasComponent,
        CardComponent,
        RegistroTarjetaComponent,
        DecisionComponentComponent,
        MessageComponent,
        TelefonoregistroComponent,
        CardLinkComponent,
        DetallesTarjetasComponent,
        ReporteComponent,
        RoleElementsDirective,
        DetalleMovimientoComponent,
        VirtualCardComponent,
    ],
    imports: [
        CommonModule,
        CardsRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgxBarcodeModule,
        LayoutModule,
        SharedComponentsModule,
        NgxUpperCaseDirectiveModule,
        NgxMaskModule.forChild(),
        // FlipModule,
        SwiperModule,
        CardsMaterialModule,
        MatTooltipModule,
        ProfileModule,
    ],
    exports: [
        RegistroTarjetaComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CardsModule {}
