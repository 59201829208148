import {Component, OnInit, EventEmitter, Output} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';

@Component({
    selector: 'app-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

    filtersForm: FormGroup;
    @Output() filters: EventEmitter<any> = new EventEmitter();

    showFilters = false;

    constructor(
        private formBuilder: FormBuilder
    ) {
        this.createFilters();
    }

    ngOnInit() {}

    createFilters() {
        this.filtersForm = this.formBuilder.group({
            customer: ['', Validators.pattern('^[0-9]+$')],
            employee: ['', Validators.pattern('^[0-9]+$')],
            name: ['', Validators.pattern('^[A-Za-z0-9_-]*$')],
            lastname: ['', Validators.pattern('^[A-Za-z0-9_-]*$')],
            balance: [],
            gift_card: []
        });
    }

    getNumberErrors() {
        const employee = this.filtersForm.controls.employee.hasError('pattern');
        const customer = this.filtersForm.controls.customer.hasError('pattern');

        if (employee || customer) {
            return 'Sólo numeros';
        }
    }

    getStringErrors() {
        const name = this.filtersForm.controls.name.hasError('pattern');
        const lastname = this.filtersForm.controls.lastname.hasError('pattern');

        if (name || lastname) {
            return 'Sólo letras y números';
        }
    }

    getFilters() {
        this.filtersForm.value.balance = this.filtersForm.get('balance').value ? 1 : 0;
        this.filtersForm.value.gift_card = this.filtersForm.get('gift_card').value ? 1 : 0;

        this.filters.emit(this.filtersForm.value);
    }

    changeFilterState(): void {
        this.showFilters = !this.showFilters;
    }
}
