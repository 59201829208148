import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class McPersonalService {
    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.serverURL;
    }

    checkUserName(): Observable<any> {
        return this.http.get(`${this.baseUrl}/mc-personal/check-username`);
    }

    getUserHeader(origin?: string): Observable<any> {
        if (origin) {
            return this.http.get(`${this.baseUrl}/users/get-user-headers?origin=${origin}`);
        }

        return this.http.get(`${this.baseUrl}/users/get-user-headers`);
    }

    getUser(): Observable<any> {
        return this.http.get(`${this.baseUrl}/mc-personal/get-user`);
    }

    updateUser(payload: any): Observable<any> {
        const body = {
            pid_vtex: payload.id_vtex || '',
            pid_janis: payload.id_janis || '',
            pnombre: payload.nombre || '',
            pap_paterno: payload.ap_paterno || '',
            pap_materno: payload.ap_materno || '',
            psexo: payload.sexo || '',
            prfc: payload.rfc || '',
            pnss: payload.nss || '',
            pcurp: payload.curp || '',
            pf_nacimiento: payload.f_nacimiento || '',
            pid_dom: payload.id_dom || '0',
            pid_tel: payload.id || '0',
            pid_email: payload.id_email || '0',
            pid_cta: payload.id_cta || '0',
            pid_tcard: '0',
            ptipo: 'CLIENTE',
            pip: null,
            pso: null,
            papp: null
        };
        return this.http.put(`${this.baseUrl}/mc-personal/update-user`, body);
    }
}
