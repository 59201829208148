import {AfterViewInit, Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {externalComponent} from 'src/app.component.registry';
import {ProfileActionResult} from '../models/profile.action.result.model';
import {AddressAutofillComplementService} from '../../address-autofill-complement.service';
import {McAddressService} from '../../mc-address.service';
import {ProfileManagerService} from '../profile-manager.service';

@externalComponent
@Component({
    selector: 'app-address-autofill',
    templateUrl: './address-autofill.component.html',
    styleUrls: ['./address-autofill.component.scss']
})
export class AddressAutofillComponent implements OnInit, AfterViewInit {

    addressForm: FormGroup;

    suburbs: any[] = [];
    cities: any[] = [];
    states: any[] = [];

    public result: ProfileActionResult = {
        type: null,
        data: null,
        payload: null,
        canceled: true,
        model: null
    };

    type: string;
    payload: any;
    model: any;
    action: string;

    @ViewChild('confirmDeletion')
    confirmDeletion: TemplateRef<any>;

    constructor(
        private profileManager: ProfileManagerService,
        private addressAutoFillService: AddressAutofillComplementService,
        private addressService: McAddressService,
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        public dialogRef: MatDialogRef<AddressAutofillComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.type = data.type;
        this.payload = data.payload;
        this.model = data.model;
        this.action = data.action;
    }

    ngOnInit() {
        this.result = {
            type: this.type,
            data: null,
            payload: null,
            canceled: true,
            model: this.model
        };

        this.addressForm = this.formBuilder.group({
            calle: ['', Validators.required],
            numero_ext: [null, [Validators.required, Validators.maxLength(5)]],
            numero_int: [null, Validators.nullValidator],
            codigo_postal: ['', [Validators.minLength(5), Validators.maxLength(5), Validators.required]],
            colonia: ['', Validators.required],
            ciudad_id: [{value: '', disabled: true}, Validators.required],
            estado_id: [{value: '', disabled: true}, Validators.required]
        });
    }

    ngAfterViewInit(): void {
        this.registerPostalCodeListener();
        this.checkIfRequirePreloadValues();
    }

    get isFormValid(): boolean {
        return this.addressForm.valid;
    }

    get isFormInvalid(): boolean {
        return this.addressForm.invalid || this.addressForm.pristine;
    }

    private checkIfRequirePreloadValues(): void {
        if (this.action === 'UP') {
            this.preloadDataForUpdate();
        }
    }

    private preloadDataForUpdate(): void {
        this.initializeForm();
    }

    private initializeForm(): void {
        this.addressForm.setValue({
            calle: this.payload.calle,
            numero_ext: this.payload.numero_ext,
            numero_int: this.payload.numero_int,
            codigo_postal: this.payload.codigo_postal,
            colonia: this.payload.colonia,
            ciudad_id: this.payload.ciudad_id,
            estado_id: this.payload.estado_id
        });
    }

    private registerPostalCodeListener(): void {
        const cp = this.addressForm.get('codigo_postal');
        cp.valueChanges.subscribe((value) => {
            if (cp.valid) {
                this.getSuburbs(value);
            }
        });
    }

    private getSuburbs(codigo: string): void {
        this.addressAutoFillService.autoFillSuburbs(codigo).subscribe(
            (response: any) => {
                const {data, mensaje, statuscode} = response;
                const {ciudad, estado, pais} = data[0];

                this.suburbs = data;

                this.updateCityAndState(ciudad, estado, pais);
            }
        );
    }

    private getStates(countryId: number, stateId: number): void {
        this.addressAutoFillService.autoFillStates(countryId, stateId).subscribe(
            (response: any) => {
                const {data, mensaje, statuscode} = response;
                this.states = data;
            }
        );
    }

    private getCities(stateId: number): void {
        this.addressAutoFillService.autoFillCities(stateId)
            .subscribe(
                (response: any) => {
                    const {data, mensaje, statuscode} = response;
                    this.cities = data;
                }
            );
    }

    private updateCityAndState(
        cityId: number,
        stateId: number,
        countryId: number
    ): void {
        this.getCities(stateId);
        this.getStates(countryId, stateId);

        this.updateState(stateId);
        this.updateCity(cityId);
    }

    private updateState(stateId: number): void {
        this.addressForm.get('estado_id').setValue(stateId);
    }

    private updateCity(cityId: number): void {
        this.addressForm.get('ciudad_id').setValue(cityId);
    }

    onSubmit(): void {
        const data = this.addressForm.getRawValue();
        const isValid = this.isFormValid;

        if (isValid) {
            this.result = {
                type: this.type,
                data,
                payload: this.payload,
                canceled: false,
                model: this.model
            };

            this.closeDialog();
        }
    }

    private closeDialog(): void {
        this.dialogRef.close(this.result);
    }

    openDeletionAddressModal(): void {
        this.dialogRef.close();
        this.dialog.open(this.confirmDeletion);
    }

    deleteAddress(): void {
        this.addressService.deleteAddress(this.payload).subscribe(
            response => this.onAddressDeleted(response),
            error => this.onAddressDeletedError(error)
        );
    }

    private onAddressDeleted(response: any): void {
        const { data } = response;
        this.result = {
            type: this.type,
            data,
            payload: this.payload,
            canceled: false,
            model: this.model
        };
        this.dialogRef.close(this.result);
    }

    private onAddressDeletedError(error: Error): void {
        console.error(error);
    }
}
