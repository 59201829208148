import { Component, Input, OnChanges } from '@angular/core';
import { ControlContainer, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-tax-information',
  templateUrl: './tax-information.component.html',
  styleUrls: ['./tax-information.component.css']
})
export class TaxInformationComponent implements OnChanges {

  @Input()
  taxDataConfig: any;
  hideCurp = false;
  hideRfc = false;
  lettersAndNumbers = '^[a-zA-Z0-9 ]+$';
  rfcDefaultValidators: ValidatorFn[] = [];
  curpDefaultValidators: ValidatorFn[] = [];

  constructor(
    public container: ControlContainer
  ) {
    this.rfcDefaultValidators = [
      Validators.minLength(13),
      Validators.maxLength(13),
      Validators.pattern(this.lettersAndNumbers)
    ];

    this.curpDefaultValidators = [
      Validators.minLength(18),
      Validators.maxLength(18),
      Validators.pattern(this.lettersAndNumbers)
    ];
  }

  ngOnChanges(changes): void {
    if (changes.taxDataConfig) {
      this.formConfig(changes.taxDataConfig);
    }
  }

  get invalidRfc(): boolean {
    return this.container.control.get('rfc').invalid && this.container.control.get('rfc').touched;
  }

  get invalidCurp(): boolean {
    return this.container.control.get('curp').invalid && this.container.control.get('curp').touched;
  }

  formConfig({ currentValue }): void {

    if (!Object.keys(currentValue).length) {
      return;
    }

    this.optionalContorls(currentValue);
    this.requiredControls(currentValue);
    this.hideControls(currentValue);
  }

  hideControls({ rfc, curp }): void {
    if (curp.hide === 1 || curp.required === 0) {
      this.hideCurp = true;
      this.container.control.get('curp').disable();
    }

    if (rfc.hide === 1 || rfc.required === 0) {
      this.hideRfc = true;
      this.container.control.get('rfc').disable();
    }
  }

  optionalContorls({ rfc, curp }): void {
    if (rfc.required === 1) {
      this.container.control.get('rfc').setValidators(this.rfcDefaultValidators);
      this.container.control.get('rfc').updateValueAndValidity();
    }

    if (curp.required === 1) {
      this.container.control.get('curp').setValidators(this.curpDefaultValidators);
      this.container.control.get('curp').updateValueAndValidity();
    }
  }

  requiredControls({ rfc, curp }): void {
    if (rfc.required === 2) {
      this.rfcDefaultValidators.push(Validators.required);
      this.container.control.get('rfc').setValidators(this.rfcDefaultValidators);
    }

    if (curp.required === 2) {
      this.curpDefaultValidators.push(Validators.required);
      this.container.control.get('curp').setValidators(this.curpDefaultValidators);
    }

    this.container.control.updateValueAndValidity();
  }

  onlyReaddControls({ rfc, curp }): void {
    if (curp.hide === 3) {
      this.container.control.get('curp').disable();
    }

    if (rfc.hide === 3) {
      this.container.control.get('rfc').disable();
    }
  }

}
