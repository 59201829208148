import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HideCardNumberPipe } from 'src/app/hide-card-number.pipe';
import { CodeSMSInputComponent } from './components/code-smsinput/code-smsinput.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CheckmarkComponent } from './components/checkmark/checkmark.component';
import { MatButtonLoadingDirective } from './directives/mat-button-loading.directive';
import { CardNumberPipe } from 'src/app/card-number.pipe';

@NgModule({
    imports: [CommonModule, MatIconModule, MatProgressSpinnerModule],
    declarations: [
        CardNumberPipe,
        HideCardNumberPipe,
        CodeSMSInputComponent,
        CheckmarkComponent,
        MatButtonLoadingDirective
    ],
    exports: [
        CardNumberPipe,
        HideCardNumberPipe,
        CommonModule,
        FormsModule,
        CodeSMSInputComponent,
        CheckmarkComponent,
        MatButtonLoadingDirective
    ],
    // entryComponents: [MatProgressSpinner]
})
export class SharedModule {}
