<div>
    <div mat-ripple
         matRippleColor="{{
                movement.importe > 0
                    ? '#0c4d9415'
                    : '#cf152015'
            }}"
         class="movement-item"
         (click)="viewDetails()">
        <div class="movement-header">
            <div class="movement-header-textarea">
                <img class="rounded-3" src="
                        {{
                            movement.importe > 0
                                ? '../../../assets/img/transaction-up-2-100.png'
                                : '../../../assets/img/transaction-down-2-100.png'
                        }}"
                     width="32"
                     height="32"
                     alt="Transaction icon">
                <div class="ms-4">
                    <p class="small-text">
                        {{ movement.descripcion | titlecase }}
                    </p>
                    <p class="x-small-label m-0">
                        {{ movement.fecha | date: 'dd/MM/yyyy HH:mm:ss': 'UTC' }}
                    </p>
                    <p class="x-small-label m-0" *ngIf="movement.sucursal">
                        {{ movement.sucursal | titlecase }}
                    </p>
                </div>
            </div>
            <div class="d-flex flex-row">
                <div class="list-item-qty"
                     [class.accent-color]="movement.importe > 0"
                     [class.accent-red-color]="movement.importe <= 0">
                    {{ movement.importe | currency }}
                </div>
            </div>
        </div>
    </div>
    <hr class="app-module-subtitle" *ngIf="showLine"/>
    <ng-template #movementDetails>
        <div class="container">
            <div class="movement-details-header">
                <img src="
                    {{
                        movement.importe < 0
                            ? '../../../assets/img/transaction-down-2-100.png'
                            : '../../../assets/img/transaction-up-2-100.png'
                    }}"
                    width="32"
                    height="32"
                    alt="Transaction icon">
                <div class="movement-header-textarea">
                    <div>
                        <div class="medium-text list-detail-item-desc">
                            <strong>
                                {{ movement.descripcion | titlecase }}
                            </strong>
                        </div>
                        <div class="list-item-qty"
                             [class.accent-color]="movement.importe > 0"
                             [class.accent-red-color]="movement.importe <= 0">
                                {{
                                    movement.importe < 0
                                        ? (movement.importe | currency)
                                        : '+' + (movement.importe | currency)
                                }}
                        </div>
                    </div>
                </div>
            </div>
            <hr class="app-module-subtitle" />
            <div class="movement-details-content">
                <div class="py-3" *ngIf="movement.sucursal">
                    <p class="small-label">Sucursal</p>
                    <p class="medium-text">
                        {{ movement.sucursal | titlecase }}
                    </p>
                </div>
                <div class="py-3" *ngIf="movement.importe < 0">
                    <p class="small-label">Caja</p>
                    <p class="medium-text">
                        {{ checkIn | titlecase }}
                    </p>
                </div>
                <div class="py-3">
                    <p class="small-label">Referencia</p>
                    <p class="medium-text">
                        {{ transaction | titlecase }}
                    </p>
                </div>
                <div class="py-3">
                    <p class="small-label">Fecha de movimiento</p>
                    <p class="medium-text">
                        {{ movement.fecha | date: 'dd/MM/yyyy HH:mm:ss': 'UTC' }}
                    </p>
                </div>
            </div>
        </div>
        <hr class="app-module-subtitle" />
        <div class="multiple-separation-bottom-actions">
            <button mat-flat-button
                class="w-100 bg-transparent"
                [class.accent-color]="movement.importe > 0"
                [class.accent-red-color]="movement.importe <= 0"
				type="button"
                (click)="closeDetails()">
				Aceptar
			</button>
        </div>
    </ng-template>
</div>
