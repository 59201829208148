import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { TermsPayload } from './policies/models/terms.payload';

@Injectable({
    providedIn: 'root'
})
export class TermsConditionsService {

    constructor(
        private http: HttpClient,
        private config: ConfigService
    ) {}

    emitCardTermStatus(payload: TermsPayload): Observable<any> {
        const {
            userName,
            cardTermStatus,
            ipAddress,
            operatingSystem,
            device,
            operationDate
        } = payload;

        const body = {
            param_in: {
                option: 1,
                userName,
                cardTermStatus,
                ipAddress,
                operatingSystem,
                device,
                operationDate
            },
            param_out: {},
            funcion: 'vmax_terms_action_mgr'
        };

        return this.http.post(
            `${this.config.serverURL}/card-term-change`, body
        );
    }
}
