import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { ControlContainer, Validators, ValidatorFn } from '@angular/forms';
import { Country } from './interfaces/country.interface';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddressAutofillComplementService } from 'src/app/address-autofill-complement.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent implements OnInit, OnChanges {

  @Input()
  addressDataConfig: any;
  hideStreet = false;
  hideIntNumber = false;
  hideExtNumber = false;
  hideZip = false;
  hideSuburb = false;
  hideCity = false;
  hideState = false;
  hideCountry = false;
  openControls = false;
  // lettersAndNumbers = '^[a-zA-Z0-9\_\- ]*$';

  suburbs: any[];
  cities: any[];
  cityCode: number;
  stateCode: number;
  countryCode: number;
  countries: Country[];
  states: any[];
  info = false;
  infoCity = false;
  defaultValidators: ValidatorFn[] = [];

  constructor(
    public container: ControlContainer,
    private addressAutofillService: AddressAutofillComplementService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.zipListener();
    this.countries = [
      { code: 52, shortName: 'MX', name: 'México' },
      { code: 1, shortName: 'USA', name: 'Estados Unidos' }
    ];
  }

  ngOnChanges(changes) {
    if (changes.addressDataConfig) {
      this.formConfig(changes.addressDataConfig);
    }
  }

  get invalidStreet(): boolean {
    const form = this.container.control.get('street');
    return form.invalid && form.touched;
  }

  get invalidExtNumber(): boolean {
    const form = this.container.control.get('extNumber');
    return form.invalid && form.touched;
  }

  get invalidIntNumber(): boolean {
    const form = this.container.control.get('intNumber');
    return form.invalid && form.touched;
  }

  get invalidZip(): boolean {
    const form = this.container.control.get('zip');
    return form.invalid && form.touched;
  }

  get invalidSuburb(): boolean {
    const form = this.container.control.get('suburb');
    return form.invalid && form.touched;
  }

  get invalidCity(): boolean {
    const form = this.container.control.get('city');
    return form.invalid && form.touched;
  }

  get invalidState(): boolean {
    const form = this.container.control.get('state');
    return form.invalid && form.touched;
  }

  get invalidCountry(): boolean {
    const form = this.container.control.get('country');
    return form.invalid && form.touched;
  }

  zipListener(): void {
    this.container.control.get('zip').valueChanges
      .subscribe(
        zip => {
          if (zip.length !== 5) {
            return;
          }

          if (this.container.control.get('zip').valid) {
            this.getSuburbs(zip);
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getSuburbs(zip: string): void {
    this.addressAutofillService.autoFillSuburbs(zip)
      .subscribe(
        result => {
          const { data } = result;
          this.suburbs = data;
          if (data.length > 0) {
            const {
              ciudad: city,
              estado: state,
              pais: country
            } = data[0];

            this.openControls = false;
            this.disableControls();
            this.cityCode = city;
            this.stateCode = state;
            this.countryCode = country.toString();

            this.container.control.get('country').setValue(country);
            this.container.control.get('suburb').enable();

            this.getCities();
            this.getStates();
          } else {
           this.openControls = true;
           this.clearControls();
           this.enableControls();
           this.container.control.get('country').setValue(1);
           this.info = true;
           this.snackBar.open('Parece que tu código postal no pertenece a México', null, {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'right',
            panelClass: ['snackbar-error']
          });
          }
        },
        error => {
          console.log(error);
        }
      );
  }

  getCities(): void {
    this.addressAutofillService.autoFillCities(this.stateCode)
      .subscribe(
        result => {
          this.cities = result.data;
          this.container.control.get('city').setValue(this.cityCode);
        },
        error => {
          console.log(error);
        }
      );
  }

  getStates(): void {
    this.addressAutofillService.autoFillStates(this.countryCode, this.stateCode)
      .subscribe(
        result => {
          this.states = result.data;
          this.container.control.get('state').setValue(this.stateCode);
        },
        error => {
          console.log(error);
        }
      );
  }

  formConfig({ currentValue }): void {
    if (!Object.keys(currentValue).length) {
      return;
    }

    this.controlsConfig(currentValue);
  }

  controlsConfig(fields): void {
    const filteredFields = Object.keys(fields)
      .filter(value => value !== 'addressTotalToHide');

    Object.values(filteredFields).forEach(field => {
      const control = this.container.control;

      this.defaultValidators = [
        Validators.minLength(1),
        Validators.maxLength(100),
        // Validators.pattern(this.lettersAndNumbers)
      ];

      // Hidden fileds >> || fields[field].required === 0
      if (fields[field].hide === 1) {
        // control.get(field).disable();
        const hiddenField = 'hide' + field.replace(/\b\w/g, l => l.toUpperCase());
        this[hiddenField] = true;
      }

      // Optional fields
      if (fields[field].required === 1) {
        control.get(field).setValidators(this.defaultValidators);
      }

      // Require fileds
      if (fields[field].required === 2) {
        this.defaultValidators.push(Validators.required);

        control.get(field).setValidators([...new Set(this.defaultValidators)]);
      }

      // Read only fields
      if (fields[field].required === 3) {
        control.get(field).disable();
      }

      this.container.control.updateValueAndValidity();

    });
  }

  enableControls(): void {
    this.container.control.get('suburb').enable();
    this.container.control.get('city').enable();
    this.container.control.get('state').enable();
  }

  disableControls(): void {
    this.container.control.get('suburb').disable();
    this.container.control.get('city').disable();
    this.container.control.get('state').disable();
    this.container.control.get('country').disable();
  }

  clearControls(): void {
    this.container.control.get('suburb').setValue('');
    this.container.control.get('city').setValue('');
    this.container.control.get('state').setValue('');
    this.container.control.get('country').setValue('');
  }

}
