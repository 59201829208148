<div class="card-theme-color theme-shadow summary-cards">
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-4 d-none d-lg-block">
          <img src="assets/img/customer-100.png" alt="avatar">
        </div>
        <div class="col-12 col-lg-8">
          <h4 class="bold-title">{{ card_details.given_names }} {{ card_details.lastname }} {{ card_details.mother_lastname }}</h4>
          <div>
            <p class="small-label">No. de empleado</p>
            <p>{{ card_details.employee_id || 0 }}</p>
          </div>
          <div>
            <p class="small-label">No. de cliente</p>
            <p>{{ card_details.customer_id || 0 }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="summary">
        <div class="row">
          <div class="col-4">
            <p class="small-label">Saldo</p>
            <h3>{{ card_details.balance || 0 | currency }}</h3>
          </div>
          <div class="col-4">
            <p class="small-label">Depositos</p>
            <h3> {{ card_details.deposits || 0 }}</h3>
          </div>
          <div class="col-4">
            <p class="small-label">Retiros</p>
            <h3>{{ card_details.disposition || 0 }}</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>