import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserIntegrityService} from '../user-integrity.service';

@Injectable({
    providedIn: 'root'
})
export class SetupGuard implements CanActivate {

    constructor(
        private userIntegrityService: UserIntegrityService,
        private router: Router,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const { personal, card, phone, email, zip } = this.userIntegrityService.currentValue;

        if (personal === 1 && card === 1 && phone === 1 && email === 1 && zip === 1) {
            this.router.navigate(['/app/dashboard/cards']);
            return;
        }

        return true;
    }
}
