export class CustomMask {

    private _value: string;
    private _description: string;
    private _icon: string;
    private _includeCharacters = false;
    private _char: string;
    private _charIndexByLeft: number;
    private _fromLast: boolean;
    private _stage: string;

    public get value(): string { return this._value; }
    public set value(value: string) { this.value = value; }

    public get length(): number { return this._value.length; }

    public get description(): string { return this._description; }
    public set description(description: string) { this._description = description; }

    public get icon(): string { return this._icon; }
    public set icon(icon: string) { this._icon = icon; }

    public get includeCharacters(): boolean { return this._includeCharacters; }
    public set includeCharacters(includeCharacters: boolean) { this._includeCharacters = includeCharacters; }

    public get char(): string { return this._char; }
    public set char(char: string) { this._char = char; }

    public get charPosition(): number {
        const index = this._value.length - this._charIndexByLeft;
        return index;
    }
    public set charPosition(charPosition: number) {
        this._charIndexByLeft = charPosition;
    }

    public get beforeCharIndex(): number {
        return this._charIndexByLeft;
    }

    public get fromLast(): boolean { return this._fromLast; }
    public set fromLast(fromLast: boolean) { this._fromLast = fromLast; }

    public get stage(): string { return this._stage; }
    public set stage(stage: string) { this._stage = stage; }

    constructor(
        value: string,
        description: string,
        icon: string,
        includeCharacters: boolean,
        char?: string,
        charIndexByLeft?: number,
        fromLast?: boolean,
        stage?: string
    ) {
        this._value = value;
        this._description = description;
        this._icon = icon;
        this._includeCharacters = includeCharacters;
        this._char = char;
        this._charIndexByLeft = charIndexByLeft;
        this._fromLast = fromLast;
        this._stage = stage;
    }
}
