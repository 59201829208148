import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsultarTarjetasComponent } from './consultar-tarjetas/consultar-tarjetas.component';
import { ConsultarTarjetasResolverService } from './consultar-tarjetas/consultar-tarjetas-resolver.service';
import { DetallesTarjetasComponent } from 'src/app/detalles-tarjetas/detalles-tarjetas.component';
import { DetallesTarjetasResolverService } from 'src/app/detalles-tarjetas/detalles-tarjetas-resolver.service';
import { ReporteComponent } from 'src/app/reporte/reporte.component';
import { RegistroTarjetaComponent } from './registro/registro-tarjeta/registro-tarjeta.component';
import {RequiredCardStepComponent} from '../../setup/required-card-step/required-card-step.component';

const routes: Routes = [
    {
        path: '',
        component: ConsultarTarjetasComponent,
        resolve: { response: ConsultarTarjetasResolverService },
        data: { ruta: 'consulta', animation: 'HomePage' }
    },
    {
        path: 'add/card/:accountType',
        component: RegistroTarjetaComponent,
        data: { animation: 'HomePage' }
    },
    {
        path: 'question/card',
        component: RequiredCardStepComponent,
        data: { animation: 'HomePage' }
    },
    {
        path: 'details/card/:cardId',
        component: DetallesTarjetasComponent,
        resolve: { response: DetallesTarjetasResolverService }
    },
    {
        path: 'report/card/:cardId',
        component: ReporteComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CardsRoutingModule { }
