import {Component, Inject, OnInit} from '@angular/core';
import {ProfileActionResult} from '../models/profile.action.result.model';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-profile-delete-modal',
  templateUrl: './profile-delete-modal.component.html',
  styleUrls: ['./profile-delete-modal.component.scss']
})
export class ProfileDeleteModalComponent implements OnInit {
    private result: ProfileActionResult = {
        type: null,
        data: null,
        payload: null,
        canceled: true,
        model: null
    };

    type: string;
    payload: any;
    model: any;
    action = 'DL';

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<ProfileDeleteModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit(): void {
        this.result = {
            type: this.type,
            data: null,
            payload: this.payload,
            canceled: false,
            model: this.model
        };
    }

    confirmDeletion(): void {
        this.dialogRef.close(this.result);
    }

    cancelDeletion(): void {
        this.result.canceled = true;
        this.dialogRef.close(this.result);
    }

}
