import {Component, OnInit} from '@angular/core';
import { DashboardService } from './dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {NavigationEnd, Router} from '@angular/router';
import {mobile} from './consts/utilities';
import {DarkModeService} from './dark-mode.service';
import {UserIntegrityService} from './user-integrity.service';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {AuthService} from './auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'Calimax';

    private routerSub: Subscription;

    constructor(
        private authService: AuthService,
        private dashboardService: DashboardService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private darkModeService: DarkModeService,
        private userIntegrityService: UserIntegrityService,
    ) {
        this.subscribeAppTheme();
        this.checkUserDataIntegrity();

        window.addEventListener('load', () => {
            this.calculateResponsiveValues();
        });
        window.addEventListener('resize',  () => { this.calculateResponsiveValues(); });
        window.addEventListener('orientationchange', () => { this.calculateResponsiveValues(); });

        this.calculateResponsiveValues();
    }

    ngOnInit() {
        this.checkSessionState();
        this.dashboardService.spin$.subscribe(value => {
            if (value) {
                this.spinner.show();
            } else {
                this.spinner.hide();
            }
        });

        // Activar en base a dato de backend
        this.routerSub = this.router.events.subscribe(ev => {
            const loggedIn = this.authService.loggedIn;

            if (ev instanceof NavigationEnd && loggedIn) {
                this.userIntegrityService.checkNow();
            }
        });
    }

    private checkSessionState(): void {
        const isLoggedIn = this.authService.loggedIn;
        if (isLoggedIn) {
            this.authService.checkSession().subscribe(
                (response) => {
                    const { statusCode } = response;

                    if (statusCode === HttpStatusCode.Ok) {
                        localStorage.setItem('cw_sess_st', 'ok');
                    }
                },
                (error: HttpErrorResponse) => {
                    const { status } = error;

                    if (status === HttpStatusCode.Unauthorized) {
                        localStorage.setItem('cw_sess_st', 'invalid');
                    }
                }
            );
        }
    }

    private checkUserDataIntegrity(): void {
        this.userIntegrityService.checker$.subscribe(() => {
            this.userIntegrityService.checkIntegrity().subscribe((response) => {
                const {statusCode, data} = response;
                const showedToUser = sessionStorage.getItem('user-canceled-card-reg') === '1';
                const userSkippedEmail = sessionStorage.getItem('user-skip-email') === '1';
                const userSkippedPhone = sessionStorage.getItem('user-skip-phone') === '1';

                if (statusCode !== HttpStatusCode.Ok) {
                    return;
                }

                // Save for other stages
                this.userIntegrityService.saveState(data);

                const {card, phone, personal, email, zip} = data;

                if (personal === 0) {
                    this.router.navigate(['/setup/add-data']);
                    return;
                }

                if (phone === 0 && !userSkippedPhone) {
                    this.router.navigate(['/setup/add-contact-data'], { queryParams: { type: 'phone' }});
                    return;
                }

                if (zip === 0) {
                    this.router.navigate(['/setup/confirm-zip-code']);
                    return;
                }

                if (email === 0 && !userSkippedEmail) {
                    this.router.navigate(['/setup/add-contact-data'], { queryParams: { type: 'email' }});
                    return;
                }

                if (card === 0 && !showedToUser) {
                    this.router.navigate(['/setup/add-card']);
                    return;
                }

                this.routerSub.unsubscribe();
            });
        });
    }

    private subscribeAppTheme(): void {
        const darkModeState = this.darkModeService.getDarkModeFromStorage();
        const syncThemeWithOs = this.darkModeService.getSyncThemeModeFromStorage();

        if (syncThemeWithOs) {
            this.darkModeService.syncTheme();
            return;
        }

        if (darkModeState) {
            this.darkModeService.enable();
        }

        if (!darkModeState) {
            this.darkModeService.disable();
        }
    }

    private calculateResponsiveValues(): void {
        const styler: CSSStyleDeclaration = document.documentElement.style;

        const currentViewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const toolbarHeight = 70;
        const bottomToolbarHeight = this.isMobile ? 60 : 0;
        const topPadding = 24;
        const bottomPadding = toolbarHeight + bottomToolbarHeight + topPadding;

        const viewportCalculations = currentViewportHeight - bottomPadding;

        styler.setProperty('--inner-height', `${viewportCalculations}px`);
        styler.setProperty('--toolbar-height', `${toolbarHeight}px`);
        styler.setProperty('--default-top-padding', `${topPadding}px`);
        styler.setProperty('--default-bottom-padding', `${bottomPadding}px`);
    }

    private get isMobile(): boolean {
        return mobile();
    }
}
