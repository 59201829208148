import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnDestroy {

  private onSubject = new Subject<{ key: string, value: any }>();
  public changes = this.onSubject.asObservable().pipe(share());

  constructor() {
    this.start();
  }

  ngOnDestroy(): void {
    this.stop();
  }

  public getStorage() {
    const s = [];
    for (let i = 0; i < sessionStorage.length; i++) {
        s.push({
            key: sessionStorage.key(i),
            value: JSON.parse(sessionStorage.getItem(sessionStorage.key(i)))
        });
    }
    return s;
  }

  public store(key: string, data: any): void {
      sessionStorage.setItem(key, data);
      // the local application doesn't seem to catch changes to localStorage...
      this.onSubject.next({key, value: data});
  }

  public clear(key: string): void {
      sessionStorage.removeItem(key);
      // the local application doesn't seem to catch changes to localStorage...
      this.onSubject.next({key, value: null});
  }

  public clearAll(): void {
    sessionStorage.clear();
    this.onSubject.next(null);
  }

  private start(): void {
      window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
      if (event.storageArea === sessionStorage) {
          let v;
          try {
              v = JSON.parse(event.newValue);
          } catch (e) {
              v = event.newValue;
          }
          this.onSubject.next({key: event.key, value: v});
      }
  }

  private stop(): void {
      window.removeEventListener('storage', this.storageEventListener.bind(this));
      this.onSubject.complete();
  }
}
