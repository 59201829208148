<div class="profile-data-card" mat-ripple matRippleColor="#DDDDDD25"
    [ngClass]="config.group !== 'personal' ? [ 'card-theme-color', 'theme-shadow', 'p-3', 'rounded-4' ] : []"
    (click)="selectedItem()">
    <div class="profile-data-card-content">
        <span class="material-symbols-rounded" *ngIf="emitSelection">{{ isSelected ? 'radio_button_checked' : 'radio_button_unchecked' }}</span>
        <div class="data-card-text">
            <div class="d-flex flex-row align-items-center"
                [class.gap-3]="showUpdateBtn || showDeleteBtn">
                <p class="x-small-label spaced-label text-uppercase pb-2">{{ title }}</p>
                <div *ngIf="verified" class="header-pill data-card-verified-pill m-0">
                    <span class="material-symbols-rounded">done</span>
<!--                    <p class="m-0">Verificado</p>-->
                </div>
            </div>
<!--            <p class="data-card-value" *ngIf="value || value !== ''">{{ value }}</p>-->
<!--            <p class="medium-label fw-bold" *ngIf="!value || value === ''">(Agregar)</p>-->
            <div class="d-flex flex-row justify-content-between align-items-center gap-3">
                <mat-form-field  *ngIf="config.group === 'personal' && type !== 'date'" class="w-100" floatLabel="never">
                    <input matInput
                           class="edition-field"
                           [id]="identifier"
                           [formControl]="control" />
                </mat-form-field>
                <input *ngIf="config.group !== 'personal' && editing && type !== 'date'"
                       class="edition-field w-100 border-0 fw-bold card-theme-color theme-shadow"
                       [id]="identifier"
                       [formControl]="control" />
                <p class="medium-text fw-bold" *ngIf="config.group !== 'personal' && !editing">{{ value }}</p>
                <ng-container *ngIf="type === 'date'">
                    <mat-form-field class="w-100">
                        <input matInput
                               [id]="identifier"
                               [formControl]="control"
                               [matDatepicker]="dtPicker">
                        <mat-datepicker-toggle matSuffix [for]="dtPicker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #dtPicker></mat-datepicker>
                    </mat-form-field>
                </ng-container>
                <div *ngIf="showUpdateBtn || showDeleteBtn" class="d-flex flex-row gap-3"
                     [ngClass]="showUpdateBtn || showDeleteBtn ? [] : [ 'd-none' ]">
                    <button mat-mini-fab *ngIf="config.group !== 'personal' && showUpdateBtn"
                            class="attenuated-accent-primary-btn"
                            (click)="onEditionBtnClick()">
                        <span class="material-symbols-rounded medium-text">
                            {{ editing ? 'done': 'edit' }}
                        </span>
                    </button>
                    <button mat-mini-fab
                            *ngIf="showDeleteBtn"
                            class="attenuated-accent-red-btn"
                            (click)="deleteDialog()">
                        <span class="material-symbols-rounded text-danger">delete</span>
                    </button>
                </div>
            </div>
<!--            <div *ngIf="showUnverifiedPill && !verified" class="header-pill data-card-not-verified-pill card-theme-border-color">-->
<!--                <span class="material-symbols-rounded medium-text">question_mark</span>-->
<!--                <p class="small-label m-0">Aun no se ha verificado</p>-->
<!--            </div>-->
        </div>
    </div>
</div>
