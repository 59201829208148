// tslint:disable: variable-name
export class MCConfigGroup {
    type: string;
    value_type: string;
    name: string;
    dialog_name: string;
    icon: string;
    group: string;
    template: string;
    multivalue: boolean;
    require_custom_dialog: boolean;
    caller: string;
    class: string;
    edit_dialog_name: string;
    adding_dialog_name: string;
}
