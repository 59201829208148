export const McClientConst: any = {
      paction: 'UP',
      pid_cliente: '',
      pid_vtex: '',
      pid_janis: '',
      pnombre: '',
      pap_paterno: '',
      pap_materno: '',
      psexo: '',
      prfc: '',
      pcurp: '',
      pnss: '',
      pf_nacimiento: null,
      pid_dom: 0,
      pid_tel: 0,
      pid_email: 0,
      pid_cta: 0,
      pid_tcard: 0,
      ptipo: 'CLIENTE',
      pip: null,
      pso: null,
      papp: null,
};
