export class TermsPayload {

    public operationId = '';
    public userName = '';
    public id = '';
    public cardTermStatus = 0;
    public ipAddress = '0.0.0.0';
    public operatingSystem = '';
    public device = '';
    public operationDate: Date = null;
    public userId: number;

    constructor(
        operationId: string = '',
        userName: string = '',
        cardTermStatus: number = 0,
        ipAddress: string = '0.0.0.0',
        operatingSystem: string = '',
        device: string = '',
        operationDate: Date = null,
        userId: number
    ) {
        this.operationId = operationId;
        this.userName = userName;
        this.cardTermStatus = cardTermStatus;
        this.ipAddress = ipAddress;
        this.operatingSystem = operatingSystem;
        this.device = device;
        this.operationDate = operationDate;
        this.userId = userId;
    }
}
