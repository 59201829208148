<div>
    <form [formGroup]="container.control">
        <div class="form-responsive">
            <div class="my-3">
                <app-responsive-input id="rfc"
                    controlName="rfc"
                    type="text"
                    placeholder="RFC con homoclave"
                    [showIcon]="false"
                    [enableLabel]="true"
                    labelText="RFC"
                    [enableEnterEvent]="true"
                    [class.is-invalid]="invalidRfc"
                    [viewElementRef]="viewElementRef"
                    (enter)="onEnterEvent()">
                </app-responsive-input>
                <mat-error *ngIf="invalidRfc">
                    <img src="../../assets/img/error-circle-100.svg" 
                        width="32" 
                        height="32" 
                        alt="Account Error">
                    RFC invalido
                </mat-error>
            </div>
        </div>
    </form>
</div>