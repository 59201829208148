import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProfileComponent} from './profile.component';
import {ProfileRoutingModule} from './profile.routing.module';
import {NgxMaskModule} from 'ngx-mask';
import {ProfileInfoItemComponent} from './profile-info-item/profile-info-item.component';
import {ProfilePersonalComponent} from './profile-personal/profile-personal.component';
import {ProfileContactComponent} from './profile-contact/profile-contact.component';
import {ProfileAddressesComponent} from './profile-addresses/profile-addresses.component';
import {AddressAutofillComponent} from './address-autofill/address-autofill.component';
import {MaterialModule} from '../material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {EditProfileComponent} from './edit-profile/edit-profile.component';
import {AddInfoComponent} from './add-info/add-info.component';
import {ProfileManagerService} from './profile-manager.service';
import {ProfileDataService} from './profile-data.service';
import {ProfileDeleteModalComponent} from './profile-delete-modal/profile-delete-modal.component';
import {AddressItemComponent} from './profile-addresses/address-item/address-item.component';
import {PersonalDataRequestScreenComponent} from './personal-data-request-screen/personal-data-request-screen.component';

@NgModule({
    declarations: [
        ProfileComponent,
        ProfileInfoItemComponent,
        ProfilePersonalComponent,
        ProfileContactComponent,
        ProfileAddressesComponent,
        AddressAutofillComponent,
        EditProfileComponent,
        AddInfoComponent,
        ProfileDeleteModalComponent,
        AddressItemComponent,
        PersonalDataRequestScreenComponent,
    ],
    providers: [
        ProfileManagerService,
        ProfileDataService
    ],
    imports: [
        NgxMaskModule.forRoot(),
        CommonModule,
        ReactiveFormsModule,
        ProfileRoutingModule,
        MaterialModule,
    ],
    exports: [
        ProfilePersonalComponent,
        PersonalDataRequestScreenComponent,
        ProfileContactComponent,
        ProfileInfoItemComponent,
    ]
})
export class ProfileModule {
}
