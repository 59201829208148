import { ProfileInstantiableClasses } from './models/profile.instantiable.classes';

export class DynamicClassLoader {
    constructor(className: string, opts?: any) {
        if (ProfileInstantiableClasses[className] === undefined ||
            ProfileInstantiableClasses[className] === null) {
                throw new Error(`Class type of \'${className}\' is not in the store`);
        }

        return new ProfileInstantiableClasses[className](opts);
    }
}
