<ng-container [formGroup]="controlContainer.control">
    <div class="d-flex flex-column">
        <div class="d-flex flex-row align-items-center" *ngIf="enableLabel">
            <div class="d-flex" *ngIf="showIcon">
                <span matPreffix class="material-symbols-rounded"
                      *ngIf="iconType === 'MATERIAL_SYMBOL'">{{ icon }}</span>
                <img matPreffix [src]="icon" alt="Banner icon" *ngIf="iconType === 'IMAGE'"/>
            </div>
            <p class="small-label pb-2">{{ labelText }}</p>
        </div>
        <div class="d-flex flex-column">
            <mat-form-field floatLabel="never">
                <ng-container *ngIf="enableEnterEvent">
                    <!-- [mask]="mask" -->
                    <input matInput
                           [id]="id"
                           formControlName="{{ controlName }}"
                           [placeholder]="placeholder"
                           [type]="type"
                           [pattern]="pattern"
                           (keyup.enter)="onEnterEvent()"
                           (focus)="onFocusedEvent()"
                           (focusout)="onFocusedOutEvent()"/>
                </ng-container>
                <ng-container *ngIf="!enableEnterEvent">
                    <input matInput
                           formControlName="{{ controlName }}"
                           [placeholder]="placeholder"
                           [type]="type"
                           [pattern]="pattern"
                           (focus)="onFocusedEvent()"
                           (focusout)="onFocusedOutEvent()"/>
                </ng-container>
            </mat-form-field>
        </div>
    </div>
</ng-container>


