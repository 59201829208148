<div @fade>
    <div class="pt-4">
        <div class="container">
            <p class="app-module-title large-text fw-bold pb-3">
                Hola {{ nameToShow }}
            </p>
        </div>
        <div class="dashboard-top-toolbar container"
            aria-label="Cards Menu">
                <button class="card-theme-color theme-shadow"
                        mat-ripple matRippleColor="#0c4d9445"
                        [routerLink]="[routesList[1].path]">
                    <span class="material-symbols-rounded dashboard-top-item-1">
                            barcode_scanner
                    </span>
                    <span class="small-text m-0">Scan & Go</span>
                </button>
                <button class="card-theme-color theme-shadow"
                        mat-ripple matRippleColor="#FA002545"
                        [routerLink]="['/app/dashboard/cards/add/card/owner']">
                    <span class="material-symbols-rounded dashboard-top-item-2">add_card</span>
                    <span class="small-text m-0">Vincular tarjeta</span>
                </button>
                <button class="card-theme-color theme-shadow"
                        mat-ripple matRippleColor="#FB8E2645"
                        [routerLink]="[routesList[3].path]">
                    <span class="material-symbols-rounded dashboard-top-item-4">receipt_long</span>
                    <span class="small-text m-0">Ver tickets</span>
                </button>
                <button class="card-theme-color theme-shadow"
                        mat-ripple matRippleColor="#D0008B45"
                        [routerLink]="[routesList[4].path]">
                    <span class="material-symbols-rounded dashboard-top-item-3">shopping_bag</span>
                    <span class="small-text m-0">Mis compras</span>
                </button>
        </div>
        <div class=" container pt-4">
            <p class="x-small-label text-uppercase spaced-label m-0 pb-3">Anuncios</p>
            <swiper [config]="adsConfig" [autoplay]="true" #swiperAdsSlide>
                <ng-template swiperSlide *ngFor="let ad of ads">
                    <div class="template template-wide card-theme-color theme-shadow">
                        <img [hidden]="mobile" [src]="ad.desktop_image_path"
                             [style.width]="'100%'"
                             [style.height]="'auto'">
                        <img *ngIf="mobile" [src]="ad.mobile_image_path"
                             [style.width]="'100%'"
                             [style.height]="'auto'">
                    </div>
                </ng-template>

                <div class="swiper-pagination"></div>
                <!--                <div class="swiper-button-next"></div>-->
                <!--                <div class="swiper-button-prev"></div>-->
            </swiper>
        </div>
    </div>
    <div class="cards-container container">
        <div class="card-container-header">
            <p class="x-small-label text-uppercase spaced-label m-0 pt-3">TARJETAS</p>
        </div>
        <div class="card-container-content">
            <div class="card-adaptive-grid" *ngIf="cards.length > 0; else cardsNoValid">
                <div class="card-adaptive-item" *ngFor="let card of cards; trackBy: identify; let i = index">
                    <app-card (selectedCard)="selectCard($event)"
                              [card]="card"
                              [cardIndex]="i"
                              [cardNoSelected]="selectedCard"
                              (genericTermPref)="updateTermPref($event)"
                              (requestedDeletion)="onRequestCardDeletion($event)"
                              [newTermPref]="termsPreference"
                              [pinValidationIn]="pinValidationIn">
                    </app-card>
                </div>
            </div>
            <ng-template #cardsNoValid>
                <div class="no-cards-container">
                    <div class="text-center">
                        <img src="../../../../assets/img/add_item.png"
                             class="mb-3"
                             [style.border-radius]="'18px'"
                             width="100"
                             height="100"
                             alt="No cards image">
                        <h5 class="app-module-title mb-3">
                            Para continuar agrega una tarjeta
                        </h5>
                        <p class="small-label">
                            Podrás consultar tus saldos, tickets de compra, movimientos y
                            reportes de tarjeta
                        </p>
                    </div>
                </div>
            </ng-template>
        </div>
        <div class="card-container-bottom"></div>
    </div>
</div>
