<div class="container" @fadeSlideInOut>
    <app-filters (filters)="getFilterEmployees($event)"></app-filters>
    <div class="emp-table card-theme-color theme-shadow">
        <div class="d-flex flex-row justify-content-between align-items-center"
            *ngIf="!!employees && employees.length > 0">
            <mat-form-field floatLabel="never">
                <mat-label>Filtrar</mat-label>
                <input matInput (keyup)="applyFilter($event.target.value)">
            </mat-form-field>
        </div>
        <div>
            <mat-table *ngIf="!!employees && employees.length > 0" [dataSource]="dataSource">
                <!-- Company -->
                <ng-container matColumnDef="company">
                    <mat-header-cell *matHeaderCellDef>Cliente</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-cell-data" data-label="Cliente">
                        <p class="small-text">
                            <strong>{{ row.customer_id }}</strong>
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Progress Column -->
                <ng-container matColumnDef="employee">
                    <mat-header-cell *matHeaderCellDef>Empleado</mat-header-cell>
                    <mat-cell *matCellDef="let row" class="mat-cell-data"
                              data-label="Empleado">
                        <p class="small-text">
                            {{ row.employee_id }}
                        </p>
                    </mat-cell>
                </ng-container>
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                    <mat-header-cell *matHeaderCellDef>Nombre</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="nombre">
                        <p class="small-text emp-name-width-mxw">
                            {{ row.given_names | titlecase }} {{ row.lastname | titlecase }} {{ row.mother_lastname | titlecase }}
                        </p>
                    </mat-cell>
                </ng-container>

                <!-- Balance Column -->
                <ng-container matColumnDef="balance">
                    <mat-header-cell *matHeaderCellDef>Saldo</mat-header-cell>
                    <mat-cell *matCellDef="let row" data-label="Saldo">
                        <h5 class="medium-text"><span class="mat-cell-increased-font">{{ row.balance | currency }}</span></h5>
                    </mat-cell>
                </ng-container>

                <!-- Movements Column -->
                <ng-container matColumnDef="movements">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <button mat-flat-button
                            [routerLink]="['movimientos', row.customer_id]"
                            [style.font-size]="'12px'"
                            class="attenuated-accent-primary-btn w-100 me-1 rounded-pill py-0 px-4">
                            Movimientos
                        </button>
                    </mat-cell>
                </ng-container>

                <!-- Link Column -->
                <!-- <ng-container matColumnDef="link">
                  <mat-header-cell *matHeaderCellDef></mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <button (click)="openCardActivationDialog(row)" class="btn btn-light">
                      {{ row.card_status ? 'Vinvulada' : 'Vincular'}}
                    </button>
                  </mat-cell>
                </ng-container> -->

                <!-- Cards Column -->
                <ng-container matColumnDef="cards">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let row">
                        <button mat-flat-button
                                [routerLink]="['tarjetas', row.customer_id]"
                                [style.font-size]="'12px'"
                                class="attenuated-accent-primary-btn w-100 ms-1 rounded-pill py-0 px-4">
                            Tarjetas
                        </button>
                    </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;">
                </mat-row>
            </mat-table>
            <div *ngIf="employees.length == 0" class="py-5 mt-5 text-center">
                <h5 class="app-module-title mb-0 custom-title">
                    No hay resultados.
                </h5>
                <p class="small-label">
                    Prueba a cambiar la busqueda o presionar el boton de busqueda.
                </p>
            </div>
        </div>
        <mat-paginator [pageSizeOptions]="[10, 25, 50]">
        </mat-paginator>
    </div>
</div>