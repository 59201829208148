<div mat-ripple class="profile-data-card card-theme-color theme-shadow">
    <div class="profile-data-card-content"
        (click)="profileManager.loadEditor(
            'direccion',
            scope,
            address,
            config,
            addressComponent
        )">
            <div class="data-card-text">
            <p class="x-small-label">{{ 'Calle y numero' }}</p>
            <p class="medium-text">
                <strong>{{ address.calle | titlecase }} {{ address.numero_ext }}</strong>
            </p>
        </div>
            <div class="data-card-text">
            <p class="x-small-label">{{ 'Colonia o fraccionamiento' }}</p>
            <p class="medium-text">
                <strong>{{ address.colonia | titlecase }}</strong>
            </p>
        </div>
            <div class="data-card-text">
            <p class="x-small-label">{{ 'Código postal' }}</p>
            <p class="medium-text">
                <strong>{{ address.codigo_postal }}</strong>
            </p>
        </div>
            <div *ngIf="showMore">
                <div class="data-card-text">
                <p class="x-small-label">{{ 'Ciudad' }}</p>
                <p class="medium-text">
                    <strong>{{ address.ciudad | titlecase }}</strong>
                </p>
            </div>
                <div class="data-card-text">
                <p class="x-small-label">{{ 'Estado' }}</p>
                <p class="medium-text">
                    <strong>{{ address.estado | titlecase }}</strong>
                </p>
            </div>
            </div>
    </div>
    <div class="p-3">
        <button mat-mini-fab
                *ngIf="allowDelete"
                class="attenuated-accent-red-btn"
                (click)="deleteDialog()">
            <span class="material-symbols-rounded text-danger">delete</span>
        </button>
    </div>
</div>