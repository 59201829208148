import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {Observable, of} from 'rxjs';
import { DashboardService } from '../dashboard.service';
import { ProfileService } from '../profile.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProfileResolverService implements Resolve<any> {
    constructor(
        private profileService: ProfileService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return this.profileService.getProfileHeader().pipe(
            map(res => res),
            catchError(error => {
                return of({ error });
            })
        );
    }
}
