<div class="decision-container">
  <!-- <p class="small-label mt-0 mb-4">Selecciona una opcion para vincular tu tarjeta</p> -->

  <div class="add-card-options">
    <div mat-ripple class="add-card-option card-theme-color theme-shadow"
      (click)="getRegisterType(true)">
        <div class="d-flex flex-row">
            <span class="material-symbols-rounded square-icon">credit_card</span>
            <div class="d-flex align-items-center ms-3">
                <p class="m-0"><strong>Número de tarjeta</strong></p>
<!--                <p class="small-label">Mediante número de tarjeta</p>-->
            </div>
        </div>
        <span class="material-symbols-rounded">arrow_forward</span>
    </div>
<!--    <div mat-ripple class="add-card-option card-theme-color theme-shadow"-->
<!--      (click)="getRegisterType(false)">-->
<!--        <div class="d-flex flex-row">-->
<!--            <span class="material-symbols-rounded square-icon">phone</span>-->
<!--            <div class="ms-3">-->
<!--                <p class="m-0"><strong>Numero de telefono</strong></p>-->
<!--                <p class="small-label">Vincular mediante tu numero de telefono</p>-->
<!--            </div>-->
<!--        </div>-->
<!--        <span class="material-symbols-rounded">arrow_forward</span>-->
<!--    </div>-->
  </div>
</div>
