<div class="container">
  <div class="add-info-header">
      <div class="d-flex flex-row align-items-center">
<!--          <span class="material-symbols-rounded">add</span>-->
          <div>
              <p class="x-small-text text-uppercase spaced-label m-0">Agregar {{ model.name }}</p>
              <p class="small-label m-0">Presiona Confirmar para guardar cambios.</p>
          </div>
      </div>
      <div [hidden]="isMobile">
        <button (click)="closeDialog()"
            class="dialog-close"
            aria-hidden="true">
        </button>
      </div>
  </div>
  <div *ngIf="model">
      <form #profileEditionFrm
          [formGroup]="addingForm"
          class="edition-form">
          <div class="edition-item" *ngFor="let item of getItems(model.template); trackBy: identify">
              <div>
                  <p class="small-label edition-tag">
                      {{ item.name }}
                  </p>
                  <div [ngSwitch]="item.type">
                      <div *ngSwitchCase="'text'">
                          <mat-form-field>
                            <!-- mask="{{ item.mask }}" -->
                              <input matInput
                                  class="edition-field"
                                  id="{{ item.id }}"
                                  [class.is-invalid]="isInvalidInput(item.id)"
                                  formControlName="{{ item.id }}"
                                  focus />
                          </mat-form-field>
                      </div>
                      <div *ngSwitchCase="'number'">
                          <mat-form-field floatLabel="never">
                              <input matInput
                                  class="edition-field"
                                  id="{{ item.id }}"
                                  [class.is-invalid]="isInvalidInput(item.id)"
                                  formControlName="{{ item.id }}"
                                  focus />
                          </mat-form-field>
                      </div>
                      <div *ngSwitchCase="'date'">
                          <mat-form-field floatLabel="never">
                              <input matInput
                                  id="{{ item.id }}"
                                  formControlName="{{ item.id }}"
                                  [class.is-invalid]="isInvalidInput(item.id)"
                                  [matDatepicker]="picker"
                                  [readonly]="true">
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker touchUi #picker></mat-datepicker>
                          </mat-form-field>
                      </div>
                      <div *ngSwitchCase="'dropdown'">
                          <mat-form-field floatLabel="never">
                              <mat-select formControlName="{{ item.id }}">
                                  <mat-select-trigger>
                                      {{ addingForm.get(item.id).value }}
                                  </mat-select-trigger>
                                  <mat-option *ngFor="let option of item.options; trackBy: identify" [value]="option.value">
                                      {{ option.name }}
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                      </div>
                      <div *ngSwitchDefault>
                          <h5 class="non-editable-title">Edicion no disponible</h5>
                          <p class="non-editable-text">Al parecer este tipo de dato no esta soportado o se trata de algun error.</p>
                      </div>
                  </div>
              </div>
              <!-- For non primary data option -->
              <!-- <div *ngIf="item.index == 0">
                  <mat-checkbox
                      class="edition-indexable-check"
                      color="primary"
                      formControlName="{{item.id}}_indexable">
                          Convertir {{item.name}} en principal
                  </mat-checkbox>
                  <div>
                      <p class="edition-indexable-advisory">
                          Ten en cuenta que solo se puede establecer <strong>un(a) {{item.name}}
                          por cuenta</strong>. De esta forma el/la {{item.name}} <strong>predeterminado/a</strong>  actual
                          dejara de ser nuestro dato primario para los fines que correspondan.
                          Por lo tanto asegurate de tener acceso a ese <strong>{{item.name}}</strong> para evitar
                          problemas de comunicacion.
                      </p>
                  </div>
              </div>
              <ng-template #non_editable>
                  <h5 class="non-editable-title">Esta informacion no se puede editar</h5>
                  <p class="non-editable-text">Si deseas actualizar este dato contacta al equipo de soporte tecnico.</p>
              </ng-template>  -->
          </div>
      </form>
      <mat-error class="text-danger mb-3" *ngIf="addingForm.hasError('accountExists')">
          <img src="../../../assets/img/error-circle-100.svg"
               width="32"
               height="32"
               alt="Account Error">
        Esta cuenta ya esta siendo utilizada.
      </mat-error>
  </div>
  <div class="multiple-separation-bottom-actions">
      <button
          mat-flat-button
          class="attenuated-accent-primary-btn w-100"
          type="button"
          [disabled]="isFormInvalid"
          (click)="onSubmit()">
          <strong>Confirmar</strong>
      </button>
      <button
          mat-flat-button
          class="attenuated-accent-red-btn me-auto w-100"
          (click)="closeDialog()">
          <strong>Cerrar</strong>
      </button>
  </div>
</div>

