<nav class="bottom-nav">
    <a mat-ripple matRippleColor="#CCCCCC35" class="bottom-nav__destination"
        [routerLink]="['/app/dashboard/cards']"
        [routerLinkActive]="['active-icon', 'bottom-nav__destination--active']">
        <span class="material-symbols-rounded">
            home
        </span>
        <span class="bottom-nav__label">Inicio</span>
    </a>

    <a mat-ripple matRippleColor="#CCCCCC35" class="bottom-nav__destination"
       [routerLink]="['/app/scan-and-go']"
       [routerLinkActive]="['active-icon', 'bottom-nav__destination--active']"
       [routerLinkActiveOptions]="{ exact: true }">
        <span class="material-symbols-rounded"
            routerLinkActive="active-icon">
            barcode_scanner
        </span>
        <span class="bottom-nav__label">Scan & Go</span>
    </a>

    <a mat-ripple matRippleColor="#CCCCCC35" class="bottom-nav__destination"
       [routerLink]="['/app/in-store-shopping']"
       [routerLinkActive]="['active-icon', 'bottom-nav__destination--active']">
        <span class="material-symbols-rounded">
            shopping_bag
        </span>
        <span class="bottom-nav__label">Compra en ti...</span>
<!--        <span class="bottom-nav__badge px-1">3</span>-->
    </a>

<!--    <a class="bottom-nav__destination"-->
<!--       [routerLink]="['/profile']"-->
<!--       [routerLinkActive]="['active-icon', 'bottom-nav__destination&#45;&#45;active']">-->
<!--        <span class="material-symbols-rounded"-->
<!--            routerLinkActive="material-symbols-sharp">-->
<!--            account_circle-->
<!--        </span>-->
<!--        <span class="bottom-nav__label">Perfil</span>-->
<!--    </a>-->

    <a mat-ripple matRippleColor="#CCCCCC35" class="bottom-nav__destination"
       (click)="openMenu()"
       [routerLinkActive]="['active-icon', 'bottom-nav__destination--active']">
        <span class="material-symbols-rounded">
            menu
        </span>
        <span class="bottom-nav__label">Menu</span>
    </a>
</nav>

