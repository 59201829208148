<mat-toolbar color="primary">
    <mat-toolbar-row class="d-flex justify-content-between" [class.container]="!mobile">
        <button mat-icon-button
            *ngIf="isOnMainRoute && !mobile"
            class="toolbar-user-opts bg-transparent"
            (click)="openMenu()">
            <span class="material-symbols-rounded">menu</span>
        </button>
        <button [hidden]="isOnMainRoute"
                mat-icon-button
                type="button"
                class="toolbar-user-opts bg-transparent"
                aria-label="Toggle sidenav"
                (click)="backNavigation()">
                <span class="material-symbols-rounded">
                    arrow_back
                </span>
        </button>
        <span class="toolbar-spacer" [hidden]="isOnMainRoute && mobile"></span>
        <p class="text-center medium-text text-uppercase spaced-label m-0">
            {{ title }}
        </p>
        <span class="toolbar-spacer"></span>
        <button mat-button
            *ngIf="mobile"
            class="card-theme-color theme-shadow rounded-picture p-0 m-0"
            (click)="openProfileMenu()">
            <img class="rounded-picture"
                 [src]="user.picture"
                 alt="User Picture">
<!--            {{ user.name | titlecase }} {{ user.lastName | titlecase }}-->
        </button>
        <button mat-button
            [hidden]="mobile"
            class="card-theme-color theme-shadow rounded-picture p-0 m-0"
            [matMenuTriggerFor]="profileMenu">
            <img class="rounded-picture"
                 [src]="user.picture"
                 alt="User Picture">
<!--            {{ user.name | titlecase }} {{ user.lastName | titlecase }}-->
        </button>
        <mat-menu #profileMenu="matMenu" class="p-1">
            <div class="pt-3 px-3 pb-2 d-flex flex-column align-items-start">
                <div>
                    <p class="medium-text">
                        <strong>
                            {{ user.name | titlecase }} {{ user.lastName | titlecase }}
                        </strong>
                    </p>
<!--                    <p class="small-label"> {{ user.contact }} </p>-->
                </div>
<!--                <div class="mt-2 user_id_pill glance-card-theme-color">-->
<!--                    <p class="medium-text fw-bold">{{ user.id }}</p>-->
<!--                </div>-->
            </div>
            <div class="d-flex flex-column gap-3">
                <ng-container *ngIf="isMultiEmp">
                    <div class="glance-card-theme-color rounded-4">
                        <button (click)="openCompaniesDialog()"
                                mat-menu-item>
                            <span class="material-symbols-rounded">settings</span>
                            Modificar empresa
                        </button>
                    </div>
                </ng-container>
                <div class="glance-card-theme-color rounded-4">
                    <button mat-menu-item
                            (click)="goToProfile()">
                        <span class="material-symbols-rounded">account_circle</span>
                        Perfil
                    </button>
                    <hr class="app-module-subtitle m-0" />
                    <button mat-menu-item
                            (click)="logout()">
                        <span class="material-symbols-rounded">logout</span>
                        Cerrar sesión
                    </button>
                </div>
                <div class="glance-card-theme-color rounded-4">
                    <button mat-menu-item
                            (click)="goToPolicy()">
                        <span class="material-symbols-rounded">shield</span>
                        Politica de privacidad
                    </button>
                </div>
            </div>
            <div class="d-flex flex-row align-items-center" [style.padding]="'0.75rem'">
                <span class="material-symbols-rounded square-icon me-3">info</span>
                <div>
                    <p class="small-label">
                        <strong>CaliWallet</strong>
                    </p>
                    <p class="small-label">v{{ appVersion }}</p>
                </div>
            </div>
        </mat-menu>
    </mat-toolbar-row>
</mat-toolbar>

<ng-template #profileBottomMenu>
    <div class="pill-drag-indicator"></div>
    <div class="p-4 d-flex flex-row justify-content-between">
        <div class="d-flex flex-column">
            <span class="medium-text">
                <strong>
                    {{ user.name | titlecase }}
                    {{ user.lastName | titlecase }}
                </strong>
            </span>
            <div class="mt-2 user_id_pill glance-card-theme-color">
                <p class="medium-text fw-bold">{{ user.id }}</p>
            </div>
<!--            <p class="small-label"> {{ user.contact }} </p>-->
        </div>
<!--        <div class="mt-2 user_id_pill glance-card-theme-color">-->
<!--            <p class="medium-text fw-bold">{{ user.id }}</p>-->
<!--        </div>-->
    </div>
    <ng-container *ngIf="isMultiEmp">
        <hr class="app-module-subtitle" />
        <button (click)="openCompaniesDialog()"
            mat-menu-item>
            <span class="material-symbols-rounded">settings</span>
            Modificar empresa
        </button>
    </ng-container>
    <hr class="app-module-subtitle" />
    <button mat-menu-item
        (click)="goToProfile()">
        <span class="material-symbols-rounded">account_circle</span>
        Perfil
    </button>
    <button mat-menu-item
        (click)="logout()">
        <span class="material-symbols-rounded">logout</span>
        Cerrar Sesión
    </button>
    <hr class="app-module-subtitle" />
    <div class="d-flex flex-row align-items-center" [style.padding]="'0.75rem'">
        <span class="material-symbols-rounded square-icon me-3">info</span>
        <div>
            <p class="small-label">
                <strong>CaliWallet</strong>
            </p>
            <p class="small-label">v{{ appVersion }}</p>
        </div>
    </div>
</ng-template>