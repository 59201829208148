import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../config.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SaldosMovimientosService {
  baseUrl = '';

  constructor(
    private config: ConfigService,
    private http: HttpClient
  ) {
      this.baseUrl = `${config.serverURL}/manager`;
  }

  getFilterEmployees(filters: any): Observable<any> {
    const body = {
        companyId: filters.company_id,
        customerId: filters.customer || 0,
        employeeId: filters.employee || 0,
        name: filters.name,
        lastname: filters.lastname,
        balance: filters.balance,
        giftCard: filters.gift_card,
    };

    return this.http.post(`${this.baseUrl}/filter-employees`, body);
  }

  getCardDetails(customerId: string): Observable<any> {
    const body = {customer_id: customerId };
    return this.http.post(`${this.baseUrl}/get-card-details`, body);
  }

  getCardsByEmployee(companyId: number, customerId: number): Observable<any> {
    const body = {
      companyId,
      customerId,
    };

    return this.http.post(`${this.baseUrl}/get-cards-by-employee`, body);
  }

  getCardMovements(data: any): Observable<any> {
    const body = {
        company_id: data.company_id,
        id: data.id,
        date: data.dadate,
    };

    return this.http.post(`${this.baseUrl}/get-cards-movements`, body);
  }

  linkEmployeeCard(form: any): Observable<any> {
    const body = {
      param_in: {
        customer_id: form.customer_id,
        email: form.email,
        method: form.method,
        phone: form.phone
      },
      param_out: {},
      funcion: 'sp_link_vales_card'
    };

    return this.http.post(`${this.baseUrl}/link-card`, body);
  }

  getCompanyData(companyId: number, filter: string): Observable<any> {
    const body = { companyId, filter };
    return this.http.post(`${this.baseUrl}/get-company-data`, body);
  }

  getCompanies(): Observable<any> {
    return this.http.get(`${this.baseUrl}/get-companies`);
  }

}
