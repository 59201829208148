import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PinSessionManagerService implements OnDestroy {

    private pinValidationSubject =
        new BehaviorSubject<{ isAuthenticated: boolean }>(
                { isAuthenticated: false }
        );
    private pinValidationChanges = this.pinValidationSubject.asObservable();

    constructor() { }

      ngOnDestroy(): void {
        this.pinValidationSubject.unsubscribe();
      }

      public setAuthState(isAuthenticated: boolean): void {
        this.pinValidationSubject.next({ isAuthenticated });
      }

      public listenChanges(): Observable<{ isAuthenticated: boolean }> {
        if (this.pinValidationSubject.closed) {
          this.resetSubscription();
        }

        return this.pinValidationChanges;
      }

      private resetSubscription(): void {
        this.pinValidationSubject =
            new BehaviorSubject<{ isAuthenticated: boolean }>(
              { isAuthenticated: false }
            );

        this.pinValidationChanges = this.pinValidationSubject.asObservable();
      }

      public get pinValidationStatus(): boolean {
        return this.pinValidationSubject.value.isAuthenticated;
      }
}
