// tslint:disable: variable-name
export class MCEmail {
    codigo: number;
    email: string;
    es_usuario: number;
    estado: string;
    id_cliente: string;
    id_email: string;
    mensaje: string;
    statuscode: number;
    sw_primario: number;
    sw_valida: number;
    tipo_email: string;

    constructor(
        codigo: number = 0,
        email: string = '',
        es_usuario: number = 0,
        estado: string = '',
        id_cliente: string,
        id_email: string = '',
        mensaje: string = '',
        statuscode: number = 0,
        sw_primario: number = 0,
        sw_valida: number = 0,
        tipo_email: string = 'APP',
    ) {
        this.codigo = codigo;
        this.email = email;
        this.es_usuario = es_usuario;
        this.estado = estado;
        this.id_cliente = id_cliente;
        this.id_email = id_email;
        this.mensaje = mensaje;
        this.statuscode = statuscode;
        this.sw_primario = sw_primario;
        this.sw_valida = sw_valida;
        this.tipo_email = tipo_email;
    }
}
