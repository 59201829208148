import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '../auth-guard.service';
import { ProfileAddressesComponent } from './profile-addresses/profile-addresses.component';
import { ProfileContactComponent } from './profile-contact/profile-contact.component';
import { ProfilePersonalResolverService } from './profile-personal/profile-personal.resolver.service';
import { ProfilePersonalComponent } from './profile-personal/profile-personal.component';
import { ProfileResolverService } from './profile-resolver.service';
import { ProfileComponent } from './profile.component';
import {ProfileAddressesResolverService} from './profile-addresses/profile-addresses.resolver.service';
import {ProfileContactResolverService} from './profile-contact/profile-contact.resolver.service';

const routes: Routes = [
    {
        path: '',
        component: ProfileComponent,
        resolve: { headers: ProfileResolverService },
        canActivate: [AuthGuardService]
    },
    {
        path: 'personal',
        component: ProfilePersonalComponent,
        resolve: { response: ProfilePersonalResolverService }
    },
    {
        path: 'contact',
        component: ProfileContactComponent,
        resolve: { response: ProfileContactResolverService }
    },
    {
        path: 'addresses',
        component: ProfileAddressesComponent,
        resolve: { response: ProfileAddressesResolverService }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProfileRoutingModule {}
