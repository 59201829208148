import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import {emailRegex} from './consts/regex-patterns.consts';
import {encrypt, getAccount, getUserId} from './consts/utilities';

@Injectable({
    providedIn: 'root'
})
export class CardService  {
    url = this.config.serverURL;

    constructor(
        private config: ConfigService,
        private _http: HttpClient
    ) {}

    public deleteCardByNumber(cardId: number): Observable<any> {
        const params = { card: cardId };
        return this._http.delete(`${this.url}/cards/delete-card`, {
            params
        });
    }

    // verifica si existe un pin
    pinExists(): Observable<any> {
        return this._http.get(`${this.config.serverURL}/virtual-card/check-pin-existence`);
    }

    // Valida si el pin ingresado es correcto
    pinValidation(pin: string): Observable<any> {
        const body = { pin };
        return this._http.post(`${this.config.serverURL}/virtual-card/check-pin`, body);
    }

    // guarda el pin creado
    savePin(pin: string): Observable<any> {
        const body = { pin };
        return this._http.post(`${this.config.serverURL}/virtual-card/generate-pin`, body);
    }

    sendResetCode(): Observable<any> {
        const preload = { custom: true };
        return this._http.post(`${this.config.serverURL}/virtual-card/reset-pin-code`, preload);
    }

    validateResetCode(code: string): Observable<any> {
        const account = getAccount();
        const id = getUserId();
        const method = emailRegex.test(account) ? '1' : '2';
        const body = {
            userId: id,
            account,
            code,
            method
        };

        return this._http.post(`${this.config.serverURL}/verification/code-confirmation`, body);
    }

    // actualiza el pin
    resetPin(pin: string): Observable<any> {
        const body = { pin };
        return this._http.post(`${this.url}/virtual-card/reset-pin`, body);
    }
}
