<div mat-dialog-content class="p-5">
    <h5 class="app-module-title">Selecciona una empresa</h5>
    <p class="small-label mb-3">Estás actualmente en: {{ companyName }}</p>
    <form [formGroup]="form">
        <mat-form-field floatLabel="never" class="w-100">
            <mat-select [disableOptionCentering]="true"
                        formControlName="selectSearch"
                        placeholder="Selecciona una empresa"
                        (selectionChange)="selectedCompany($event)">
                <mat-option>
                    <ngx-mat-select-search formControlName="searchCtrl" [placeholderLabel]="'Filtrar'"
                                           [noEntriesFoundLabel]="'No se encontraron resultados'"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let company of filteredCompanies | async" [value]="company.company_id">
                    {{ company.company_name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="multiple-separation-bottom-actions">
            <button mat-flat-button
                    class="role-action-btn w-100"
                    (click)="getCompany()">
                Seleccionar
            </button>
            <button mat-flat-button
                    class="attenuated-accent-red-btn w-100"
                    (click)="close()">
                Cerrar
            </button>
        </div>
    </form>
</div>