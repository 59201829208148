import {Component, OnDestroy, OnInit} from '@angular/core';
import { MCAddress } from '../models/mc.address.model';
import { AppTitleService } from '../../app-title.service';
import { MatDialog } from '@angular/material/dialog';
import { MCConfigGroup } from '../models/mc.group.config.model';
import {ProfileManagerService} from '../profile-manager.service';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {AddressAutofillComponent} from '../address-autofill/address-autofill.component';
import {Subscription} from 'rxjs';
import {ProfileActionResult} from '../models/profile.action.result.model';
import {ProfileDataService} from '../profile-data.service';

@Component({
  selector: 'app-profile-addresses',
  templateUrl: './profile-addresses.component.html',
  styleUrls: ['./profile-addresses.component.scss']
})
export class ProfileAddressesComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];

    error: Error = null;
    addresses: MCAddress[] = [];

    configurations: any = {
        direccion: new MCConfigGroup()
    };

    public addressComponent: any = AddressAutofillComponent;

    constructor(
        private dialog: MatDialog,
        private titleService: AppTitleService,
        public profileManager: ProfileManagerService,
        private profileDataService: ProfileDataService,
        private activatedRoute: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.titleService.setTitle('Direcciones');

        const addingSub = this.profileManager.addingChanges$.subscribe(
            (value: { result, type }) => {
                if (value) {
                    const { result, type } = value;
                    this.addInData(result, type);
                }
            }
        );

        this.subscriptions.push(addingSub);

        this.activatedRoute.data.subscribe(value => {
            const { response } = value;
            const { addressesResponse, addressesConfigResponse } = response;

            if (addressesResponse instanceof HttpErrorResponse) {
                this.error = addressesResponse;
            }

            if (addressesConfigResponse instanceof HttpErrorResponse) {
                this.error = addressesConfigResponse;
            }

            const { data: addresses } = addressesResponse;
            const { data: addressConfig } = addressesConfigResponse;

            this.addresses = addresses;
            this.configurations.direccion = addressConfig[0];
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    onRequestItemDeletion(event: ProfileActionResult): void {
        const {type, payload} = event;
        const rawPayload = JSON.stringify(payload);

        if (type === 'direccion') {
            this.profileDataService.addressRequest('DL', rawPayload, (result) => {
                const {data} = result;
                this.removeFromData(data[0], type);
            });
        }
    }

    private addInData(result: any, type: string): void {
        switch (type) {
            case 'direccion':
                this.addresses.push(result.data[0]);
                break;
        }
    }

    private removeFromData(item: any, type: string): void {
        switch (type) {
            case 'direccion':
                this.addresses = this.addresses.filter((address) => address.domicilio_id !== item.domicilio_id);
                break;
        }
    }
}
