import { Injectable } from '@angular/core';
import {ProfileService} from '../profile.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileDataService {

  constructor(
      private profileService: ProfileService
  ) { }

    public personalRequest(
        paction: string,
        prequest: string,
        onResponse: (result: any) => void
    ): void {
        this.profileService.clienteAPI(paction, prequest).subscribe(
            response => onResponse(response),
            error => console.error(error)
        );
    }

    public emailRequest(
        paction: string,
        email: any,
        onResponse: (result: any) => void
    ): void {
        this.profileService.emailAPI(paction, email).subscribe(
            (response: any) => onResponse(response),
            error => console.error(error)
        );
    }

    public phoneRequest(
        paction: string,
        phone: any,
        onResponse: (result: any) => void
    ): void {
        this.profileService.phoneAPI(paction, phone).subscribe(
            (response: any) => onResponse(response),
            error => console.error(error)
        );
    }

    public addressRequest(
        paction: string,
        address: any,
        onResponse: (result: any) => void
    ): void {
        this.profileService.addressAPI(paction, address).subscribe(
            (response: any) => onResponse(response),
            error => console.error(error)
        );
    }
}
