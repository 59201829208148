import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService, MatToolbarInterface } from 'src/app/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CardMovemwnts } from '../../../models/CardMovements.model';
import { SaldosMovimientosService } from '../saldos-movimientos.service';
import * as jwt from 'jwt-decode';
import { AccessToken } from 'src/app/models/accessToken';

interface Months {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.scss']
})

export class MovimientosComponent implements OnInit {
  matMenuState: MatToolbarInterface;
  matElevationValue: string;
  activationForm: FormGroup;
  displayedColumns = ['date', 'transaction', 'in', 'out', 'balance', 'reference'];
  dataSource: MatTableDataSource<CardMovemwnts>;
  movements: any;
  selectedCard: number;
  customer_id: number;
  card_id: number;
  months: Months[] = [
    { value: 1, viewValue: 'Hace 1 mes' },
    { value: 2, viewValue: 'Hace 2 meses' },
    { value: 3, viewValue: 'Hace 3 meses' }
  ];
  selectedDate = 0;
  company: any = {};
  // accessToken: AccessToken;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private _dashboardService: DashboardService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _saldosMovimientosService: SaldosMovimientosService
  ) {

    if (localStorage.getItem('company-data') === null) {
      this._router.navigate(['/dashboard']);
    }

    this.company = JSON.parse(localStorage.getItem('company-data'));
    // this.accessToken = jwt<AccessToken>(window.sessionStorage.getItem('access_token'));

    const title = this.company.company;

    this.matMenuState = {
      title: title,
      action: 'back',
      color: 'warn',
      icon: 'arrow_back',
      isButtonVisible: true,
      route: '/dashboard/saldos-y-movimientos'
    };

    this._dashboardService.matMenuObs$.next(this.matMenuState);

    this._dashboardService.executeFn$.subscribe(action => {
      this.back(this.matMenuState.route);
    });

    this._route.paramMap.subscribe(params => {
      this.customer_id = parseInt(params.get('customer_id'), 10);
      this.card_id = parseInt(params.get('card_id'), 10);

      if (this.card_id !== null) {
        this.getCardMovements(this.customer_id);
      } else {
        this.getCardMovements(this.card_id, 0, 'card');
      }
    });
  }

  ngOnInit() {
    this.createForm();
  }

  back(route: string) {
    this._router.navigateByUrl(route);
  }

  createForm() {
    this.activationForm = this._formBuilder.group({
      user: ['', Validators.required],
      card: ['', Validators.required]
    });
  }

  getCardMovements(id: number, date: number = 0, action: string = 'customer') {
    const data = {
      company_id: this.company.company_id,
      date,
      id,
      action
    };

    this._saldosMovimientosService.getCardMovements(data).subscribe(
      response => {
        this.movements = response.data;

        this.dataSource = new MatTableDataSource(this.movements);
        this.dataSource.paginator = this.paginator;

      },
      _ => {
        this.movements = [];
      }
    );
  }

  getMovementsByDate() {
    const date = this.selectedDate;

    if (this.card_id !== null) {
      this.getCardMovements(this.customer_id, date);
    } else {
      this.getCardMovements(this.card_id, date, 'card');
    }
  }
}
