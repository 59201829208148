<div class="message-container">
  <p class="small-label mt-4">
    Información de la cuenta asociada
  </p>
  <p class="medium-text">
    <strong>{{ name | titlecase }} {{ lastName | titlecase }} {{ motherLastName | titlecase }}</strong>
  </p>
   <p class="small-label mt-4">
    Al continuar esta cuenta se actualizará con tus datos
   </p>
   <ng-container *ngIf="showBtn">
     <div class="multiple-separation-bottom-actions mt-4">
       <button mat-flat-button
        class="link-action-btn"
        (click)="personalFormStep(showBtn)">
        Editar información personal
      </button>
     </div>
   </ng-container>
</div>

