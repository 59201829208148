import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.scss']
})
export class ModalDeleteComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<ModalDeleteComponent>) { }

  ngOnInit(): void {
  }

borrar() {
    this.dialogRef.close('borrar');
}

cancelar() {
    this.dialogRef.close('cancelar');
}

}
