import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardService } from '../dashboard.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth.service';
import { ReporteResolverService } from './reporte-resolver.service';
import { FlipAnimation } from '../animations/flip.animation';
import { Location } from '@angular/common';
import { AppTitleService } from '../app-title.service';
import { decrypt } from '../consts/utilities';

@Component({
    selector: 'app-reporte',
    templateUrl: './reporte.component.html',
    styleUrls: ['./reporte.component.scss'],
    animations: FlipAnimation.animations
})
export class ReporteComponent implements OnInit {
    selectedReason: any = { text: 'Extravio', value: 2 };

    card: any;
    saldo: number;

    reportForm: FormGroup;

    @ViewChild('reportModal', {})
    reportModal: MatDialog;

    @ViewChild('confirmationToSend', { static: true })
    confirmationToSend: TemplateRef<any>;

    @ViewChild('reportContainer', {})
    viewElementRef: ElementRef<any>;

    reasons = [
        { text: 'Extravío', value: 2 },
        { text: 'Daño', value: 3 }
    ];

    public activedBtn: number;
    public serverError: boolean;

    resultMessage: string;
    details: string;
    reportId: number;

    constructor(
        private fb: FormBuilder,
        public dashboard: DashboardService,
        public dialog: MatDialog,
        private auth: AuthService,
        private location: Location,
        private titleService: AppTitleService,
        private reportService: ReporteResolverService,
        // private cardService: CardsServiceService,
        private route: Router,
    ) {
        this.activedBtn = 2;
        this.card = JSON.parse(sessionStorage.getItem('reporte_tarjeta'));
        this.card.cuenta_r = decrypt(this.card.cuenta_r);
        this.saldo = JSON.parse(
            sessionStorage.getItem('reporte_tarjeta_saldo')
        );

        this.dashboard.executeFn$.subscribe(
            action => this.location.back()
        );

        this.titleService.setTitle('Reporte de tarjeta');
    }

    ngOnInit() {
        this.dashboard.setTitle('Reportar Tarjeta');

        this.reportForm = new FormGroup({
            reasonId: new FormControl(2, [Validators.required]),
            comments: new FormControl('')
        });
    }

    onCancel(): void {
        this.route.navigate(['/dashboard/cards']);
    }

    onReasonSelected(event) {
        this.selectedReason = event.value;
    }

    sendReport(): void {
        const payload: any = {
            cardId: this.card.id,
            reasonId: this.selectedReason.value,
            cardNumber: this.card.cuenta_r,
            cardCurrentAmount: this.saldo,
            comments: this.reportForm.get('comments').value,
        };

        this.reportService.requestReport(payload).subscribe(
            (response: any) => this.onSuccessReport(response),
            (error: Error) => this.onErrorReport(error)
        );
    }

    onSubmit(): void {
        this.sendReport();
    }

    private onSuccessReport(response: any): void {
        const { data } = response;
        const { codigo, mensaje, id_rep } = data;

        this.reportId = id_rep;
        this.details = mensaje;

        this.openDialog(this.reportModal);

        if (codigo !== 0) {
            this.serverError = true;
            this.resultMessage = 'Algo sucedio al enviar tu reporte';
        }
    }

    private onErrorReport(error: Error): void {
        const { message, name, stack } = error;

        this.serverError = true;
        this.resultMessage = message;

        this.openDialog(this.reportModal);
    }

    private openDialog(content): void {
        this.dialog.open(content, {
            width: 'auto'
        });
    }

    dismissDialog(): void {
        this.dialog.closeAll();
    }

    redirectToDashboard() {
        this.route.navigate(['/dashboard/cards']);
    }
}
