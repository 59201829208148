import {Component, ElementRef, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import { ControlContainer, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css']
})
export class ContactInformationComponent implements OnChanges {

  @Input()
  contactDataConfig: any;
  hidePhone = false;
  hideEmail = false;
  emailPattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  defaultValidators: ValidatorFn[] = [];

  countries = [
      { area: 52, description: 'MX' },
      { area: 1, description: 'US' }
  ];
  selectedCountry = { area: 52, description: 'MX' };
  invalidCode = false;

  @Output() enterEvent = new EventEmitter<any>();
  @Input() viewElementRef: ElementRef<any>;

  constructor(public container: ControlContainer) {}

  ngOnChanges(changes): void {
    if (changes.contactDataConfig) {
      this.formConfig(changes.contactDataConfig);
    }
  }

  get invalidPhone(): boolean {
    return this.container.control.get('phone').invalid && this.container.control.get('phone').touched;
  }

  get invalidEmail(): boolean {
    return this.container.control.get('email').invalid && this.container.control.get('email').touched;
  }

  formConfig({ currentValue }): void {
    console.log('FormConfig: ' + !Object.keys(currentValue).length);
    if (!Object.keys(currentValue).length) {
      return;
    }

    this.controlsConfig(currentValue);

    const phone = currentValue.phone.value;

    this.container.control.get('phone').setValue(phone);
  }

  controlsConfig(fields): void {
    const filteredFields = Object.keys(fields)
      .filter(value => value !== 'contactTotalToHide');

    // if (!fields.email.hide) {
    //   this.defaultValidators.push(Validators.pattern(this.emailPattern));
    // }

    Object.values(filteredFields).forEach(field => {
      const control = this.container.control;

      // Hidden fileds
      if (fields[field].hide === 1 || fields[field].required === 0) {
        control.get(field).disable();
        const hiddenField = 'hide' + field.replace(/\b\w/g, l => l.toUpperCase());
        this[hiddenField] = true;
      }

      // Optional fields
      if (fields[field].required === 1) {
        control.get(field).setValidators(this.defaultValidators);
      }

      // Require fileds
      if (fields[field].required === 2) {
        this.defaultValidators.push(Validators.required);
        control.get(field).setValidators([...new Set(this.defaultValidators)]);
      }

      // Read only fields
      if (fields[field].required === 3) {
        control.get(field).disable();
      }

      this.container.control.updateValueAndValidity();
    });
  }

    onSelectedCountry(event: any): void {
      this.selectedCountry = event.value;
    }

  onEnterEvent(): void {
    this.enterEvent.emit();
  }
}
