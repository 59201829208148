import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '../auth-guard.service';
import { SaldosMovimientosComponent } from './saldos-movimientos/saldos-movimientos.component';
import { MovimientosComponent } from './saldos-movimientos/movimientos/movimientos.component';
import { TarjetasComponent } from './saldos-movimientos/tarjetas/tarjetas.component';
import {CouponsComponent} from './coupons/coupons.component';

const routes: Routes = [
    // {
    //     path: 'beneficiaries',
    //     loadChildren: () =>
    //         import('./beneficiaries/beneficiaries.module').then(
    //             m => m.BeneficiariesModule
    //         )
    // },
    {
        path: 'link-account',
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import('../link-account/link-account.module').then(
                m => m.LinkAccountModule
            )
    },
    {
        path: 'cards',
        canActivate: [AuthGuardService],
        loadChildren: () =>
            import('./cards/cards.module').then(m => m.CardsModule)
    },
    {
        path: 'coupons',
        canActivate: [AuthGuardService],
        component: CouponsComponent,
    },
    {
        path: 'saldos-y-movimientos',
        canActivate: [AuthGuardService],
        component: SaldosMovimientosComponent
    },
    {
        path: 'saldos-y-movimientos/movimientos/:customer_id/:card_id',
        canActivate: [AuthGuardService],
        component: MovimientosComponent
    },
    {
        path: 'saldos-y-movimientos/movimientos/:customer_id',
        canActivate: [AuthGuardService],
        component: MovimientosComponent
    },
    {
        path: 'saldos-y-movimientos/tarjetas/:customer_id',
        canActivate: [AuthGuardService],
        component: TarjetasComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule {}
