import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import {LayoutComponent} from './layout/layout.component';
import {environment} from '../environments/environment';
import {APP_BASE_HREF} from '@angular/common';
import {SetupGuard} from './setup/setup.guard';


const routes: Routes = [
    {
        path: `account`,
        loadChildren: () =>
            import('./public/public.module').then((m) => m.PublicModule)
    },
    {
        path: 'setup',
        component: LayoutComponent,
        canActivate: [SetupGuard],
        loadChildren: () =>
            import('./setup/setup.module').then((m) => m.SetupModule)
    },
    {
        path: `app`,
        component: LayoutComponent,
        loadChildren: () =>
            import('./layout/layout.module').then((m) => m.LayoutModule)
    },
    {
        path: '**',
        redirectTo: 'account',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules,
        relativeLinkResolution: 'legacy',
        onSameUrlNavigation: 'ignore',
        scrollPositionRestoration: 'top',
    })],
    providers: [
        {provide: APP_BASE_HREF, useValue: `/v${environment.version}`}
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
