import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatStepper} from '@angular/material/stepper';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';

import {CardLinkComponent} from '../card/card.component';
import {RFCComponent} from '../rfc/rfc.component';
import {PersonalInformationComponent} from '../personal-information/personal-information.component';
import {AddressComponent} from '../address/address.component';
import {ContactInformationComponent} from '../contact-information/contact-information.component';
import {TaxInformationComponent} from '../tax-information/tax-information.component';
import {DecisionComponentComponent} from '../decision-component/decision-component.component';
// import { SharedService } from 'src/app/services/shared.service';
import {ValidatorsService} from 'src/app/services/validators.service';
import {McClientConst} from 'src/app/consts/mc-client.const';
import {McClient} from 'src/app/models/McClient.model';
import {McAddress} from 'src/app/models/McAddress.model';
import {McAddressConst} from 'src/app/consts/mc-address.conts';
import {McEmailConst} from 'src/app/consts/mc-email.const';
import {McPhoneConst} from 'src/app/consts/mc-phone.const';
import {McPhone} from 'src/app/models/McPhone.model';
import {McEmail} from 'src/app/models/McEmai.model';
import {McCardConst} from 'src/app/consts/mc-card.const';
// import {McCard} from 'src/app/models/McCard.model';
import {MessageComponent} from '../message/message.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppUtils} from 'src/app/app-utils';
import {LinkCardService} from 'src/app/link.card.service';
import {DashboardService, MatToolbarInterface} from 'src/app/dashboard.service';
// import {Title} from '@angular/platform-browser';
import {AppTitleService} from 'src/app/app-title.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {MatDialog} from '@angular/material/dialog';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {mobile} from '../../../../consts/utilities';
import {HttpStatusCode} from '@angular/common/http';

@Component({
    selector: 'app-registro-tarjeta',
    templateUrl: './registro-tarjeta.component.html',
    styleUrls: ['./registro-tarjeta.component.scss'],
    animations: [
        trigger('fadeSlideInOut', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateY(30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateY(0)'})),
            ]),
            transition(':leave', [
                animate('300ms', style({opacity: 0, transform: 'translateY(30px)'})),
            ]),
        ])
    ]
})
export class RegistroTarjetaComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('stepper', {}) stepper: MatStepper;
    @ViewChild(DecisionComponentComponent, {})
    decisionComponent: DecisionComponentComponent;
    @ViewChild(CardLinkComponent, {}) cardComponent: CardLinkComponent;
    @ViewChild(RFCComponent, {}) rfcComponent: RFCComponent;
    @ViewChild(PersonalInformationComponent, {})
    personalInfo: PersonalInformationComponent;
    @ViewChild(AddressComponent, {}) addressComponent: AddressComponent;
    @ViewChild(ContactInformationComponent, {})
    contactInfo: ContactInformationComponent;
    @ViewChild(TaxInformationComponent, {}) taxInfo: TaxInformationComponent;
    @ViewChild(MessageComponent, {}) messageComponent: MessageComponent;
    @ViewChild('linkCardContainer', {}) viewElement: ElementRef<any>;
    @ViewChild('errMessageTemplate', {}) errMessage: TemplateRef<any>;

    viewEl: ElementRef<any> = null;

    // matMenuState: MatToolbarInterface;
    // matElevationValue: string;

    private subscriptions: Subscription[] = [];
    cardForm: FormGroup;
    phoneForm: FormGroup;
    rfcForm: FormGroup;
    personalInfoForm: FormGroup;
    addressForm: FormGroup;
    contactInfoForm: FormGroup;
    taxInfoForm: FormGroup;
    account: string;
    name: string;
    lastName: string;
    motherLastName: string;
    birthday: Date;
    phone: string;
    email: string;
    curp: string;
    rfc: string;
    lettersAndNumbers = '^[a-zA-Z0-9 ]+$';
    numbers = '^[0-9]+$';
    byCard = true;
    showRfcForm = true;
    personalDataConfig: any = {};
    accountData: any = {};
    showAddressForm = true;
    addressDataConfig: any = {};
    showContactForm = true;
    contactDataConfig: any = {};
    showTaxForm = true;
    taxDataConfig: any = {};
    cardType: number;
    // customerId: string;
    savePhone: boolean;
    saveEmail: boolean;
    isClubCard = false;
    voucherCard = true;
    showAccountMessage = false;
    hidePersonalDataForm = false;
    showPersonalFormBtn = false;
    user: any;

    accountType = '';

    sectionTitle = '';

    isMobile: boolean;

    private sections: any = {
        cardStep: 'Ingresa el número de tarjeta',
        rfcStep: 'Ingresa tu RFC',
        messageStep: 'Información de cuenta asociada',
        curpStep: 'Ingresa tu CURP',
        personalDataStep: 'Ingresa tus datos personales',
        addressDataStep: 'Ingresa tu domicilio',
        taxDataStep: 'Ingresa tu RFC',
        contactDataStep: 'Ingresa datos de contacto'
    };

    message = '';

    public formReady = false;
    public userNoHaveCards = false;
    @Input() userNotAnswered = true;

    @Input() disableEndRedirection = false;
    @Input() enableSkipButton = false;
    @Output() userHasEndedCardStep = new EventEmitter<any>();

    @ViewChild('addedDigitalCard', { static: true })
    addedCardRef: TemplateRef<any>;

    @ViewChild('failedToGenCard', { static: true })
    failedGenCardRef: TemplateRef<any>;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private fb: FormBuilder,
        private linkCardService: LinkCardService,
        private dashboardService: DashboardService,
        private validatorsService: ValidatorsService,
        private titleService: AppTitleService,
        private snackBar: MatSnackBar,
        private dialog: MatDialog,
        private bottomSheet: MatBottomSheet,
    ) {
        this.createCardForm();
        this.createPhoneForm();
        this.createRfcForm();
        this.createPersonalInfoForm();
        this.createAddressForm();
        this.createContactInfoForm();
        this.createTaxInfoForm();

        // const user = AppUtils.getUserFromToken();
        // const { id } = user;

        // this.customerId = id;
        this.isMobile = mobile();

        const cardsSw = sessionStorage.getItem('no-cards') || '1';

        this.userNoHaveCards = cardsSw === '1';
    }

    ngOnInit() {
        this.titleService.setTitle('Vincular tarjeta');

        setTimeout(() => {
            if (Object.keys(this.activeRoute.snapshot.queryParams).length > 0) {
                this.clubCardValidation();
            }
        }, 1000);

        this.activeRoute.params.subscribe((params: { accountType: string }) => {
            if (!params.accountType) {
                this.accountType = 'owner';
                return;
            }

            if (params.accountType === 'owner') {
                this.accountType = 'owner';
            } else if (params.accountType === 'beneficiary') {
                this.accountType = 'beneficiary';
            }
        });

        this.sectionTitle = this.sections['cardStep'];
    }

    ngAfterViewInit(): void {
        this.observeStepperChanges();

        setTimeout(() => {
            this.formReady = true;
            this.viewEl = this.viewElement;
        }, 0);
    }

    ngOnDestroy() {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    get firstStep(): boolean {
        return this.stepper.selectedIndex === 0;
    }

    get isLastStep(): boolean {
        return this.stepper.selectedIndex === this.stepper.steps.length - 1;
    }

    get cardNumber(): string {
        const vinculationNumber = this.getVinculationNumber();

        if (this.voucherCard) {
            return this.formatedCard;
        } else {
            return (this.byCard ? vinculationNumber : this.account);
        }
    }

    get formatedCard(): string {
        const cardNumber = this.cardForm.get('cardNumber').value;
        return [cardNumber.slice(0, -4), '-', cardNumber.slice(-4)].join('');
    }

    get formatedPhone(): string {
        const country = this.phoneForm.get('lada').value;
        const phone = this.phoneForm.get('telefono').value;
        return (country.area + phone);
    }

    // back(route: string): void {
    //     this.router.navigateByUrl(route);
    // }

    public onPrimaryAction(): void {
        this.userNotAnswered = false;
    }

    public onSecondaryAction(): void {
        // this.userNoAnswer = true;
        this.onSkipCard();
    }

    onUserMovedOnStepper(event: string): void {
        this.sectionTitle = event;
    }

    observeStepperChanges(): void {
        this.stepper.selectionChange.subscribe((value: any) => {
            const stepId = value.selectedStep.label;

            this.setTitleBasedOnStep(stepId);
        });
    }

    // getUser(): void {
    //   this.linkCardService.getUser().subscribe(
    //     user => {
    //       this.user = user;
    //       console.log(user);
    //     },
    //     error => {
    //       console.log(error);
    //     }
    //   );
    // }

    getVinculationNumber(): string {
        let vinculationNumber: string;

        if (this.byCard) {
            vinculationNumber = this.cardForm.get('cardNumber').value;
            const stage1 = vinculationNumber.substring(0, 4);
            const stage2 = vinculationNumber.substring(0, 3);

            if ((stage1 === '2390' || stage2 === '220') && vinculationNumber.length === 13) {
                this.voucherCard = false;
            } else if (vinculationNumber.length < 13) {
                this.voucherCard = true;
            }
        } else {
            const form = this.phoneForm;
            vinculationNumber = form.get('lada').value + form.get('telefono').value;
        }

        return vinculationNumber;
    }

    createCardForm(): void {
        this.cardForm = this.fb.group({
            cardNumber: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]],
        });
    }

    createPhoneForm(): void {
        this.phoneForm = this.fb.group({
            telefono: ['', [Validators.required]],
            lada: ['52', [Validators.required]],
        });
    }

    createRfcForm(): void {
        this.rfcForm = this.fb.group(
            {
                rfc: [
                    '',
                    [
                        Validators.required,
                        Validators.minLength(13),
                        Validators.maxLength(13),
                        Validators.pattern(this.lettersAndNumbers),
                    ],
                ],
                card: ['', Validators.required],
            },
            {
                asyncValidators: this.validatorsService.rfcValidation,
            }
        );
    }

    createPersonalInfoForm(): void {
        this.personalInfoForm = this.fb.group({
            name: ['', [Validators.required]],
            lastName: ['', [Validators.required]],
            motherLastName: [''],
            birthday: [''],
        });
    }

    createAddressForm(): void {
        this.addressForm = this.fb.group({
            street: ['', [Validators.required]],
            extNumber: ['', [Validators.required]],
            intNumber: [''],
            suburb: [{value: '', disabled: true}, [Validators.required]],
            zip: ['', [Validators.required]],
            city: [{value: '', disabled: true}, [Validators.required]],
            state: [{value: '', disabled: true}, [Validators.required]],
            country: [{value: '', disabled: true}, [Validators.required]],
        });
    }

    createContactInfoForm(): void {
        this.contactInfoForm = this.fb.group({
            code: ['52', [Validators.required]],
            phone: ['', [Validators.required]],
            email: ['', [Validators.required]],
        });
    }

    createTaxInfoForm(): void {
        this.taxInfoForm = this.fb.group({
            curp: [''],
            rfc: [''],
        });
    }

    // getRegisterType(): void {
    //     this.decisionComponent.byCard.subscribe((value) => {
    //         this.byCard = value;
    //
    //         if (!this.byCard) {
    //             this.voucherCard = false;
    //         }
    //
    //         this.stepper.next();
    //     });
    // }

    previousStep(): void {
        this.personalInfoForm.markAsPristine();
        this.addressForm.markAsPristine();
        this.contactInfoForm.markAsPristine();
        this.taxInfoForm.markAsPristine();

        if (this.firstStep) {
            this.router.navigate(['/app/dashboard/cards']);
        } else if (this.isLastStep) {
            this.stepper.selectedIndex = 0;
        } else {
            this.stepper.previous();
        }
    }

    nextStep(currentStep: string): void {
        this[currentStep]();
    }

    setTitleBasedOnStep(currentStep: string): void {
        this.sectionTitle = this.sections[currentStep];
    }

    cardStep(): void {
        this.isValidCardForm();
        this.isValidPhoneForm();

        if (this.cardForm.valid || this.phoneForm.valid) {
            this.getFormConfig();
        }
    }

    rfcStep(): void {
        this.isValidRfcForm();

        if (this.rfcForm.valid) {
            this.stepper.next();
        }
    }

    messageStep(): void {
        if (this.isLastStep) {
            this.saveCard();
        } else {
            this.stepper.next();
        }
    }

    personalDataStep(): void {
        this.isValidPersonalInfoForm();

        if (this.personalInfoForm.valid) {
            this.savePersonalData();
        }

        this.personalInfoForm.markAllAsTouched();
    }

    addressDataStep(): void {
        this.isValidAddressForm();

        if (this.addressForm.valid) {
            this.saveAddressData();
        }
    }

    contactDataStep(): void {
        this.isValidContactForm();
        console.log(this.contactInfoForm);

        if (this.contactInfoForm.valid && this.savePhone) {
            this.saveContactPhoneData();
        }

        if (this.contactInfoForm.valid && this.saveEmail) {
            this.saveContactEmailData();
        }
    }

    taxDataStep(): void {
        this.isValidTaxForm();

        if (this.taxInfoForm.valid) {
            this.saveTaxData();
        }
    }

    isValidCardForm() {
        if (this.cardForm.invalid) {
            return Object.values(this.cardForm.controls).forEach((control) => {
                control.markAllAsTouched();
            });
        }
    }

    isValidPhoneForm() {
        if (this.phoneForm.invalid) {
            return Object.values(this.phoneForm.controls).forEach((control) => {
                control.markAllAsTouched();
            });
        }
    }

    isValidRfcForm() {
        if (this.rfcForm.invalid) {
            return Object.values(this.rfcForm.controls).forEach((control) => {
                control.markAllAsTouched();
            });
        }
    }

    isValidPersonalInfoForm() {
        if (this.personalInfoForm.invalid) {
            return Object.values(this.personalInfoForm.controls).forEach(
                (control) => {
                    control.markAllAsTouched();
                }
            );
        }
    }

    isValidAddressForm() {
        if (this.addressForm.invalid) {
            return Object.values(this.addressForm.controls).forEach((control) => {
                control.markAllAsTouched();
            });
        }
    }

    isValidContactForm() {
        if (this.contactInfoForm.invalid) {
            return Object.values(this.contactInfoForm.controls).forEach((control) => {
                control.markAllAsTouched();
            });
        }
    }

    isValidTaxForm() {
        if (this.taxInfoForm.invalid) {
            return Object.values(this.taxInfoForm.controls).forEach((control) => {
                control.markAllAsTouched();
            });
        }
    }

    // Función para obtener la configuración de los formularios
    getFormConfig(): void {
        const vinculationNumber = this.byCard ? this.cardNumber : this.formatedPhone;
        const channel = this.byCard ? 'card' : 'phone';

        this.linkCardService.getFormConfig(vinculationNumber, channel).subscribe(
            (result) => {

                if (result.statusCode !== 200) {
                    // Replace deprecated Snackbar by Modal
                    this.openErrMessage(result.message);
                } else {
                    const {cardType, data, account} = result.data;

                    // console.log(result);

                    this.account = account;
                    this.cardType = cardType;
                    this.rfcFormConfig(cardType);
                    this.personalFormConfig(data[0]);
                    this.accountDataMessage(data[0]);
                    this.addressFormConfig(data[0]);
                    this.contactFormConfig(data[0]);
                    this.taxFormConfig(data[0]);

                    const totalSteps = this.stepper._steps.length;

                    if (totalSteps < 3) {
                        this.saveCard();
                    } else if (!this.isClubCard) {
                        this.stepper.next();
                    }
                }
            },
            (error) => {
                this.openErrMessage(error.error.mensaje);
            }
        );
    }

    // Función para mostrar o esconder componente de rfc
    rfcFormConfig(cardType: number): void {
        if (cardType > 1) {
            this.showRfcForm = false;
            this.rfcForm.get('rfc').disable();
            this.rfcForm.get('card').disable();
        } else {
            this.rfcForm.get('card').setValue(this.formatedCard);
        }
    }

    // Función para mandar configuracion al componente hijo
    // de datos personales
    personalFormConfig({personalInfo}): void {
        this.personalDataConfig = personalInfo;
    }

    accountDataMessage({personalInfo}): void {
        const {account, name, lastName} = personalInfo;

        this.accountData = account;

        const accountName = account.name + account.lastName;
        const masterName = name.value + lastName.value;

        // Escenario 1
        // Cliente tiene datos en mc_cliente, pero no en origen
        if (masterName.length > 0 && !accountName.length) {
            this.showAccountMessage = false;
            this.hidePersonalDataForm = true;
        }

        // Escenario 2
        // Cliente tiene datos en mc_cliente y en origen
        if (masterName.length > 0 && accountName.length > 0) {
            this.showAccountMessage = true;
            this.hidePersonalDataForm = true;
            this.showPersonalFormBtn = true;
        }

        // Escenario 3
        // Ciente no tiene datos en el mc_cliente y tampoco en origen
        if (!masterName.length && !accountName.length) {
            this.showAccountMessage = false;
            this.hidePersonalDataForm = false;
        }

        // Escenario 4
        // Cliente no tiene datos en mc_cliente y tiene datos en origen
        if (!masterName.length && accountName.length > 0) {
            this.showAccountMessage = false;
            this.hidePersonalDataForm = false;
            this.loadData(account);
        }

    }

    loadData(account) {
        this.personalInfoForm.setValue({
            name: account.name,
            lastName: account.lastName,
            motherLastName: account.motherLastName,
            birthday: null
        });
    }

    goToPersonalFormStep(showForm: boolean): void {
        if (showForm) {
            this.hidePersonalDataForm = false;

            setTimeout(() => {
                this.stepper.next();
            }, 1000);
        }
    }

    addressFormConfig({addressInfo}): void {
        if (addressInfo.hide === 1) {
            this.showAddressForm = false;
            return;
        }

        if (addressInfo.extNumber.hide && !addressInfo.intNumber.hide) {
            addressInfo.addressTotalToHide += 1;
        }

        if (addressInfo.addressTotalToHide === 8) {
            this.showAddressForm = false;
        } else {
            this.addressDataConfig = addressInfo;
        }
    }

    contactFormConfig({contactInfo, ...data}): void {
        if (contactInfo.hide === 1) {
            this.showContactForm = false;
            return;
        }

        this.savePhone = contactInfo.phone.hide === 0;
        this.saveEmail = contactInfo.email.hide === 0;

        contactInfo.phone.value = data.personalInfo.account.phone || '';

        if (contactInfo.contactTotalToHide === 2) {
            this.showContactForm = false;
        } else {
            this.contactDataConfig = contactInfo;
        }
    }

    taxFormConfig({taxInfo}): void {
        if (taxInfo.hide === 1) {
            this.showTaxForm = false;
            return;
        }

        // const hideFields = taxInfo.curp.required + taxInfo.rfc.required;
        if (taxInfo.taxTotalToHide === 2) {
            this.showTaxForm = false;
        } else {
            this.taxDataConfig = taxInfo;
        }
    }

    savePersonalData(): void {
        const customerData = this.formatPersonalData();

        this.linkCardService.savePersonalData(customerData).subscribe(
            (result) => {
                console.log(result);

                const {codigo: code, mensaje: message} = result.data[0];

                if (code > 0) {
                    this.openErrMessage(message);
                    return;
                }

                if (this.isLastStep) {
                    this.saveCard();
                } else {
                    this.stepper.next();
                }
            },
            (error) => {
                this.openErrMessage(error.message);
            }
        );
    }

    formatPersonalData(): McClient {
        this.setPersonalData();

        McClientConst.pid_cliente = 0;
        McClientConst.pnombre = this.name;
        McClientConst.pap_paterno = this.lastName;
        McClientConst.pap_materno = this.motherLastName;
        McClientConst.pf_nacimiento = this.birthday;
        McClientConst.pid_tcard = this.cardType;

        return McClientConst;
    }

    setPersonalData(): void {
        const form = this.personalInfoForm;

        this.name = form.get('name').value;
        this.lastName = form.get('lastName').value;
        this.motherLastName = form.get('motherLastName').value;
        this.birthday = form.get('birthday').value;
    }

    saveTaxData(): void {
        const taxData = this.taxInfoForm.value;

        this.linkCardService.saveTaxData(taxData).subscribe(
            (_) => {
                if (this.isLastStep) {
                    this.saveCard();
                } else {
                    this.stepper.next();
                }
            },
            (error) => {
                console.log(error);
                this.openErrMessage(error.error.mensaje);
            }
        );
    }

    saveAddressData(): void {
        const addressData = this.formatAddressData();

        this.linkCardService.saveAddressData(addressData).subscribe(
            (_) => {
                this.addressComponent.info = false;

                if (this.isLastStep) {
                    this.saveCard();
                } else {
                    this.stepper.next();
                }
            },
            (error) => {
                this.openErrMessage(error.error.mensaje);
            }
        );
    }

    formatAddressData(): McAddress {
        this.addressForm.get('suburb').enable();
        this.addressForm.get('city').enable();
        this.addressForm.get('state').enable();
        this.addressForm.get('country').enable();

        const {
            street = '',
            extNumber = '',
            intNumber = '',
            suburb = '',
            zip = '',
            city = '',
            state = '',
            country = '',
        } = this.addressForm.value;

        McAddressConst.cliente_id = 0;
        McAddressConst.calle = street;
        McAddressConst.numero_ext = extNumber;
        McAddressConst.numero_int = intNumber;
        McAddressConst.codigo_postal = zip;
        McAddressConst.colonia = suburb;
        McAddressConst.ciudad_id = city;
        McAddressConst.estado_id = state;
        McAddressConst.pais_id = country;

        return McAddressConst;
    }

    saveContactPhoneData(): void {
        const phoneData = this.formatContacPhoneData();

        this.linkCardService.savePhoneData(phoneData).subscribe(
            (_) => {
                if (this.isLastStep) {
                    this.saveCard();
                } else {
                    this.stepper.next();
                }
            },
            (error) => {
                this.openErrMessage(error.error.mensaje);
            }
        );
    }

    formatContacPhoneData(): McPhone {
        const countryCode = this.contactInfoForm.get('code').value;
        const phone = this.contactInfoForm.get('phone').value;

        this.phone = `${countryCode}${phone}`;

        // const code = this.phone.substring(0, 2);
        // const phone = this.phone.substring(2, 12);

        McPhoneConst.pid_cliente = 0;
        McPhoneConst.ppais = countryCode;
        McPhoneConst.ptelefono = phone;

        return McPhoneConst;
    }

    saveContactEmailData(): void {
        const emailData = this.formatEmailData();

        this.linkCardService.saveEmailData(emailData).subscribe(
            (_) => {
                if (this.isLastStep) {
                    this.saveCard();
                } else {
                    this.stepper.next();
                }
            },
            (error) => {
                this.openErrMessage(error.error.mensaje);
            }
        );
    }

    formatEmailData(): McEmail {
        this.email = this.contactInfoForm.get('email').value;

        McEmailConst.pid_cliente = 0;
        McEmailConst.pemail = this.email;

        return McEmailConst;
    }

    saveCard(): void {
        const cardData = this.formatCardData();
        // this.accountValidation();

        this.linkCardService.saveCard(cardData).subscribe(
            (result: any) => {
                const {statusCode, mensaje} = result;

                if (statusCode !== 200) {
                    this.openErrMessage(mensaje);
                    return;
                }

                // Open modal or show ok indicators

                this.userHasEndedCardStep.emit();

                if (this.disableEndRedirection) {
                    return;
                }

                setTimeout(() => {
                    this.router.navigate(['/app/dashboard/cards']);
                }, 1000);
            },
            (error) => {
                console.log(error);
            }
        );
    }

    private onSkipCard(): void {
        this.linkCardService.generateDigitalCard().subscribe(
            (response) => {
                // On generated card success
                const { statusCode } = response;

                if (statusCode !== HttpStatusCode.Ok) {
                    return;
                }

                if (mobile()) {
                    this.bottomSheet.open(this.addedCardRef);
                }

                if (!mobile()) {
                    this.dialog.open(this.addedCardRef);
                }
            },
            (error) => {
                // On generated card error
                if (mobile()) {
                    this.bottomSheet.open(this.failedGenCardRef);
                }

                if (!mobile()) {
                    this.dialog.open(this.failedGenCardRef);
                }
            }
        );
    }

    formatCardData(): any {
        // const sessionId = AppUtils.getUserFromToken().session;

        this.setPersonalData();

        McCardConst.id_usu = 0;
        McCardConst.id_sesion = 0;
        McCardConst.cardno = this.cardNumber;
        McCardConst.nombre = this.name;
        McCardConst.paterno = this.lastName;
        McCardConst.materno = this.motherLastName;
        McCardConst.fecha_nac = this.birthday;
        McCardConst.telefono = this.phone;
        McCardConst.curp = this.curp;
        McCardConst.rfc = this.rfc;
        McCardConst.email = this.email;

        return McCardConst;
    }

    skipCardForSession(): void {
        sessionStorage.setItem('user-canceled-card-reg', '1');
        this.userHasEndedCardStep.emit();
    }

    accountValidation(): void {
        this.linkCardService.accountValidation(this.account)
            .subscribe(
                result => {
                    if (result.exists > 0) {
                        this.router.navigate(['/dashboard/cards']);
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

    clubCardValidation(): any {
        this.activeRoute.queryParams
            .subscribe(
                params => {
                    this.linkCardService.clubCardValidation(params.phone)
                        .subscribe(
                            result => {
                                if (result.account !== '') {
                                    this.isClubCard = true;
                                    this.voucherCard = false;
                                    this.cardForm.get('cardNumber').setValue(result.account);
                                    this.getFormConfig();
                                } else {
                                    this.phoneForm.get('lada').setValue(params.code);
                                    this.phoneForm.get('telefono').setValue(params.phone);
                                }
                            },
                            error => {
                                console.log(error);
                            }
                        );
                });
    }

    openErrMessage(message: string): void {
        const isMobile = mobile();

        this.message = message;

        if (isMobile) {
            this.bottomSheet.open(this.errMessage);
        }

        if (!isMobile) {
            this.dialog.open(this.errMessage, {
                position: { top: '60px', right: '25px' },
                hasBackdrop: true,
                closeOnNavigation: true
            });
        }
    }

    dismiss(): void {
        const isMobile = mobile();

        if (isMobile) {
            this.bottomSheet.dismiss();
        }

        if (!isMobile) {
            this.dialog.closeAll();
        }
    }
}
