import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {forkJoin, Observable, of} from 'rxjs';
import {McAddressService} from '../../mc-address.service';
import {ProfileItemModelService} from '../../profile-item-model.service';
import {catchError} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProfileAddressesResolverService implements Resolve<any> {
    constructor(
        private mcAddressesService: McAddressService,
        private profileModelService: ProfileItemModelService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin({
            addressesResponse: this.mcAddressesService.getAddresses()
                .pipe(catchError(err => of(err))),
            addressesConfigResponse: this.profileModelService.getProfileModel('direccion')
                .pipe(catchError(err => of(err))),
        });
    }
}
