import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class McAddressService {

    constructor(
        private http: HttpClient
    ) {}

    get baseUrl(): string {
        return environment.serverURL;
    }

    getAddresses(): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/mc-address/get-addresses`);
    }

    postAddress(payload: any, type = 'manual'): Observable<any> {
        return this.http.post(`${this.baseUrl}/mc-address/add-address?type=${type}`, payload);
    }

    putAddress(payload: any): Observable<any> {
        return this.http.put(`${this.baseUrl}/mc-address/update-address`, payload);
    }

    deleteAddress(payload: any): Observable<any> {
        return this.http.post(`${this.baseUrl}/mc-address/delete-address`, payload);
    }
}
