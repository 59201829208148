import {Component, OnInit} from '@angular/core';
import {AppTitleService} from '../../app-title.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-privacy-policy',
    templateUrl: './privacy-policy.component.html',
    styleUrls: ['./privacy-policy.component.scss'],
    animations: [
        trigger('fadeSlideInOut', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateY(80px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateY(0)'})),
            ]),
        ])
    ]
})
export class PrivacyPolicyComponent implements OnInit {

    constructor(
        private title: AppTitleService,
    ) {
    }

    ngOnInit(): void {
        this.title.setTitle('Politica de privacidad');
    }

}
