import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-telefonoregistro',
  templateUrl: './1.2telefonoregistro.component.html'
})
export class TelefonoregistroComponent implements OnInit {

  subscriptions = [];

  errorTelefono = false;
  mensaje = '';
  ladaPais = '52';
  phoneErrorMessage: string;
  activeUser = false;

  countries: any[] = [];
  selectedCountry: any = { area: 52, description: 'MX' };

  @Output() enterEvent = new EventEmitter<any>();
  @Input() viewElementRef: ElementRef<any>;

  constructor(
    public controlContainer: ControlContainer,
  ) {
    this.countries = [
      { area: 52,  description: 'MX' },
      { area: 1, description: 'USA' }
    ];
  }

  ngOnInit() {
    // this.subscriptions.push(
      // this.sharedService.getErrorTelefono().subscribe(data => {
      //   this.errorTelefono = data;
      // }),
      // this.sharedService.getMensajeTelefonoError().subscribe(data => {
      //   this.mensaje = data;
      // })
    // );
  }

  get invalidPhone(): boolean {
    const phone = this.controlContainer.control.get('telefono');

    if (phone.errors !== null && phone.errors.userAsData) {
      this.phoneErrorMessage = 'Este teléfono ya se encuentra regístrado';
      return true;
    } else if (phone.invalid && phone.touched) {
      this.phoneErrorMessage = 'El teléfono es invalido';
      return true;
    }
  }

  get invalidCode(): boolean {
    return this.controlContainer.control.get('lada').invalid && this.controlContainer.control.get('lada').touched;
  }

  get telefono() {
    return this.controlContainer.control.get('telefono').value;
  }

  onSelectedCountry(event: any): any {
    this.selectedCountry = event.value;
  }

  seleccionarPais(value) {
    this.ladaPais = value.toString();
    this.controlContainer.control.get('telefonoVerificacion').setValue(this.ladaPais.toString());
  }

  validaTelefono(value) {
    const long = this.ladaPais.toString().length;
    const prefix = this.ladaPais;
    if (value.substring(0, long) !== prefix) {
      this.controlContainer.control.get('telefonoVerificacion').setValue(this.ladaPais.toString());
    }
  }

  onEnterEvent(): void {
    this.enterEvent.emit();
  }
}
