<div>
  <div class="terms-conditions-pane">
      <div class="terms-conditions-content">
        <h3 class="custom-title">
          <strong>Terminos y condiciones</strong>
          de tarjetas digitales de <strong>Calimax</strong>
        </h3>
        <span>
          <br>
          A los Usuarios les informamos de los siguientes Términos y Condiciones de Uso y Privacidad, que les son aplicables por la Aceptación en la Instalación de la <strong>App Calimax</strong>  en su teléfono celular, por lo que entenderemos que los acepta y acuerda en obligarse en el cumplimiento y buen uso de las Tarjetas emitidas por CALIMAX.
          <br>
          <br>
          Estimado Usuario, <strong>CENTRAL DETALLISTA, S.A. DE C.V.</strong> (en lo sucesivo <strong>“CALIMAX”</strong>), con domicilio en <strong>Ignacio Comonfort #9351, Col. Zona Rio, C.P. 05120, Tijuana, Baja California</strong>, pone a su disposición los Términos y Condiciones, mismos que rigen el uso y/o Instalación de la App Calimax en el teléfono celular del Usuario, con el consentimiento de este, permaneciendo vigentes mientras continúe utilizando nuestra Aplicación o mientras sean válidas, y hasta que se actualice una causa de terminación, de acuerdo con lo dispuesto por el Término 14 <strong>"Terminación"</strong>.<br><br>
          Tenga en cuenta que serán aplicables de igual forma las condiciones establecidas en el Contrato principal celebrado entre Calimax y el Cliente.<br>
          Por lo anterior, lo invitamos a leer detenidamente los presentes Términos y Condiciones, y en caso de duda, contactarnos a través de los medios proporcionados en el Término 22 "Contacto”. Estos Términos y Condiciones, contienen las condiciones que rigen el uso de la App Calimax en su teléfono celular, y constituyen un acuerdo o contrato vinculante entre CALIMAX y usted, por lo que entenderemos que los acepta, y recuerda obligarse en su cumplimiento.<br>
          <br>
          <br>
          <strong>1.	DEFINICIONES.</strong>
          <br>
          <br>
          <strong>“APP CALIMAX”</strong> aplicación móvil operada por <strong>CALIMAX</strong> vinculada directamente con:
          <br>
          •	Tarjeta de Despensa Electrónica <strong>"CALIMAX"</strong>;
          <br>
          •	Tarjeta de Monedero Lealtad <strong>“CALIMAX”</strong>;
          <br>
          •	Tarjeta de Crédito de mercancías <strong>“CALIMAX”</strong>.
          <br><br>
          <strong>“CONTRATO PRINCIPAL”</strong>: contrato de prestación de servicios celebrado entre <strong>CALIMAX</strong> y el Cliente con el fin de proporcionar el servicio de APP de consulta de tarjetas Electrónicas "CALIMAX".
          <br>
          <strong>“CLIENTE”</strong>: persona física o moral que celebra un contrato de prestación de servicios de de APP de consulta de tarjetas Electrónicas con <strong>CALIMAX</strong>, con el fin de proporcionar a sus empleados este beneficio.
          <br>
          <strong>“ARTÍCULOS DE CONSUMO AUTORIZADOS CON TARJETA DE DESPENSA”</strong>: Todos aquellos Artículos, excepto, Cerveza, Vinos y Licores y Cigarros, en el uso de la Tarjeta de Despensa Electrónica <strong>“CALIMAX”</strong>.
          <strong>“SUCURSALES”</strong>: Cadena de supermercados más grande de la región en donde se acepta como forma de pago la App, en virtud de la firma del Contrato Principal entre las partes. Las cuales son: Tiendas Calimax, Tiendas Aprecio y Bodegón.
          <br>
          <strong>“EVENTO”</strong>: robo, extravío o uso no autorizado de la App.
          <br>
          <strong>“SALDO”</strong>: monto depositado periódicamente al Usuario, según las instrucciones del Cliente, conforme al Contrato Principal.
          <br>
          <strong>“SALDO ACTUAL”</strong>: saldo disponible en la App, para la adquisición de Artículos de Consumo Autorizados, en las Sucursales.
          <br>
          <strong>“SITIO WEB / PÁGINA WEB”</strong>: el sitio web de CALIMAX, http://www.calimax.com.mx
          <br>
          <strong>“CALIMAX”</strong>: <strong>CENTRAL DETALLISTA, S.A. DE C.V.</strong>
          <br>
          <strong>“TARJETA DE DESPENSA ELECTRÓNICA CALIMAX”</strong>: Monedero Electrónico de Vales de Despensa "CALIMAX" emitido por CALIMAX y proporcionado al empleado, en virtud de un contrato celebrado con el Cliente, con el fin de que el empleado pueda adquirir distintos Artículos de Consumo Autorizados, en las Sucursales.
          <br>
          <strong>“TARJETA DE MONEDERO LEALTAD CALIMAX”</strong>: Monedero Electrónico para empleados, en donde participan todos los empleados del Cliente, obteniendo un porcentaje de bonificación sobre la Compra Neta realizada en las Sucursales, el cual tiene una vigencia de 6 meses donde los cortes de saldos se realizarán en los meses de junio y diciembre.
          <br>
          <strong>“TARJETA DE CRÉDITO DE MERCANCÍAS CALIMAX”</strong>: Tarjeta válida para la compra de mercancías en Tiendas Calimax, Tiendas Aprecio y Bodegón. Esta tarjeta es un beneficio que solo puede ser utilizada por empleados del Cliente, acompañada de su gafete de empleado, en el que se validara que la foto sea la misma en ambas y la firma coincida con una identificación oficial.
          <br>
          <strong>“TRANSACCIÓN(ES)”</strong>: la adquisición de Artículos de Consumo Autorizados por el Usuario en las Sucursales, mediante el uso de la <strong>App Calimax</strong>.
          <br>
          <strong>“USUARIO”</strong>: el usuario final o beneficiario de la <strong>App Calimax</strong>.
          <br>
          <strong>“VALIDEZ”</strong>: periodo de vigencia del uso de la <strong>App Calimax</strong>.
          <br>
          <br>
          <strong>2.	OBJETO.</strong>
          <br>
          <br>
          Que el usuario de la <strong>App Calimax</strong> cuente con un Saldo, y tenga acceso a través de ella a las dispersiones de fondos para la adquisición de Artículos de Consumo Autorizados en las Sucursales.
          <br>
          <br>
          <strong>3.	AUTORIZACIÓN, ACTIVACIÓN Y USO DE LA APP CALIMAX.</strong>
          <br>
          <br>
          El uso que haga de la <strong>App Calimax</strong>, estará sujeto a las siguientes condiciones:
          Autorización del Usuario:<br>
          •	El Usuario bajo su derecho autoriza a CALIMAX, y sin responsabilidad alguna para esta, el uso de su teléfono celular para descargar la App Calimax, con la finalidad de hacerse Acreedor de todos los Beneficios, los cuales a continuación se mencionan:
          o	Consulta del saldo, en cualquier momento, de la Tarjeta de Despensa Electrónica “CALIMAX”;
          o	Consulta del saldo, en cualquier momento, de la Tarjeta Monedero Lealtad “CALIMAX”;
          o	Consulta del saldo, en cualquier momento, de la Tarjeta de Crédito de Mercancías “CALIMAX”;
          o	Obtener el descuento empleado en Sucursales por cada compra realizada en las Sucursales;
          o	Generación de Monedero Electrónico Lealtad en cada compra realizada en las Sucursales;
          o	Consultar, en cualquier momento, los Tickets de compra realizadas en las Sucursales.
          o	Entre otros.
          <br>
          <br>
          Activación:
          <br>
          <br>
          •	Registra tus datos personales de la <strong>App Calimax</strong> en tu teléfono celular y sigue las indicaciones para Activarla.<br>
          •	Solo presenta tu teléfono celular con la App Calimax instalada previamente, en cajas de las Sucursales y automáticamente tendrás los beneficios de club Calimax.<br>
          Uso:
          <br>
          <br>
          •	La App Calimax, es de uso personal e intransferible, quedando prohibido compartir el Usuario y Contraseñas. En caso, de detectar este incumplimiento <strong>CALIMAX</strong> aplicara lo previsto en el Término 14 "Terminación".
          <br>
          •	Su Saldo y la periodicidad de los depósitos estarán sujetos a las instrucciones que recibamos del Cliente en virtud del Contrato Principal.
          <br>
          •	Podrás consultar su Saldo Actual, siempre que lo deseé.
          <br>
          •	En caso de robo, extravío o uso no autorizado de tu teléfono celular con la <strong>App Calimax</strong> instalada previamente, deberá comunicarnos dicha situación, de forma inmediata, de conformidad con lo referido en el Término 5 “Robo, extravío o uso no autorizado”.
          <br>
          •	Queda prohibido utilizar o permitir el uso de la <strong>App Calimax</strong> para fines fraudulentos, con el fin de causar un perjuicio a un tercero o de cualquier otra forma contraria a la legislación aplicable, al orden público, la moral y las buenas costumbres y/o a los presentes Términos y Condiciones de Uso.
          <br>
          <br>
          <strong>4.	CLAVES DE ACCESO.</strong>
          <br>
          <br>
          En todo momento, el Usuario es el responsable único y final de mantener en secreto sus claves de acceso.
          <br>
          <br>
          <strong>5.	ROBO, EXTRAVIO O USO NO AUTORIZADO.</strong>
          <br>
          <br>
          En caso de Robo y/o extravío o de que ocurra un Evento, en relación cualquiera de tu teléfono celular con la App Calimax instalada previamente, deberá comunicarse con CALIMAX tan pronto tenga conocimiento del mismo.
          Puede comunicarnos el Evento directamente en el Centro de Atención a Clientes (Call Center) a través del número Telefónico (01 800) 028-2537, en el horario de lunes a Domingo de 08:00am a 09:00pm incluyendo días festivos.
          Calimax no será responsable en caso de robo, extravío o uso indebido de su <strong>App Calimax</strong>.
          Tan pronto como nos comunique el Evento, se realizar la Cancelación de la <strong>App Calimax</strong>, y con eso evitar el mal uso o uso no autorizado. No obstante, no nos haremos responsables en el caso de demora en la comunicación del Evento a nosotros, por el mal uso o uso no autorizado que se haga de su App Calimax.
          El Cliente titular del Contrato con Calimax y el Usuario designado se obligan a no entregar Claves y Contraseñas de las App Calimax a persona distinta al Usuario.
          <br>
          <br>
          <strong>6.	MOVIMIENTO MANUAL.</strong>
          <br>
          <br>
          Es la función de traspasar o cancelar un importe de una <strong>App Calimax</strong>, por la cancelación de una cuenta, reporte de robo o extravío, error en sistema, solicitud del cliente de un traspaso de una cuenta a otra o por baja de un empleado:
          •	DEPÓSITOS A CUENTAS INCORRECTAS: Se realiza un movimiento manual por errores derivados de los depósitos a los empleados del cliente. (Transferencias a cuentas incorrectas).
          •	APP CALIMAX INUTILIZADAS: Se realiza un movimiento manual para regresarle al cliente por medio de una nota de crédito o de un depósito, el saldo de aquellas <strong>App Calimax</strong> que fueron proporcionadas y que nunca fueron utilizadas por sus empleados.
          El plazo para realizar un cargo o abono al saldo de la <strong>App Calimax</strong> es de 48 horas.
          <br>
          <br>
          <strong>7.	CUOTAS Y COMISIONES.</strong>
          <br>
          <br>
          <strong>Central Detallista, SA de CV</strong>, no realiza cobros de Cuotas y Comisiones por el Uso del Servicio de la <strong>App Calimax</strong> a sus Clientes y/o Usuarios.
          <br>
          <br>
          <strong>8.	INFORMACIÓN PUBLICITARIA.</strong>
          <br>
          <br>
          Podremos hacerle llegar información publicitaria, a su teléfono celular.
          <br>
          <br>
          <strong>9.	PROTECCIÓN DE DATOS PERSONALES.</strong>
          <br>
          <br>
          Para saber más sobre cómo hacemos uso de sus datos personales, así como las opciones que tiene en relación con los mismos, por favor consulte nuestro Aviso de Privacidad en el Sitio Web.
          <br>
          <br>
          <strong>10.	SITIO WEB.</strong>
          <br>
          <br>
          Podrá registrarse en nuestro Sitio Web, esto, con el fin de conocer las promociones, las Sucursales que se encuentran cerca de su localidad, entre otros. El uso del Sitio Web está sujeto a Términos y Condiciones distintos a los presentes.
          <br>
          <br>
          <strong>11.	VIGENCIA DE LA APP CALIMAX.</strong>
          <br>
          <br>
          La App Calimax no tiene fecha de vencimiento, salvo que, el Cliente nos indique lo contrario, el Contrato Principal haya llegado a su fin, o bien, se actualice alguna otra causal de terminación señalada en el Término 14 “Terminación”.
          CALIMAX se reserva el derecho de la implementación de nuevas tecnologías o medidas de seguridad o cualesquiera otras circunstancias. En estos casos, te haremos llegar la información correspondiente, a través del Cliente o mediante un mensaje a tu teléfono celular en la que se encuentra instalada la App Calimax, salvo que, el Cliente nos indique lo contrario, el Contrato Principal haya llegado a su fin, o bien, se actualice alguna otra causal de terminación señalada en el Término 14 “Terminación”.
          <br>
          <br>
          <strong>12.	PROPIEDAD INTELECTUAL.</strong>
          <br>
          <br>
          Las marcas, diseños, y cualesquiera otros materiales utilizados en relación con la App Calimax, están sujetos a derechos de propiedad intelectual, protegidos por leyes nacionales e internacionales en la materia, y son propiedad de CALIMAX. El uso de la App Calimax no le otorga ningún tipo de derecho o licencia sobre los materiales utilizados en relación con ésta.
          Queda estrictamente prohibido copiar, reproducir, modificar, crear obras derivadas y divulgar cualesquiera materiales utilizados en relación con la App Calimax.
          <br>
          <br>
          <strong>13.	LIMITACIÓN DE RESPONSABILIDAD.</strong>
          <br>
          <br>
          CALIMAX se obliga con el Usuario únicamente a la prestación del servicio relacionado con la App Calimax, en los términos previstos en estos Términos y Condiciones. CALIMAX no será responsable por:
          <br>
          <br>•	El retraso o no aceptación de la <strong>App Calimax</strong> en las Sucursales, siempre que el retraso o la no aceptación no sean atribuibles directamente a CALIMAX; (Por ejemplo, fallas con el servicio de Internet por el Proveedor);
          <br>•	Daños y perjuicios causados en las Sucursales;
          <br>•	Daños y perjuicios derivados de caso fortuito o fuerza mayor;
          <br>•	Entre otros.
          <br>
          <br>Asimismo, <strong>CALIMAX</strong> quedará libre de cualquier tipo de responsabilidad, en caso de que usted incumpla con los presentes Términos y Condiciones de Uso.
          <br>
          <br>
          <strong>14.	TERMINACIÓN.</strong>
          <br>
          <br>
          Los presentes Términos y Condiciones llegarán a su fin en los siguientes casos:
          <br>
          <br>•	En caso de que <strong>CALIMAX</strong> determine su incumplimiento de los Presentes Términos y Condiciones.
          <br>•	Cuando el Contrato Principal llegue a su fin.
          <br>•	En caso de que el Cliente nos dé instrucciones de dejar de prestar el servicio de Despensa Electrónica "CALIMAX", ya sea porque su relación laboral con el Usuario ha concluido, o cualesquiera otras circunstancias.
          <br>
          <br>
          <strong>15.	INDEMINZACIÓN.</strong>
          <br>
          <br>
          En caso de incumplimiento de estos Términos y Condiciones, se obliga a sacar en paz y a salvo a CALIMAX, sus empleados, accionistas, directivos y empresas relacionadas de cualquier controversia de tipo jurídica o extrajurídica que pudiera surgir, así como, en su caso, a indemnizarlos, a entera satisfacción de estos, por cualquier daño o perjuicio (incluyendo gastos razonables de representación legal) en los que pudieran incurrir con motivo de su incumplimiento.
          <br>
          <br>
          <strong>16.	RESTRICCIONES DE EDAD.</strong>
          <br>
          <br>
          La App Calimax se encuentran dirigidos únicamente a personas mayores de 18 años con plena capacidad de ejercicio, o bien, a menores de edad que se encuentren en aptitud para trabajar, de conformidad con las leyes aplicables, siempre que se cuente con la autorización de sus padres o tutores.
          <br>
          <br>
          <strong>17.	MODIFICACIÓN A LOS TÉRMINOS Y CONDICIONES.</strong>
          <br>
          <br>
          <strong>CALIMAX</strong> podrá cambiar en cualquier momento cualquier estipulación de estos Términos y Condiciones.
          <strong>CALIMAX</strong> le comunicará cualquier cambio sustancial con una anticipación mínima de 30 días naturales, lo cual podrá efectuarse por cualquier de los medios utilizados habitualmente, a través de nuestro Sitio Web, por correo electrónico, por teléfono, mensajes de texto o bien, a través del Cliente. El uso continuado de la <strong>App Calimax</strong> implica su aceptación de los cambios.
          En caso de que se realice un cambio importante o numerosos cambios de menor importancia en algún año determinado, previa solicitud del Cliente, podremos proporcionarle, a través del Cliente, una copia actualizada de estos Términos y Condiciones.
          <br>
          <br>
          <strong>18.	LEGISLACIÓN APLICABLE.</strong>
          <br>
          <br>
          La interpretación y ejecución de estos Términos y Condiciones se regirá por la legislación mexicana que resulte aplicable.  En caso de disputa, las partes se someten a jurisdicción de los tribunales competentes en el Estado de Baja California.
          <br>
          <br>
          <strong>19.	INDEPENDENCIA.</strong>
          <br>
          <br>
          En caso de que una o varias de las disposiciones contenidas en este documento sean declaradas nulas o inejecutables por las autoridades competentes, dichas disposiciones se entenderán excluidas de estos Términos y Condiciones, y el resto de las disposiciones permanecerán válidas, vigentes y ejecutables.
          <br>
          <br>
          <strong>20.	CESIONES.</strong>
          <br>
          <br>
          El Usuario no podrá, en ninguna circunstancia, ceder los derechos u obligaciones que le corresponden en virtud de esos Términos y Condiciones.
          <strong>CALIMAX</strong> se reserva el derecho de ceder, en cualquier momento, previa notificación al Usuario, los derechos y obligaciones que le correspondan en virtud de estos Términos y Condiciones. La notificación pertinente, podrá realizarse directamente al Usuario, a través de correo electrónico o por teléfono, a través del Sitio Web, o bien, a través del Cliente, con al menos 15 días de anticipación a la fecha en que la cesión deba surtir efectos.
          <br>
          <br>
          <strong>21.	CONSENTIMIENTO.</strong>
          <br>
          <br>
          Al hacer uso de la <strong>App Calimax</strong>, usted acepta expresamente someterse a los presentes Términos y Condiciones de Uso, así como a nuestro Aviso de Privacidad.
          <br>
          <br>
          <strong>22.	CONTACTO.</strong>
          <br>
          <br>
          En caso de existir cualquier tipo de Duda y/o Queja y/o Reclamación respecto a los servicios prestados por CALIMAX o los presentes Términos y Condiciones, puede ponerse en contacto con nuestro Centro de Atención a Clientes (Call Center) a través del número Telefónico (01 800) 028-2537, en el horario de lunes a Domingo de 08:00am a 09:00pm incluyendo días festivos.
      </span>
      <div class="current-cards-container">
        <div class="current-cards">
          <div class="current-card">
            <img src="../../../assets/img/credit-card-100.png" alt="">
            <div>
              <h5><strong>{{ card.desc_tarjeta }}</strong></h5>
              <span>{{ decrypt(card.cuenta_r) | hideCardNumber }}</span>
            </div>
          </div>
        </div>
      </div>
  </div>
  <div class="actions-panel">
    <button
        (click)="acceptedTerms()"
        class="primary-button">
        Aceptar terminos y condiciones
    </button>
    <button
        (click)="deniedTerms()"
        class="secondary-button">
        Rechazar
    </button>
  </div>
  </div>
</div>
