import { Component, OnInit } from '@angular/core';
import { DashboardService, MatToolbarInterface } from 'src/app/dashboard.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { EmployeeCardData } from '../../../models/EmployeeCards.model';
import { SaldosMovimientosService } from '../saldos-movimientos.service';

@Component({
  selector: 'app-tarjetas',
  templateUrl: './tarjetas.component.html',
  styleUrls: ['./tarjetas.component.scss']
})
export class TarjetasComponent implements OnInit {
  matMenuState: MatToolbarInterface;
  matElevationValue: string;
  activationForm: FormGroup;
  displayedColumns = ['card_id', 'created_at', 'expire_at', 'card_status', 'movements'];
  customer_id: number;
  company: any = {};
  dataSource: MatTableDataSource<EmployeeCardData>;
  // accessToken: AccessToken;

  constructor(
    private _dashboardService: DashboardService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _saldosMovimientosService: SaldosMovimientosService,
  ) {

    if (localStorage.getItem('company-data') === null) {
      this._router.navigate(['/app/dashboard/cards']);
    }

    this.company = JSON.parse(localStorage.getItem('company-data'));

    const title = this.company.company;

    this.matMenuState = {
      title: title,
      action: 'back',
      color: 'warn',
      icon: 'arrow_back',
      isButtonVisible: true,
      route: '/dashboard/saldos-y-movimientos'
    };

    this._dashboardService.matMenuObs$.next(this.matMenuState);

    this._dashboardService.executeFn$.subscribe(action => {
      this.back(this.matMenuState.route);
    });

    this._route.paramMap.subscribe(params => {
      this.customer_id = parseInt(params.get('customer_id'), 10);
    });

    this.getCardsByEmployee(this.customer_id);

    this.createForm();
  }

  ngOnInit() {}

  back(route: string) {
    this._router.navigateByUrl(route);
  }

  createForm() {
    this.activationForm = this._formBuilder.group({
      user: ['', Validators.required],
      card: ['', Validators.required]
    });
  }

  getCardsByEmployee(customer_id: number) {
    const companyId = this.company.company_id;

    this._saldosMovimientosService.getCardsByEmployee(companyId, customer_id).subscribe(
      response => {
        const cards = response.data;
        this.dataSource = new MatTableDataSource(cards);
      }
    );
  }
}
