<div class="movements-container container pt-4"
    @fadeSlideInOut>
    <div class="movements-container-header">
        <div class="movements-selected-card">
            <div class="card-data">
                <div class="card-data-text">
                    <div>
                        <h5 class="app-module-title m-0">{{ card.desc_tarjeta }}</h5>
                        <p class="app-module-subtitle m-0">Termina en {{ decrypt(card.cuenta_r) | cardLast4 }}</p>
                    </div>
                </div>
            </div>
            <div class="multiple-separation-bottom-actions card-filters">
                <button mat-flat-button
                    *ngIf="isMobile"
                    class="movement-filter-btn attenuated-accent-primary-btn"
                    (click)="openFiltersMenu()">
                        <span class="material-symbols-rounded">tune</span>
                        Filtrar
                </button>
                <button mat-flat-button
                    [hidden]="isMobile"
                    class="movement-filter-btn attenuated-accent-primary-btn"
                    [matMenuTriggerFor]="movementsFilterMenu">
                    <span class="material-symbols-rounded">tune</span>
                    Filtrar
                </button>
                <button mat-flat-button
                    *ngIf="userSelectedPeriod"
                    class="movement-filter-btn attenuated-accent-red-btn"
                    (click)="this.resetFilters(periods[0])">
                        <span class="material-symbols-rounded">undo</span>
                        Remover filtro
                </button>
            </div>
            <div class="mt-3" *ngIf="userSelectedPeriod">
                <p class="medium-text mb-3" *ngIf="isMobile">
                    <strong>Se aplicaron filtros:</strong>
                </p>
                <div class="movement-filter-chip small-label card-theme-border-color">
                    <span class="material-symbols-rounded">sort</span>
                    <p class="m-0">Filtrar por: {{ periods[selectedPeriodIndex].description }}</p>
                </div>
            </div>
        </div>
    </div>
    <mat-menu #movementsFilterMenu>
        <div class="card-movements-filter-item"
            *ngFor="let item of periods; let p = index;"
            (click)="onSelectedPeriod(item)">
            <span class="material-symbols-rounded primary-icon me-3">
                {{ (p != selectedPeriodIndex) ? ' ' : 'done' }}
            </span>
            <p class="small-text m-0">{{ item.description }}</p>
<!--            <div class="p-4 d-flex flex-row align-items-center">-->
<!--                <div class="card-movements-filter-left">-->
<!--                    -->
<!--                </div>-->
<!--                <div class="card-movements-filter-right">-->
<!--                    -->
<!--                </div>-->
<!--            </div>-->
        </div>
    </mat-menu>
    <div class="movements-container-content" *ngIf="details.length >= 1; else noMovements">
        <p class="x-small-label text-uppercase spaced-label">Todos los movimientos ({{ details.length }})</p>
        <div class="card-theme-color theme-shadow p-3 rounded-4">
            <app-detalle-movimiento
                *ngFor="let movement of details; index as i"
                [movement]="movement"
                [showLine]="i < details.length - 1">
            </app-detalle-movimiento>
        </div>
    </div>
    <ng-template #noMovements>
        <div class="no-movements-layout">
            <div>
                <img class="mb-3" src="../../assets/img/empty-movements.svg" width="128" height="128"/>
                <h5 class="app-module-title">No hay movimientos recientes</h5>
                <p class="small-label">No hemos podido encontrar registros de movimientos para esta tarjeta. Intenta de nuevo mas tarde.</p>
            </div>
        </div>
    </ng-template>
    <ng-template #movementsFilterBottom>
        <div>
            <div class="p-3">
                <p class="medium-text m-0"><strong>Filtrar por fecha</strong></p>
<!--                <p class="small-label">Puedes filtrar hasta 90 dias atras</p>-->
            </div>
            <div *ngFor="let item of periods; let p = index;"
                (click)="onSelectedPeriod(item)">
                <div class="card-movements-filter-item p-4">
                    <div class="card-movements-filter-left">
                        <span class="material-symbols-rounded primary-icon me-3">
                            {{ (p != selectedPeriodIndex) ? ' ' : 'done' }}
                        </span>
                    </div>
                    <div class="small-text">
                        <p class="m-0">{{ item.description }}</p>
                    </div>
                </div>
                <mat-divider *ngIf="p < periods.length - 1"></mat-divider>
            </div>
        </div>
<!--        <button mat-flat-button-->
<!--            class="card-filter-btn">-->
<!--            <span class="material-symbols-rounded">search</span>-->
<!--            Aplicar-->
<!--        </button>-->
    </ng-template>
    <div class="movements-container-bottom">
    </div>
</div>
