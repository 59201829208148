import {
    Inject,
    Injectable, OnDestroy,
    Optional,
    Renderer2,
    RendererFactory2,
} from '@angular/core';
import {BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DARK_MODE_OPTIONS } from './consts/dark-mode-options.const';
import { defaultOptions } from './consts/theme-default-options.const';
import { isNil } from './consts/utilities';
import darkmodejs from '@assortment/darkmodejs';

export interface DarkModeOptions {
    darkModeClass: string;
    lightModeClass: string;
    preloadingClass: string;
    storageKey: string;
    element: HTMLElement;
}

@Injectable({
  providedIn: 'root'
})
export class DarkModeService implements OnDestroy {
    private readonly options: DarkModeOptions;
    private readonly renderer: Renderer2;
    private darkModeSubject$: BehaviorSubject<boolean>;
    private syncModeSubject$: BehaviorSubject<boolean>;
    private dmjs: any;

    constructor(
        private rendererFactory: RendererFactory2,
        // prettier-ignore
        @Optional() @Inject(DARK_MODE_OPTIONS) private providedOptions: DarkModeOptions | null
    ) {
        this.options = { ...defaultOptions, ...(this.providedOptions || {}) };
        this.renderer = this.rendererFactory.createRenderer(null, null);
        this.syncModeSubject$ = new BehaviorSubject(this.getInitialSyncModeValue());

        if (this.syncModeSubject$.getValue()) {
            this.syncTheme();
            return;
        }

        if (!this.syncModeSubject$.getValue()) {
            this.darkModeSubject$ = new BehaviorSubject(this.getInitialDarkModeValue());
            this.darkModeSubject$.getValue() ? this.enable() : this.disable();
        }

        this.removePreloadingClass();
    }

    ngOnDestroy() {}

    /**
     * An Observable representing current dark mode.
     * Only fires the initial and distinct values.
     */
    get darkMode$(): Observable<boolean> {
        return this.darkModeSubject$.asObservable().pipe(distinctUntilChanged());
    }

    get syncMode$(): Observable<boolean> {
        return this.syncModeSubject$.asObservable().pipe(distinctUntilChanged());
    }

    toggle(): void {
        if (!this.darkModeSubject$) {
            this.darkModeSubject$ = new BehaviorSubject(this.getInitialDarkModeValue());
        }

        this.darkModeSubject$.getValue() ? this.disable() : this.enable();
    }

    public enable(): void {
        const { element, darkModeClass, lightModeClass } = this.options;
        this.renderer.addClass(element, darkModeClass);
        this.renderer.removeClass(element, lightModeClass);
        this.saveDarkModeToStorage(true);
        this.darkModeSubject$.next(true);
    }

    public disable(): void {
        const { element, darkModeClass, lightModeClass } = this.options;
        this.renderer.addClass(element, lightModeClass);
        this.renderer.removeClass(element, darkModeClass);
        this.saveDarkModeToStorage(false);
        this.darkModeSubject$.next(false);
    }

    toggleSync(): void {
        this.syncModeSubject$.getValue() ? this.unSyncTheme() : this.syncTheme();
    }

    public syncTheme(): void {
        const onChange = (activeTheme, themes) => {
            switch (activeTheme) {
                case themes.DARK:
                    this.loadThemeQuery('assets/styles/dark-mode.theme.css', '(prefers-color-scheme: dark)');
                    break;
                case themes.LIGHT:
                    this.loadThemeQuery('assets/styles/light-mode.theme.css', '(prefers-color-scheme: light)');
                    break;
                case themes.NO_PREF:
                    console.log('no preference enabled');
                    break;
                case themes.NO_SUPP:
                    console.log('no support sorry');
                    break;
            }
        };
        this.dmjs = darkmodejs({ onChange });
        this.saveSyncModeThemeToStorage(true);
        this.syncModeSubject$.next(true);
    }

    public unSyncTheme(): void {
        this.darkModeSubject$ = new BehaviorSubject(this.getInitialDarkModeValue());
        this.disable();
        this.removeQueries();
        this.saveSyncModeThemeToStorage(false);
        this.syncModeSubject$.next(false);
        this.dmjs.removeListeners();
    }

    private getInitialDarkModeValue(): boolean {
        const darkModeFromStorage = this.getDarkModeFromStorage();

        if (isNil(darkModeFromStorage)) {
            return false;
        }

        return darkModeFromStorage;
    }

    private saveDarkModeToStorage(darkMode: boolean): void {
        localStorage.setItem(this.options.storageKey, JSON.stringify({ darkMode }));
    }

    public getDarkModeFromStorage(): boolean | null {
        const storageItem = localStorage.getItem(this.options.storageKey);

        if (storageItem) {
            try {
                return JSON.parse(storageItem)?.darkMode;
            } catch (error) {
                console.error(
                    'Invalid darkMode localStorage item:',
                    storageItem,
                    'falling back to color scheme media query'
                );
            }
        }

        return null;
    }

    private getInitialSyncModeValue(): boolean {
        const syncModeFromStorage = this.getSyncThemeModeFromStorage();

        if (isNil(syncModeFromStorage)) {
            return false;
        }

        return syncModeFromStorage;
    }

    public saveSyncModeThemeToStorage(value: boolean): void {
        localStorage.setItem('sync-theme-from-os', JSON.stringify({ value }));
    }

    public getSyncThemeModeFromStorage(): boolean | null {
        const storageItem = localStorage.getItem('sync-theme-from-os');

        if (storageItem) {
            try {
                return JSON.parse(storageItem)?.value;
            } catch (error) {
                console.error(
                    'Invalid sync mode setting localStorage item:',
                    storageItem,
                    'falling back to color scheme media query'
                );
            }
        }

        return null;
    }

    private loadThemeQuery(themePath: string, query: string): void {
        const scheme = document.getElementById('scheme_query');

        if (scheme && scheme.getAttribute('media') !== query) {
            scheme.setAttribute('href', themePath);
            scheme.setAttribute('media', query);
            return;
        }

        if (scheme && scheme.getAttribute('media') === query) {
            return;
        }

        const head  = document.getElementsByTagName('head')[0];
        const link  = document.createElement('link');

        link.id = 'scheme_query';
        link.rel  = 'stylesheet';
        link.href = themePath;
        link.media = query;

        head.appendChild(link);

        this.removeBodyClasses();
    }

    private removeBodyClasses(): void {
        // defer to next tick
        this.renderer.removeClass(
            this.options.element, 'dark-mode'
        );
        this.renderer.removeClass(
            this.options.element, 'light-mode'
        );
    }

    private removeQueries(): void {
        // defer to next tick
        const scheme = document.getElementById('scheme_query');

        if (scheme) {
            scheme.remove();
        }
    }

    private removePreloadingClass(): void {
        // defer to next tick
        setTimeout(() => {
            this.renderer.removeClass(
                this.options.element,
                this.options.preloadingClass
            );
        });
    }
}
