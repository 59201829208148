<div class="cards">
  <div class="container">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/app/dashboard/saldos-y-movimientos']">Saldos y Movimientos</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/app/dashboard/saldos-y-movimientos/movimientos', customer_id]">Movimientos</a>
        </li>
      </ol>
    </nav>

    <app-summary-cards></app-summary-cards>

    <div class="card-theme-color theme-shadow">
      <div class="table">

        <div class="example-container">

          <mat-table [dataSource]="dataSource">

            <!-- Card Id Column -->
            <ng-container matColumnDef="card_id">
              <mat-header-cell *matHeaderCellDef> Tarjeta </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="tarjeta"> {{ row.card_id }} </mat-cell>
            </ng-container>

            <!-- Create at Column -->
            <ng-container matColumnDef="created_at">
              <mat-header-cell *matHeaderCellDef> Alta </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="fecha"> {{ row.created_at | date }} </mat-cell>
            </ng-container>

            <!-- Expires at Column -->
            <ng-container matColumnDef="expire_at">
              <mat-header-cell *matHeaderCellDef> Vigencia </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="vigencia"> {{ row.expire_at | date }} </mat-cell>
            </ng-container>

            <!-- Card status Column -->
            <ng-container matColumnDef="card_status">
              <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="status">
                {{ row.card_status ? 'Activa' : 'Inactiva' }}
              </mat-cell>
            </ng-container>

            <!-- Movements Column -->
            <ng-container matColumnDef="movements">
              <mat-header-cell *matHeaderCellDef></mat-header-cell>
              <mat-cell *matCellDef="let row">
                <a [routerLink]="['/app/dashboard/saldos-y-movimientos/movimientos', customer_id, row.card_id]" class="btn btn-light">
                  Movimientos
                </a>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
          </mat-table>

        </div>
      </div>
    </div>
  </div>
</div>
