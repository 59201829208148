import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cardLast4'
})
export class CardLast4Pipe implements PipeTransform {

  transform(value: string, ...args: any[]): string {
    if (value.length < 4 || value.length === 4) {
        return value;
    }

    const preIndex = value.length - 4;
    const endIndex = value.length;

    return value.substring(preIndex, endIndex);
  }

}
