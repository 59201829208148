import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../environments/environment';
import {Observable} from 'rxjs';
import {ResponseApi} from './shared/models/data/response';

@Injectable({
  providedIn: 'root'
})
export class McEmailService {
    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.serverURL;
    }

    getEmails(): Observable<ResponseApi<any> | HttpErrorResponse> {
        return this.http.get<any>(`${this.baseUrl}/mc-email/get-emails`);
    }

    addEmail(payload: any): Observable<ResponseApi<any> | HttpErrorResponse> {
        return this.http.post<any>(`${this.baseUrl}/mc-email/add-email`, payload);
    }

    updateEmail(payload: any): Observable<ResponseApi<any> | HttpErrorResponse> {
        return this.http.put<any>(`${this.baseUrl}/mc-email/update-email`, payload);
    }

    deleteEmail(payload: any): Observable<ResponseApi<any> | HttpErrorResponse> {
        return this.http.post<any>(`${this.baseUrl}/mc-email/delete-email`, payload);
    }
}
