import {
  Component,
  Renderer2,
  ElementRef,
  OnInit,
  ViewEncapsulation,
  Inject,
  OnDestroy
} from '@angular/core';
import { ConfigService } from '../../config.service';
import { SPJS100Out } from '../../models/sp_js_100';
import { TermsConditionsService } from '../../terms-conditions.service';
import { TermsPayload } from '../models/terms.payload';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { decrypt } from '../../consts/utilities';

@Component({
  selector: 'app-policies',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermsConditionsComponent implements OnInit, OnDestroy {

  termsPayload: TermsPayload;

  card: SPJS100Out;
  selectedTermsOption = false;

  constructor(
    private renderer: Renderer2,
    public config: ConfigService,
    private termsConditionService: TermsConditionsService,
    public dialog_ref: MatDialogRef<TermsConditionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    console.log(this.termsPayload);
  }

  acceptedTerms() {
    this.modifyTermsPreferenceTo(true);
  }

  deniedTerms() {
    this.modifyTermsPreferenceTo(false);
  }

  modifyTermsPreferenceTo(termsPreference: boolean) {
    const userDeviceData = this.packDeviceData();

    const termsPref: number = (termsPreference) ? 1 : 0;

    this.termsPayload = new TermsPayload(
      null,
      `${this.card.nombre} ${this.card.apellido_p}`,
      termsPref,
      userDeviceData.ip,
      userDeviceData.os,
      userDeviceData.os,
      null,
      null
    );

    this.termsConditionService
      .emitCardTermStatus(this.termsPayload).subscribe(
        _ => {
          // dialog ref
          this.dialog_ref.close(termsPref);
        },
        error => console.error(error)
      );

  }

  /***** IMPORTANTE *****/
  // Enviar a servicio de utileria> para obtener informacion del dispositivo
  packDeviceData(): any {
    return {
      os: 'N/A',
      device: navigator.userAgent,
      ip: window.location.host
    };
  }

  /***** IMPORTANTE *****/
  // Enviar a servicio de utileria> para administrar clases del DOM
  classManager(
    operation: number,
    domObject: ElementRef,
    className: string
  ) {

    if (operation === 1) {
      this.renderer.addClass(
        domObject.nativeElement,
        className
      );
    } else {
      this.renderer.removeClass(
        domObject.nativeElement,
        className
      );
    }
  }

  decrypt(value: string): string {
    return decrypt(value);
  }
}
