import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import {ResponseApi} from './shared/models/data/response';

@Injectable({
  providedIn: 'root'
})
export class ProfileItemModelService {

  constructor(
    private http: HttpClient
  ) {}

  get baseUrl(): string {
    return environment.serverURL;
  }

  getProfileModel(type: string = null): Observable<ResponseApi<any> | HttpErrorResponse> {
    const response = this.http.post<any>(
      `${this.baseUrl}/users/get-profile-config`,
      { type }
    );

    return response;
  }
}
