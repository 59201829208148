import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {SPJS110SLOut} from '../models/SP110WHeader';
import {ConfigService} from '../config.service';
import {DashboardService} from '../dashboard.service';
import {Location} from '@angular/common';
import {AppTitleService} from '../app-title.service';
import {animate, style, transition, trigger} from '@angular/animations';
import {AppUtils} from '../app-utils';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {decrypt, mobile} from '../consts/utilities';

@Component({
    selector: 'app-detalles-tarjetas',
    templateUrl: './detalles-tarjetas.component.html',
    styleUrls: ['./detalles-tarjetas.component.scss'],
    animations: [
        trigger('fadeSlideInOut', [
            transition(':enter', [
                style({ opacity: 0, transform: 'translateY(80px)' }),
                animate('300ms', style({ opacity: 1, transform: 'translateY(0)' })),
            ]),
            transition(':leave', [
                animate('300ms', style({ opacity: 0, transform: 'translateY(80px)' })),
            ]),
        ])
    ]
})
export class DetallesTarjetasComponent implements OnInit {
    card: any;
    cardAmount: any;

    periods: any[] = [
        { value: 1, description: 'Ultimos 30 dias' },
        { value: 2, description: 'Hace 2 meses' },
        { value: 3, description: 'Hace 3 meses' }
    ];
    selectedPeriodIndex = 0;
    userSelectedPeriod = false;

    // Card movements
    details: SPJS110SLOut[] = [];

    isMobile: boolean;

    @ViewChild('movementsFilterBottom', { static: true })
    movementsFilterBottom: TemplateRef<any>;

    constructor(
        private http: HttpClient,
        private router: ActivatedRoute,
        private route: Router,
        private matBottomSheet: MatBottomSheet,
        public config: ConfigService,
        private titleService: AppTitleService,
        private location: Location,
        public dashboard: DashboardService,
    ) {
        this.dashboard.executeFn$.subscribe(
            _ =>  this.location.back()
        );

        this.titleService.setTitle(`Movimientos de tarjeta`);
    }

    ngOnInit(): void {
        this.isMobile = mobile();
        this.card = this.getCardDetails();

        switch (this.card.id_tc) {
            case 1:
                this.cardAmount = this.card.sdo_vales;
                break;
            case 2:
                this.cardAmount = this.card.sdo_certificado;
                break;
            case 3:
                this.cardAmount = this.card.sdo_tregalo;
                break;
            case 4:
                if (this.card.req_sdo_tclub === 2) {
                    this.cardAmount = this.card.sdo_tclub;
                }
                break;
            case 5:
                this.cardAmount = this.card.sdo_monedero;
                break;
            case 6:
                this.cardAmount = this.card.sdo_vales;
                break;
        }

        this.loadCardDetails();
    }

    loadCardDetails(): void {
        this.router.data.subscribe((value: any) => {
            console.log(value);
            const response = value.response;

            if (response.error) { return; }

            const { data } = response;

            this.details = data;
        });
    }

    private getCardDetails(): any {
        const cardData = sessionStorage.getItem('detalles_tarjeta');

        if (cardData) {
            return JSON.parse(cardData);
        }

        return null;
    }

    public getCardMovements(period: any): void {
        this.http.post<any>(`${this.config.serverURL}/card-movements/get-card-movements`, {
            cardno: this.card.id,
            nomeses: period.value,
        }).subscribe((value) => {
            this.details = value.data;
        });
    }

    onSelectedPeriod(event: any): void {
        this.matBottomSheet.dismiss();
        this.getCardMovements(event);
        this.userSelectedPeriod = true;
        this.selectedPeriodIndex = event.value - 1;
    }

    resetFilters(event: any): void {
        this.selectedPeriodIndex = event.value - 1;
        this.userSelectedPeriod = false;
        this.getCardMovements(event);
    }

    decrypt(value: string): string {
        return decrypt(value);
    }

    openFiltersMenu(): void {
        this.matBottomSheet.open(this.movementsFilterBottom);
    }
}
