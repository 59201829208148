<div class="addresses-container container pt-4">
  <div class="addresses-header">
<!--    <span class="material-symbols-rounded">home</span>-->
    <div class="ms-3">
      <h5 class="app-module-title m-0">Direcciones</h5>
      <p class="app-module-subtitle small-label m-0">Aquí puedes administrar tus domicilios.</p>
    </div>
  </div>
  <div class="addresses-content">
    <div class="profile-subgroup">
        <app-address-item *ngFor="let address of addresses"
            [address]="address"
            [config]="configurations['direccion']"
            (requestDeletion)="onRequestItemDeletion($event)">
        </app-address-item>
    </div>
  </div>
  <div class="addresses-bottom">
    <div class="multiple-separation-bottom-actions flex-grow-1">
      <button mat-flat-button
        class="link-action-btn w-100 my-3"
        (click)="profileManager.loadAdding(
            'direccion',
            'MCAddress',
            configurations['direccion'],
            addressComponent
        )">
        <span class="material-symbols-rounded">near_me</span>
          Agregar Dirección
      </button>
    </div>
  </div>
</div>