import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {
    constructor() {}

    public serverURL = environment.serverURL;
    public mapsApiKey = environment.mapsApiKey;

    phrase() {
        return environment.phrase;
    }
}
