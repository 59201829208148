import {NgModule} from '@angular/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatButtonModule} from '@angular/material/button';
import {MatRippleModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDividerModule} from '@angular/material/divider';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSelectModule} from '@angular/material/select';
import {MatMenuModule} from '@angular/material/menu';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDialogModule} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';

@NgModule({
    imports: [
        MatDatepickerModule,
        MatBottomSheetModule,
        MatDialogModule,
        MatStepperModule,
        MatSelectModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatButtonModule,
        MatRippleModule,
        MatMenuModule,
        MatListModule,
    ],
    exports: [
        MatDatepickerModule,
        MatBottomSheetModule,
        MatDialogModule,
        MatStepperModule,
        MatSelectModule,
        MatDividerModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        MatButtonModule,
        MatRippleModule,
        MatMenuModule,
    ]
})
export class CardsMaterialModule {}
