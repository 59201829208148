import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ValidatorsService } from 'src/app/services/validators.service';
import { ProfileActionResult } from '../models/profile.action.result.model';
import {ProfileManagerService} from '../profile-manager.service';
import {AppUtils} from '../../app-utils';
import { externalComponent } from 'src/app.component.registry';
import {mobile} from '../../consts/utilities';

@externalComponent
@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, AfterViewInit {

    isMobile = true;

    editionForm: FormGroup;

    private result: ProfileActionResult = {
        type: null,
        data: null,
        payload: null,
        canceled: true,
        model: null
    };

    type: string;
    payload: any;
    model: any;

    constructor(
        private profileManager: ProfileManagerService,
        private validatorService: ValidatorsService,
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<EditProfileComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.type = data.type;
        this.payload = data.payload;
        this.model = data.model;

        this.editionForm = new FormGroup(
          {},
          {
            asyncValidators: this.validatorService.profileAccountValidation
          }
        );
    }

    ngOnInit() {
        this.isMobile = mobile();
        this.registerFields();
    }

    ngAfterViewInit(): void {}

    get isFormValid(): boolean {
        return this.editionForm.valid && !this.editionForm.pristine;
    }

    get isFormInvalid(): boolean {
    return this.editionForm.invalid || this.editionForm.pristine;
  }

    get alreadyAccountExists(): boolean {
    if (this.editionForm.dirty) {
      return this.editionForm.hasError('accountExists');
    }

    return false;
  }

    private registerFields(): void {
    const template = this.getItems(this.model.template);

    template.forEach((item: any) => {
      const id = item.id;
      const control = this.createControl(item);

      this.editionForm.addControl(id, control);
      this.editionForm.get(id).setValue(this.payload[id]);
    });
  }

    private createControl(item: any): FormControl {
    const validators = this.profileManager.getInputValidators(item);
    return new FormControl(null, validators);
  }

    onSubmit() {
        const data = this.editionForm.value;
        const isValid = this.isFormValid;

        if (isValid) {
          this.result = {
            type: this.type,
            data,
            payload: this.payload,
            canceled: false,
            model: this.model
          };

        this.closeDialog();
    }
  }

    identify(index, item) {
    return item.id;
  }

    closeDialog(): void {
    this.dialogRef.close(this.result);
  }



    getItems(items: any): any {
        return JSON.parse(items);
    }
}
