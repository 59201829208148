import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {ResponseApi} from '../shared/models/data/response';

@Injectable({
  providedIn: 'root'
})
export class AdsService {
    private readonly BASE_PATH: string;

    constructor(
        private http: HttpClient,
    ) {
        this.BASE_PATH = environment.serverURL;
    }

    public getAdsFromBackend(): Observable<any> {
        return new Observable<any[]>((observer) => {
            let lastAdsSyncDateStr = localStorage.getItem(`cmx_ads_sync_date`);
            const hasAdsSyncData = lastAdsSyncDateStr !== null;

            if (!lastAdsSyncDateStr) {
                const currentDate = new Date();
                lastAdsSyncDateStr = currentDate.toString();
            }

            const adsSyncDate = new Date(Date.parse(lastAdsSyncDateStr));
            const requireSync = this.compareSyncData(adsSyncDate) || !hasAdsSyncData;

            if (requireSync) {
                const path = `${this.BASE_PATH}/ads/get-calimax-ads`;
                this.http.get<ResponseApi<any>>(path).subscribe(
                (response) => {
                        const { statusCode, data } = response;

                        if (statusCode === HttpStatusCode.Ok) {
                            const now = new Date();
                            localStorage.setItem('cmx_ads', JSON.stringify(data));
                            localStorage.setItem('cmx_ads_sync_date', now.toString());

                            observer.next(data);
                        }

                        observer.complete();
                    },
                    (error: HttpErrorResponse) => {
                        observer.error(error);
                        observer.complete();
                    }
                );
            }

            if (!requireSync) {
                const ads = this.getAdsFromStorage();

                observer.next(ads);
                observer.complete();
            }
        });
    }

    private getAdsFromStorage(): any[] {
        const recentAds = localStorage.getItem(`cmx_ads`);
        return JSON.parse(recentAds);
    }

    private getFormattedDateTime(dateToBeFormatted?: Date): string {
        const now = new Date();

        const year = (dateToBeFormatted) ? dateToBeFormatted.getFullYear() : now.getFullYear();
        const month = (dateToBeFormatted) ? (dateToBeFormatted.getMonth() + 1) : (now.getMonth() + 1);
        const day = (dateToBeFormatted) ? dateToBeFormatted.getDate() : now.getDate();

        const hour = (dateToBeFormatted) ? dateToBeFormatted.getHours() : now.getHours();
        const minute = (dateToBeFormatted) ? dateToBeFormatted.getMinutes() : now.getMinutes();
        const seconds = (dateToBeFormatted) ? dateToBeFormatted.getSeconds() : now.getSeconds();

        return `${month}/${day}/${year} ${hour}:${minute}:${seconds}`;
    }

    private compareSyncData(syncDate: Date): boolean {
        let requireSync: boolean;
        const currentDateInMillis = Date.parse(this.getFormattedDateTime());
        const syncDateInMillis = syncDate.getTime();

        // console.log(new Date(currentDateInMillis));
        // console.log(new Date(syncDateInMillis));

        // console.log( ( (currentDateInMillis / 100) - (syncDateInMillis / 100) ) );

        // If ads has not updated in 6 hours, download data again.
        requireSync = ((((currentDateInMillis / 100) - (syncDateInMillis / 100))) >= (21600));

        return requireSync;
    }
}
