import {Injectable} from '@angular/core';
import {FormGroup, ValidationErrors} from '@angular/forms';
import axios, {AxiosRequestConfig} from 'axios';
import {Observable} from 'rxjs';
import {environment as config} from 'src/environments/environment';
import {phoneRegex} from '../consts/regex-patterns.consts';
import {getUserId} from '../consts/utilities';

interface ErrorValidate {
    [s: string]: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class ValidatorsService {
    constructor() {}

    isValidAccount(controls: FormGroup): ValidationErrors | null {
        const accountType: string = controls.get('categoria').value;
        const account: string = controls.get('usuario').value;

        const regex: RegExp | string = (accountType === 'email')
            // tslint:disable-next-line: max-line-length
            ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            : /([0-9]{10})/;

        if (account) {
            const isValid = account.toLowerCase().match(regex);

            if (isValid) {
                return null;
            }
        }

        return {invalidAccount: true};
    }

    emailMatch(email: string, emailConfirm: string) {
        return (formGroup: FormGroup) => {
            const emailControl = formGroup.controls[email];
            const emailConfirmControl = formGroup.controls[emailConfirm];

            if (emailControl.value === emailConfirmControl.value) {
                emailConfirmControl.setErrors(null);
            } else {
                emailConfirmControl.setErrors({emailNotMatch: true});
            }
        };
    }

    phoneMatch(phone: string, phoneConfirm: string) {
        return (formGroup: FormGroup) => {
            const phoneControl = formGroup.controls[phone];
            const phoneConfirmControl = formGroup.controls[phoneConfirm];

            if (phoneControl.value === phoneConfirmControl.value) {
                phoneConfirmControl.setErrors(null);
            } else {
                phoneConfirmControl.setErrors({phoneNotMatch: true});
            }
        };
    }

    // passwordValidation(pass1: string, pass2: string) {
    //     return (formGroup: FormGroup) => {
    //         const passControl1 = formGroup.controls[pass1];
    //         const passControl2 = formGroup.controls[pass2];
    //
    //         if (passControl1.value === passControl2.value) {
    //             passControl2.setErrors(null);
    //         } else {
    //             passControl2.setErrors({dontMatch: true});
    //         }
    //     };
    // }

    async getCountryCode(controls: FormGroup): Promise<ErrorValidate | Observable<ErrorValidate>> {
        if (controls.untouched) {
            return null;
        }

        if (!controls.value.account && !controls.value.user) {
            return null;
        }

        const phone = controls.value.user || controls.value.account;
        const isValidPhone = phoneRegex.test(phone);

        if (!isValidPhone) {
            return null;
        }

        const endPoint = `${config.serverURL}/users/get-country-code`;

        try {
            const headers: AxiosRequestConfig = {
                params: { phone },
                withCredentials: false,
            };

            const result = await axios.get(endPoint, headers);
            const { statusCode, data } = <any>result.data;
            const { CountryCode: countryCode } = <any>data;

            // Usuario activo
            if (statusCode === 200 && countryCode > 0) {
                if (controls.value.lada) {
                    controls.get('lada').setValue(countryCode, {
                        onlySelf: true,
                        emitModelToViewChange: true,
                    });
                }

                if (controls.value.pais) {
                    controls.get('pais').setValue(countryCode, {
                        onlySelf: true,
                        emitEvent: false,
                    });
                }

                return null;
            }

            if (countryCode === 0) {
                return { notFoundedCC: true };
            }

            if (statusCode !== 200) {
                return { notFoundedCC: true };
            }
        } catch (error) {
            console.log(error);
            return {unableToCheckAccount: true};
        }
    }

    async userValidation(controls: FormGroup): Promise<ErrorValidate | Observable<ErrorValidate>> {

        if (!controls.value.telefono && !controls.value) {
            return null;
        }

        let user: string;

        if (controls.value.telefono) {
            user = controls.value.lada + controls.value.telefono;
        } else {
            user = controls.value;
        }

        const endPoint = `${config.serverURL}/user-validation/${user}`;

        try {

            const result = await axios.get(endPoint);
            const {codigo} = result.data[0];

            // Usuario activo
            if (codigo === 0) {
                return {activeUser: true};
            }

            // Usuario como dato
            if (codigo === 1) {
                return {userAsData: true};
            }

            // Usuario no existe
            if (codigo === 2) {
                return null;
            }

            // Usuario inactivo
            if (codigo === 3) {
                return null;
            }

        } catch (error) {
            console.log(error);
        }

    }

    async linkAccountValidation(controls: FormGroup): Promise<ErrorValidate | Observable<ErrorValidate>> {
        const id = getUserId();

        if (!controls.value.usuario && !controls.value) {
            return null;
        }

        if (!controls.value.lada && !controls.value.usuario && !controls.value) {
            return null;
        }

        let user: string;

        if (controls.value.categoria === 'sms') {
            user = `${controls.value.lada}${controls.value.usuario}`;
        } else {
            user = controls.value.usuario;
        }

        // Agregar id de usuario
        const endPoint = `${config.serverURL}/users/vinculation-login-validation`;

        try {
            // const token = sessionStorage.getItem('access_token');
            const payload = {action: 'INL'};

            const headers: AxiosRequestConfig = {
                params: {
                    user,
                    id
                },
                data: payload,
                withCredentials: true,
            };

            const result = await axios.get(endPoint, headers);
            const {codigo} = result.data.data[0];

            // Usuario activo
            if (codigo === 0) {
                return {alreadyAccountExists: true, accountExists: true};
            }

            // Usuario como dato
            if (codigo === 1) {
                return {alreadyAccountExists: true, accountExists: true};
            }

            // Usuario no existe
            if (codigo === 2) {
                return {alreadyAccountExists: false, accountExists: false};
            }

            // Usuario inactivo
            if (codigo === 3) {
                return {alreadyAccountExists: true, accountExists: true};
            }

            // Usuario ya registrado
            if (codigo === 4) {
                return {alreadyAccountExists: true, accountExists: true};
            }

        } catch (error) {
            console.log(error);
            return {unableToCheckAccount: true};
        }

    }

    async profileAccountValidation(controls: FormGroup): Promise<ErrorValidate | Observable<ErrorValidate>> {
        const id = getUserId();

        if (!controls.value.email && !controls.value.telefono && !controls.value.account) {
            return;
        }

        if (controls.pristine) {
            return null;
        }

        let user: string;

        if (controls.value.telefono) {
            user = `${controls.value.pais}${controls.value.telefono}`;
        } else if (controls.value.email) {
            user = controls.value.email;
        } else {
            const account = controls.value.account;
            const isPhone = phoneRegex.test(account);

            user = account;

            if (isPhone) {
                const country = controls.value.country;
                user = `${country}${account}`;
            }
        }

        const endPoint = `${config.serverURL}/users/vinculation-login-validation`;

        try {
            // const token = sessionStorage.getItem('access_token');
            const payload = {action: 'IND'};

            const headers: AxiosRequestConfig = {
                params: {
                    user,
                    id
                },
                data: payload,
                withCredentials: true,
            };

            const result = await axios.get(endPoint, headers);
            const {codigo} = result.data.data[0];

            // Usuario activo
            if (codigo === 0) {
                return {accountExists: true};
            }

            // Usuario como dato
            if (codigo === 1) {
                return {accountExists: true};
            }

            // Usuario no existe
            if (codigo === 2) {
                return null;
            }

            // Usuario inactivo
            if (codigo === 3) {
                return {accountExists: true};
            }

            if (codigo === 6) {
                return {accountExists: true};
            }

        } catch (error) {
            console.log(error);
            return {unableToCheckAccount: true};
        }

    }

    async cardValidation(controls: FormGroup): Promise<ErrorValidate | Observable<ErrorValidate>> {

        if (!controls.value.cardNumber && !controls.value.cvv) {
            return null;
        }

        const card = controls.value.cardNumber + '-' + controls.value.ccv;

        const token = sessionStorage.getItem('access_token');
        const headers = {
            headers: {
                Authorization: `Bearer ${token}`
            }
        };

        const endPoint = config.serverURL + '/validacion-tarjeta-virtual';

        const params = {
            param_in: {card, action: 'card', rfc: ''},
            param_out: {},
            funcion: 'sp_vales_card_validation'
        };

        try {
            const {data} = await (await axios.post(endPoint, params, headers)).data;

            const {is_vales} = data[0];

            if (is_vales === 1) {
                return {vouchersCard: true};
            } else {
                return null;
            }

        } catch (error) {
            console.log(error);
        }
    }

    async rfcValidation(controls: FormGroup): Promise<ErrorValidate | Observable<ErrorValidate>> {
        if (!controls.value && !controls.value.rfc) {
            return null;
        }

        const baseUrl = config.serverURL;
        const {card, rfc} = controls.value;
        const body = {card, rfc};
        const headers: AxiosRequestConfig = {
            withCredentials: true
        };
        const endPoint = `${baseUrl}/cards/check-rfc`;

        try {
            // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            const {data} = await axios.post(endPoint, body, headers);
            const validRfc = data.data.rfc;

            if (validRfc > 0) {
                return null;
            } else {
                return {invalidRfc: true};
            }

        } catch (error) {
            console.log(error);
        }
    }

}
