<div>
    <form [formGroup]="container.control">
        <div class="form-responsive">
            <div class="my-3" [hidden]="hidePhone">
                <p class="small-label pb-2">Código de Pais</p>
                <div>
                    <mat-form-field floatLabel="never">
                        <mat-select id="code"
                            placeholder="Seleccione lada"
                            formControlName="code"
                            [class.is-invalid]="invalidCode"
                            (selectionChange)="onSelectedCountry($event)"
                            required>
                            <mat-select-trigger>
                                <div class="d-flex">
                                    <span class="me-2">+{{ this.selectedCountry.area }}</span>
                                </div>
                            </mat-select-trigger>
                            <mat-option *ngFor="let country of countries" [value]="country">
                                +{{ country.area }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="my-3" [hidden]="hidePhone">
                <app-responsive-input
                      id="phone"
                      controlName="phone"
                      type="tel"
                      placeholder="Telefono (10 digitos)"
                      [showIcon]="false"
                      [enableLabel]="true"
                      labelText="Numero de telefono"
                      [enableEnterEvent]="true"
                      pattern="/^[0-9]{10,10}$/"
                      [viewElementRef]="viewElementRef"
                      (enter)="onEnterEvent()">
                </app-responsive-input>
                <mat-error *ngIf="invalidPhone">
                    <img src="../../assets/img/error-circle-100.svg"
                         width="32"
                         height="32"
                         alt="Account Error">
                    Teléfono invalido
                </mat-error>
            </div>
            <div class="my-3">
                <app-responsive-input [hidden]="hideEmail"
                    id="email"
                    controlName="email"
                    type="email"
                    placeholder="Correo electrónico"
                    [showIcon]="false"
                    [enableLabel]="true"
                    labelText="Correo electrónico"
                    [enableEnterEvent]="true"
                    [viewElementRef]="viewElementRef"
                    (enter)="onEnterEvent()"
                    [class.is-invalid]="invalidEmail">
                </app-responsive-input>
            </div>
        </div>
        <mat-error *ngIf="invalidEmail">
            <img src="../../assets/img/error-circle-100.svg"
                 width="32"
                 height="32"
                 alt="Account Error">
            Correo invalido
        </mat-error>
    </form>
</div>