import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { SaldosMovimientosService } from '../../saldos-movimientos.service';
import { ValidatorsService } from '../../../../services/validators.service';

interface Method {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-card-activation-dialog',
  templateUrl: './card-activation-dialog.component.html',
  styleUrls: ['./card-activation-dialog.component.scss']
})
export class CardActivationDialogComponent implements OnInit {

  cardActivationForm: FormGroup;

  methods: Method[] = [{value: 'email', viewValue: 'Email'}, {value: 'phone', viewValue: 'Teléfono'}];
  selected: string = null;
  emailConfirmError = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogRef: MatDialogRef<CardActivationDialogComponent>,
    private _formBuilder: FormBuilder,
    private _saldosMovimientosService: SaldosMovimientosService,
    private _validators: ValidatorsService
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  get emailErrors() {
    return this.cardActivationForm.get('email').invalid && this.cardActivationForm.get('email').touched;
  }

  get emailNotMatch() {
    const email = this.cardActivationForm.get('email').value;
    const emailConfirm = this.cardActivationForm.get('emailConfirm').value;

    return (email === emailConfirm) ? false : true;
  }

  get phoneErros() {
    return this.cardActivationForm.get('phone').invalid && this.cardActivationForm.get('phone').touched;
  }

  get phoneNotMatch() {
    const phone = this.cardActivationForm.get('phone').value;
    const phoneConfirm = this.cardActivationForm.get('phoneConfirm').value;

    return (phone === phoneConfirm) ? false : true;
  }

  close() {
    this._dialogRef.close();
  }

  createForm() {
    this.cardActivationForm = this._formBuilder.group({
      email: [null, [
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
        Validators.required
      ]],
      emailConfirm: [null],
      phone: [null, [
        Validators.pattern('^[0-9]+$'),
        Validators.required,
        Validators.minLength(10)
      ]],
      phoneConfirm: [null],
      method: [null, [
        Validators.required
      ]]
    }, {
      validators: [
        this._validators.emailMatch('email', 'emailConfirm'),
        this._validators.phoneMatch('phone', 'phoneConfirm')
      ]
    });
  }

  getEmailErrors() {
    const format = this.cardActivationForm.controls.email.hasError('pattern');
    const required = this.cardActivationForm.controls.email.hasError('required');

    if (format) { return 'Debe ingresar un formato valido para el email'; }
    if (required) { return 'El email es requerido'; }
  }

  getPhoneErrors() {
    const format = this.cardActivationForm.controls.phone.hasError('pattern');
    const required = this.cardActivationForm.controls.phone.hasError('required');
    const min = this.cardActivationForm.controls.phone.hasError('minlength');

    if (format) { return 'Debe ingresar sólo números'; }
    if (required) { return 'El teléfono es requerido'; }
    if (min) { return 'Debe de ingresar por lo menos 10 dígitos'; }
  }

  getMethodErrors() {
    const required = this.cardActivationForm.controls.method.hasError('required');

    if (required) { return 'Debe elegir un método de vinculación'; }
  }

  getEmailConfirmError() {
    return 'El email no coincide';
  }

  getPhoneConfirmError() {
    return 'El teléfono no coincide';
  }

  submit() {
    if (this.cardActivationForm.status === 'INVALID') {
      return false;
    }

    const form = this.cardActivationForm.value;
    form.customer_id = this.data.customer_id;

    this._saldosMovimientosService.linkEmployeeCard(form).subscribe(
      _ => {
        // enviar correo
        this._dialogRef.close();
      }
    );
  }

  emailConfirm() {
    const email = this.cardActivationForm.get('email').value.trim();
    const emailConfirm = this.cardActivationForm.get('emailConfirm').value;

    if ( email === emailConfirm) {
      return true;
    } else {
      this.emailConfirmError = true;
    }
  }
}


