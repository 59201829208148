import {Component, Inject, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ValidatorsService} from 'src/app/services/validators.service';
import {ProfileActionResult} from '../models/profile.action.result.model';
import {ProfileManagerService} from '../profile-manager.service';
import {externalComponent} from '../../../app.component.registry';
import {mobile} from '../../consts/utilities';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef} from '@angular/material/bottom-sheet';

@externalComponent
@Component({
    selector: 'app-add-info',
    templateUrl: './add-info.component.html',
    styleUrls: ['./add-info.component.scss']
})
export class AddInfoComponent implements OnInit {

    isMobile = true;

    @ViewChild('alreadyExistsModal')
    private alreadyExistsModal: TemplateRef<any>;

    private result: ProfileActionResult = {
        type: null,
        data: null,
        payload: null,
        canceled: true,
        model: null
    };

    addingForm: FormGroup;

    type: string;
    payload: any;
    model: any;

    constructor(
        private _dialog: MatDialog,
        private profileManager: ProfileManagerService,
        private validatorService: ValidatorsService,
        public dialogRef: MatDialogRef<AddInfoComponent>,
        public bottomRef: MatBottomSheetRef<AddInfoComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MAT_BOTTOM_SHEET_DATA) public bottom_data: any,
    ) {
        this.type = data.type || bottom_data.type;
        this.payload = data.payload || bottom_data.payload;
        this.model = data.model || bottom_data.model;

        this.addingForm = new FormGroup(
            {},
            {
                asyncValidators: this.validatorService.profileAccountValidation
            }
        );
    }

    ngOnInit() {
        this.isMobile = mobile();
        this.registerFields();
    }

    get isFormValid(): boolean {
        return this.addingForm.valid && !this.addingForm.pristine;
    }

    get isFormInvalid(): boolean {
        return this.addingForm.invalid || this.addingForm.pristine;
    }

    // get alreadyAccountExists(): boolean {
    //     if (this.addingForm.dirty) {
    //         return this.addingForm.hasError('accountExists');
    //     }
    //
    //     return false;
    // }

    isInvalidInput(id: string): boolean {
        const control = this.addingForm.get(id);
        return (control.touched && control.invalid);
    }

    private createControl(item: any): FormControl {
        const validators = this.profileManager.getInputValidators(item);
        return new FormControl(null, validators);
    }

    private registerFields(): void {
        const template = JSON.parse(this.model.template);

        template.forEach((item: any) => {
            const id = item.id;
            const control = this.createControl(item);

            this.addingForm.addControl(id, control);
        });
    }

    onSubmit() {
        const data = this.addingForm.value;
        const isValid = this.isFormValid;

        if (isValid) {
            this.result = {
                type: this.type,
                data,
                payload: this.payload,
                canceled: false,
                model: this.model
            };

            this.closeDialog();
        }
    }

    identify(index, item) {
        return item.id;
    }

    closeDialog(): void {
        // if (this.isMobile) {
        //     this.bottomRef.dismiss(this.result);
        //     return;
        // }

        this.dialogRef.close(this.result);
    }

    getItems(items: any): any {
        return JSON.parse(items);
    }

    cancel(): void {
        // if (this.isMobile) {
        //     this.bottomRef.dismiss(this.result);
        // }

        this.dialogRef.close(this.result);
    }
}
