export class MCPhone {
    codigo: number;
    es_usuario: number;
    estado: string;
    id_cliente: string;
    id_tel: string;
    mensaje: string;
    pais: number;
    statuscode: number;
    sw_primario: number;
    sw_valida: number;
    telefono: string;
    tipo_tel: string;

    constructor(
        codigo: number = 0,
        es_usuario: number = 0,
        estado: string = '',
        id_cliente: string = '',
        id_tel: string = '',
        mensaje: string = '',
        pais: number = 0,
        statuscode: number = 0,
        sw_primario: number = 0,
        sw_valida: number = 0,
        telefono: string = '',
        tipo_tel: string = 'APP'
    ) {
        this.codigo = codigo;
        this.es_usuario = es_usuario;
        this.estado = estado;
        this.id_cliente = id_cliente;
        this.id_tel = id_tel;
        this.mensaje = mensaje;
        this.pais = pais;
        this.statuscode = statuscode;
        this.sw_primario = sw_primario;
        this.sw_valida = sw_valida;
        this.telefono = telefono;
        this.tipo_tel = tipo_tel;
    }
}
