import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AppTitleService} from 'src/app/app-title.service';
import {DefaultMCConfig} from 'src/app/consts/mc-config.const';
import {MCConfigGroup} from '../models/mc.group.config.model';
import {MCPersonal} from '../models/mc.personal.model';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {ProfileDataService} from '../profile-data.service';
import {FormControl} from '@angular/forms';
import {ProfileItemModelService} from '../../profile-item-model.service';

@Component({
    selector: 'app-profile-personal',
    templateUrl: './profile-personal.component.html',
    styleUrls: ['./profile-personal.component.scss']
})
export class ProfilePersonalComponent implements OnInit, OnChanges {
    @Input() externalInstance = false;

    public nameControl = new FormControl();
    public lastNameControl = new FormControl();
    public lastName2Control = new FormControl();
    public rfcControl = new FormControl();
    public dobControl = new FormControl();
    public curpControl = new FormControl();

    @Input() hideName = false;
    @Input() hideLastName = false;
    @Input() hideLastName2 = false;
    @Input() hideRfc = false;
    @Input() hideDob = false;
    @Input() hideCurp = false;

    error: Error = null;
    @Input() personal: MCPersonal = null;
    @Input() configurations: any = DefaultMCConfig;

    @Output() confirmChanges = new EventEmitter<MCPersonal>();

    constructor(
        private titleService: AppTitleService,
        private profileDataService: ProfileDataService,
        private profileItemModel: ProfileItemModelService,
        private activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit() {
        if (!this.externalInstance) {
            this.titleService.setTitle('Datos personales');

            this.activatedRoute.data.subscribe(value => {
                const { response } = value;
                const {personalResponse, personalConfigResponse} = response;

                if (personalResponse instanceof HttpErrorResponse) {
                    this.error = personalResponse;
                    return;
                }

                if (personalConfigResponse instanceof HttpErrorResponse) {
                    this.error = personalConfigResponse;
                    return;
                }

                const { data: personalData } = personalResponse;

                this.personal = personalData[0];

                this.initControls(personalData[0]);

                this.onConfigResponse(personalConfigResponse);
            });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.personal) {
            const value = changes.personal.currentValue;

            if (value instanceof HttpErrorResponse) {
                this.error = value;
                return;
            }

            const { data: personalData } = value;

            this.personal = personalData[0];

            this.initControls(personalData[0]);
        }

        if (changes.configurations) {
            const value = changes.configurations.currentValue;

            if (value instanceof HttpErrorResponse) {
                this.error = value;
                return;
            }

            this.onConfigResponse(value);
        }
    }

    private initControls(data: any): any {
        const { nombre, ap_paterno, ap_materno, rfc, curp, f_nacimiento } = data;

        this.nameControl = new FormControl(nombre, []);
        this.lastNameControl = new FormControl(ap_paterno, []);
        this.lastName2Control = new FormControl(ap_materno, []);
        this.rfcControl = new FormControl(rfc, []);
        this.dobControl = new FormControl(f_nacimiento, []);
        this.curpControl = new FormControl(curp, []);
    }

    private onConfigResponse(response: any): void {
        const configurations = {};
        const {data} = response;

        if (response.statusCode !== HttpStatusCode.Ok) {
            return;
        }

        data.forEach((config: MCConfigGroup) => {
            const type = config.type;
            configurations[type] = config;
        });

        this.configurations = configurations;

        console.log(response);
    }

    private onConfigError(error: Error): void {
        console.error(error);
    }

    public confirmData(): void {
        // Update available props
        this.personal.nombre = this.nameControl.value;
        this.personal.ap_paterno = this.lastNameControl.value;
        this.personal.ap_materno = this.lastName2Control.value;
        this.personal.rfc = this.rfcControl.value;
        this.personal.f_nacimiento = this.dobControl.value;
        this.personal.curp = this.curpControl.value;

        const rawPayload = JSON.stringify(this.personal);

        this.profileDataService.personalRequest('UP', rawPayload, (response) => {
            this.confirmChanges.emit(this.personal);
        });

    }

}
