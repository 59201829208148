<h6 class="large-title">
    <strong>¿Esta seguro de eliminar su cuenta?</strong>
</h6>
<div mat-dialog-content class="mat-typography">
  <p class="small-label m-0">
      Se eliminara su cuenta y los datos asociados a ella.
      No se puede deshacer esta acción.
  </p>
</div>
<div class="multiple-separation-bottom-actions pt-3">
    <button mat-flat-button
            class="attenuated-accent-red-btn"
            cdkFocusInitial
            (click)="borrar()">
        <strong>Borrar</strong>
    </button>
    <button mat-flat-button
        class="bg-transparent small-accent"
        (click)="cancelar()">
           <strong>Cancelar</strong>
    </button>
</div>
