<app-user-question *ngIf="userNoHaveCards && userNotAnswered"
                   title="¿Cuentas con tarjetas Calimax?"
                   description="Si no cuentas con tarjeta te generaremos una tarjeta digital."
                   iconPath="../../../../../assets/img/calimax_logo_square.svg"
                   primaryActionText="Si"
                   (primaryAction)="onPrimaryAction()"
                   (secondaryAction)="onSecondaryAction()"
                   secondaryActionText="No tengo">
</app-user-question>

<div [hidden]="userNoHaveCards && userNotAnswered" #linkCardContainer
     id="linkCardContainer"
     class="add-card-container container"
     *ngIf="accountType === 'owner'"
     @fadeSlideInOut>
    <div class="add-card-header pt-4">
        <!--        <span class="material-symbols-rounded">credit_card</span>-->
        <div class="ms-3">
            <h5 class="app-module-title m-0">
                {{ sectionTitle }}
            </h5>
            <!--            <p class="small-label app-module-subtitle m-0">Vamos a comenzar</p>-->
        </div>
    </div>
    <div class="add-card-content">
        <div class="mg-top mg-bottom">
            <ng-container *ngIf="!!addressComponent && addressComponent.info">
                <div class="alert alert-info" role="alert">
                    <h4 class="alert-heading">Parece que tu código postal no pertenece a México</h4>
                    <p>Revisalo y si es correcto captura tu dirección en el formulario</p>
                </div>
            </ng-container>

            <mat-horizontal-stepper [linear]="true" labelPosition="bottom" #stepper>
<!--                <ng-container *ngIf="!isClubCard">-->
<!--                    <mat-step label='decision'>-->
<!--                        <app-decision-component label='decision'>-->
<!--                        </app-decision-component>-->
<!--                    </mat-step>-->
<!--                </ng-container>-->

                <!-- Componente de para vincular tarjeta -->
                <ng-container *ngIf="!isClubCard">
                    <ng-container *ngIf="byCard; else phone">
                        <mat-step [stepControl]="cardForm" label="cardStep">
                            <form [formGroup]="cardForm" autocomplete="off">
                                <app-card-item [viewElementRef]="viewEl"
                                               (enterEvent)="cardStep()">
                                </app-card-item>
                            </form>
                        </mat-step>
                    </ng-container>

                    <!-- Componente para vincular telefono -->
                    <ng-template #phone>
                        <mat-step [stepControl]="phoneForm" label="cardStep">
                            <form [formGroup]="phoneForm" autocomplete="off">
                                <app-telefonoregistro [viewElementRef]="viewEl"
                                                      (enterEvent)="cardStep()">
                                </app-telefonoregistro>
                            </form>
                        </mat-step>
                    </ng-template>
                </ng-container>

                <!-- Componente para validar rfc -->
                <ng-container *ngIf="showRfcForm && !isClubCard">
                    <mat-step [stepControl]="rfcForm" label="rfcStep">
                        <form [formGroup]="rfcForm" autocomplete="off">
                            <app-rfc [viewElementRef]="viewEl"
                                     (enterEvent)="rfcStep()">
                            </app-rfc>
                        </form>
                    </mat-step>
                </ng-container>

                <!-- Componente de mensaje de cuenta -->
                <ng-container *ngIf="showAccountMessage">
                    <mat-step label="messageStep">
                        <app-message
                            [accountData]="accountData"
                            [showBtn]="showPersonalFormBtn"
                            (goToPersonalFormStep)="goToPersonalFormStep($event)">
                        </app-message>
                    </mat-step>
                </ng-container>

                <!-- Componente para capturar informacion personal -->
                <ng-container *ngIf="!hidePersonalDataForm">
                    <mat-step [stepControl]="personalInfoForm" label="personalDataStep">
                        <form [formGroup]="personalInfoForm">
                            <app-personal-information [personalDataConfig]="personalDataConfig"
                                                      [viewElementRef]="viewEl">
                            </app-personal-information>
                        </form>
                    </mat-step>
                </ng-container>

                <!-- Componente para capturar informacion de direccion -->
                <ng-container *ngIf="showAddressForm">
                    <mat-step [stepControl]="addressForm" label="addressDataStep">
                        <form [formGroup]="addressForm">
                            <app-address [addressDataConfig]="addressDataConfig">
                            </app-address>
                        </form>
                    </mat-step>
                </ng-container>

                <!-- Componente para capturar informacion de contacto -->
                <ng-container *ngIf="showContactForm">
                    <mat-step [stepControl]="contactInfoForm" label="contactDataStep">
                        <form [formGroup]="contactInfoForm">
                            <app-contact-information [contactDataConfig]="contactDataConfig"
                                                     (enterEvent)="contactDataStep()"
                                                     [viewElementRef]="viewEl">
                            </app-contact-information>
                        </form>
                    </mat-step>
                </ng-container>

                <!-- Componente para capturar informacion fiscal -->
                <ng-container *ngIf="showTaxForm">
                    <mat-step [stepControl]="taxInfoForm" label="taxDataStep">
                        <form [formGroup]="taxInfoForm">
                            <app-tax-information [taxDataConfig]="taxDataConfig">
                            </app-tax-information>
                        </form>
                    </mat-step>
                </ng-container>

            </mat-horizontal-stepper>
        </div>
    </div>

    <ng-container *ngIf="formReady">
        <div class="add-card-bottom multiple-separation-bottom-actions">
            <button mat-flat-button
                    class="role-action-btn w-100"
                    (click)="nextStep(stepper.selected.label)">
                <span class="material-symbols-rounded m-0">arrow_forward</span>
                {{ isLastStep ? 'Finalizar' : 'Siguiente' }}
            </button>
            <button mat-flat-button
                    class="link-action-btn w-100"
                    (click)="previousStep()">
                <span class="material-symbols-rounded m-0">arrow_back</span>
                Regresar
            </button>
            <button *ngIf="enableSkipButton" mat-flat-button
                    class="bg-transparent accent-color w-100"
                    (click)="skipCardForSession()">
                Mas tarde
            </button>
        </div>
    </ng-container>

    <ng-template #errMessageTemplate>
        <div class="container d-flex justify-content-between align-items-center gap-3"
             [class.flex-row]="!isMobile"
             [class.flex-column]="isMobile">
            <div class="d-flex flex-column py-3">
                <p class="x-small-text text-uppercase spaced-label fw-bold">{{ message }}</p>
            </div>
            <button mat-flat-button [hidden]="isMobile"
                    class="text-danger card-theme-color"
                    (click)="dismiss()">
                <span class="material-symbols-rounded">close</span>
            </button>
            <mat-divider *ngIf="isMobile"></mat-divider>
            <button mat-flat-button *ngIf="isMobile"
                    class="bg-transparent accent-color w-100 mb-3"
                    (click)="dismiss()">
                Aceptar
            </button>
        </div>
    </ng-template>
</div>

<ng-template #addedDigitalCard>
    <div class="unable-to-deliver-content p-3">
        <div>
            <p class="medium-text fw-bold m-0 pb-3">Se genero una tarjeta digital</p>
        </div>
        <div>
            <p class="small-label pb-3">
                Puedes mostrar esta tarjeta en cajas y obtener descuentos, ofertas, acumular monedero y mucho mas.
            </p>
        </div>
        <div>
            <button mat-flat-button
                    class="role-action-btn w-100"
                    (click)="dismiss()">
                Aceptar
            </button>
        </div>
    </div>
</ng-template>
<ng-template #failedToGenCard>
    <div class="unable-to-deliver-content p-3">
        <div>
            <p class="medium-text fw-bold m-0 pb-3">No se pudo generar la tarjeta</p>
        </div>
        <div>
            <p class="small-label pb-3">Estamos teniendo problemas para generar tu tarjeta digital. Intenta mas tarde.</p>
        </div>
        <div>
            <button mat-flat-button
                    class="role-action-btn w-100"
                    (click)="dismiss()">
                Aceptar
            </button>
        </div>
    </div>
</ng-template>
