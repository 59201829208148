import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { McClient } from './models/McClient.model';
import { McAddress } from './models/McAddress.model';
import { McPhone } from './models/McPhone.model';
import { McEmail } from './models/McEmai.model';
import { McCard } from './models/McCard.model';

import { environment } from '../environments/environment';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable()
export class LinkCardService {
    private readonly BASE_URL: string;
    private readonly BASE_URL_API: string;

  constructor(private http: HttpClient) {
      this.BASE_URL = `${environment.serverURL}/cards`;
      this.BASE_URL_API = `${environment.serverURL}`;
  }

  getFormConfig(vinculationNumber: string, channel: string) {
    const body = { vinculationNumber, channel };
    return this.http.post<any>(
      `${this.BASE_URL}/get-card-form-config`, body)
      .pipe(
        map(response => {
          return response;
        })
    );
  }

  savePersonalData(data: McClient) {
    return this.http.put<any>(
      `${this.BASE_URL_API}/mc-personal/update-user`, data)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  saveTaxData(data) {
    return this.http.post<any>(
      `${this.BASE_URL}/update-mc-client-data`, data
    ).pipe(
        map(response => {
          return response;
        })
    );
  }

  saveAddressData(data: McAddress) {
    return this.http.post<any>(
      `${this.BASE_URL_API}/mc-address/add-address`, data)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  savePhoneData(data: McPhone) {
    return this.http.post(
      `${this.BASE_URL_API}/mc-telephone/add-phone`, data)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  saveEmailData(data: McEmail) {
    return this.http.post(
      `${this.BASE_URL_API}/mc-email/add-email`, data)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  saveCard(data: McCard) {
    return this.http.post(
      `${this.BASE_URL}/add-card`, data).pipe(
        map(response => {
          return response;
        })
      );
  }

  generateDigitalCard(phone?: string): Observable<any> {
      return this.http.post(`${this.BASE_URL}/generate-card`, {
          vinculationNumber: phone
      });
  }

  accountValidation(account: string): any {
    return this.http.get(
      `${this.BASE_URL}/account-validation/${account}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  clubCardValidation(phone: string): any {
    return this.http.get(
      `${this.BASE_URL}/club-card-validation/${phone}`)
      .pipe(
        map(response => {
          return response;
        })
      );
  }
}
