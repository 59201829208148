import { MCConfigGroup } from '../profile/models/mc.group.config.model';

export const DefaultMCConfig = {
    nombre: new MCConfigGroup(),
    ap_paterno: new MCConfigGroup(),
    ap_materno: new MCConfigGroup(),
    rfc: new MCConfigGroup(),
    f_nacimiento: new MCConfigGroup(),
    curp: new MCConfigGroup(),
    nss: new MCConfigGroup(),
    email: new MCConfigGroup(),
    telefono: new MCConfigGroup(),
    direccion: new MCConfigGroup()
};
