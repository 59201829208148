<div #reportContainer class="report-container container">
    <div class="report-header pt-4">
<!--        <span class="material-symbols-rounded">report</span>-->
        <div class="ms-3">
            <h3 class="app-module-title m-0">
                <strong>Reporte de tarjeta - {{ card.desc_tarjeta | cardDesc }}</strong>
            </h3>
            <p class="small-label m-0">Llena el formato para levantar un nuevo reporte</p>
        </div>
    </div>
    <div class="report-content">
        <form class="report-form" [formGroup]="reportForm" (ngSubmit)="onSubmit()">
            <div>
                <p class="small-label pb-2">Motivo de Reporte</p>
                <mat-form-field floatLabel="never">
                    <mat-select (selectionChange)="onReasonSelected($event)"
                        placeholder="Selecciona un motivo">
                        <mat-select-trigger>
                            {{ this.selectedReason.text }}
                        </mat-select-trigger>
                        <mat-option [value]="reason"
                            *ngFor="let reason of reasons; let i = index">
                            <div class="report-select-template">
                                <span class="m-0">{{ reason.text }}</span>
                                <p class="small-label m-0">Reposición en 7 dias habiles</p>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div>
                <app-responsive-input id="comments"
                    controlName="comments"
                    type="text"
                    placeholder="Escribe tus comentarios"
                    [showIcon]="false"
                    [enableLabel]="true"
                    labelText="Comentarios (Opcional)"
                    [enableEnterEvent]="false"
                    [viewElementRef]="viewElementRef">
                </app-responsive-input>
            </div>
        </form>
    </div>
    <div class="report-bottom multiple-separation-bottom-actions">
        <button mat-raised-button
            class="role-action-btn w-100"
            type="button"
            (click)="onSubmit()">
                Confirmar reporte
        </button>
        <button mat-raised-button
            class="link-action-btn w-100"
            type="button"
            (click)="onCancel()">
                Cancelar
        </button>
    </div>
</div>

<!-- Successful send report process-->
<ng-template #reportModal>
    <div class="container report-result-modal">
        <div>
            <div *ngIf="reportId">
                <img src="../../assets/img/success-checkmark.svg" alt="Success report">
                <h3 class="report-subtitle">
                    Tu número de folio es:
                </h3>
                <div class="report-folio">
                    <strong>{{ reportId }}</strong>
                </div>
            </div>
            <h3 class="report-subtitle">
                <strong>{{ resultMessage }}</strong>
            </h3>
            <p class="small-label">
                {{ details }}
            </p>
<!--            <p *ngIf="reportId" style="text-align: center;">-->
<!--                Conserve su número de folio, en breve recibirá un correo-->
<!--                electrónico o SMS, con más información.-->
<!--            </p>-->
        </div>
        <div class="dialog-actions">
            <button mat-raised-button
                class="role-action-btn"
                (click)="dismissDialog()"
                aria-hidden="true">
                Aceptar
            </button>
        </div>
    </div>
</ng-template>

<!-- Unsuccessful send report process -->
<ng-template #errorModal>
    <div>
<!--        <img src="../../assets/img/error-100.png" alt="Unsuccessful">-->
        <h2 class="report-subtitle">
            {{ resultMessage }}
            <br>
            Te mostramos mas detalles acerca del problema:
        </h2>
        <p style="text-align: center;">
            {{ this.details }}
        </p>
    </div>
</ng-template>