<div class="p-3">
    <div class="deletion-header">
        <div class="d-flex flex-row align-items-center">
            <span class="material-symbols-rounded">delete</span>
            <div class="ms-3">
                <p class="m-0"><strong>¿Desea eliminar el {{ model.name }}?</strong></p>
                <p class="small-label m-0">Esta acción no puede deshacerse.</p>
            </div>
        </div>
    </div>
    <p class="py-4 text-center">{{ payload[model.type] }}</p>
    <p class="small-label" *ngIf="model.group === 'contact'">
        Los datos de contacto como usuario no pueden ser eliminados.
    </p>
    <div class="multiple-separation-bottom-actions">
        <button class="btn btn-danger w-100"
            mat-flat-button
            (click)="confirmDeletion()">
            <strong>Eliminar</strong>
        </button>
        <button mat-flat-button
            (click)="cancelDeletion()"
            class="bg-transparent accent-color w-100">
            <strong>Cancelar</strong>
        </button>
    </div>
</div>
