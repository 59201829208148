import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class ModalDeleteService {

  constructor(
    private http: HttpClient,
    public config: ConfigService
  ) { }

  deleteUser(id: any): Observable<any> {
    return this.http.delete(
      `${this.config.serverURL}/users/delete-account`
    );
  }
}
