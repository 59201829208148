import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, tap, timeout } from 'rxjs/operators';
import { DashboardService } from './dashboard.service';

@Injectable({
    providedIn: 'root'
})
export class TimeoutInterceptorService implements HttpInterceptor {

    constructor(
        private dashboard: DashboardService,
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // this.dashboard.spin$.next(true);

        return next.handle(req).pipe(
            tap(),
            timeout(30000),
            finalize(() => {
                this.dashboard.spin$.next(false);
            })
        );
    }
}
