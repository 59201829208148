import { MCAddress } from './mc.address.model';
import { MCEmail } from './mc.email.model';
import { MCPersonal } from './mc.personal.model';
import { MCPhone } from './mc.phone.model';

export const ProfileInstantiableClasses: any = {
    MCAddress,
    MCEmail,
    MCPersonal,
    MCPhone
};
