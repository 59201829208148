import {Directive, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';

@Directive({
    selector: '[appMenuItemFontSize]'
})
export class MenuItemDirective implements OnChanges {
    static counter = 0;

    @Input() appMenuItemFontSize;
    styleEl: HTMLStyleElement = document.createElement('style');

    // Generate unique attribule which we will use to minimise the scope of our dynamic style
    uniqueAttr = `app-menu-item-${MenuItemDirective.counter++}`;

    constructor(private el: ElementRef) {
        const nativeEl: HTMLElement = this.el.nativeElement;
        nativeEl.setAttribute(this.uniqueAttr, '');
        nativeEl.appendChild(this.styleEl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateFontSize();
    }

    updateFontSize(): void {
        // update dynamic style with the uniqueAttr
        this.styleEl.innerText = `
            [${this.uniqueAttr}] {
            font-size: ${this.appMenuItemFontSize}px;
            font-weight: normal;
        }`;
    }

}
