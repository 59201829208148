import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import {Observable, of} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './../config.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DetallesTarjetasResolverService implements Resolve<any> {
    constructor(
        private http: HttpClient,
        private config: ConfigService,
    ) {}

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        const body = {
            cardno: route.params.cardId,
            nomeses: 1,
        };

         return this.http.post(
             `${this.config.serverURL}/card-movements/get-card-movements`, body
         ).pipe(
             map(res => res),
             catchError(error => {
                 return of({ error: error });
             })
         );
    }
}
