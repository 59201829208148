<ng-container [formGroup]="controlContainer.control">
  <div>
    <div *ngIf="errorTelefono">
        <div>
            <mat-label>{{ mensaje }}</mat-label>
        </div>
    </div>
    <div class="form-responsive">
      <div>
        <p class="small-label pb-2">País</p>
        <mat-form-field floatLabel="never">
          <mat-select id="code"
            placeholder="Seleccione lada"
            formControlName="lada"
            [class.is-invalid]="invalidCode"
            (selectionChange)="onSelectedCountry($event)"
            required>
            <mat-select-trigger>
              <div class="d-flex">
                <span class="me-2">+{{ this.selectedCountry.area }}</span>
              </div>
            </mat-select-trigger>
              <mat-option *ngFor="let country of countries" [value]="country">
                +{{ country.area }}
              </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-error class="text-danger" *ngIf="invalidCode">
          <img src="../../assets/img/error-circle-100.svg"
								width="32"
								height="32"
								alt="Account Error">
            Debe seleccionar un código de país
        </mat-error>
      </div>
      <div>
        <!-- mask="(000) 000-0000" -->
        <app-responsive-input id="phone"
          controlName="telefono"
          type="tel"
          placeholder="(000) 000-0000"
          [showIcon]="false"
          [enableLabel]="true"
          labelText="Teléfono"
          [enableEnterEvent]="true"
          [viewElementRef]="viewElementRef"
          (enter)="onEnterEvent()">
        </app-responsive-input>
        <mat-error class="text-danger" *ngIf="invalidPhone">
          <img src="../../assets/img/error-circle-100.svg"
							width="32"
							height="32"
							alt="Account Error">
              {{ phoneErrorMessage }}
        </mat-error>
      </div>
    </div>
  </div>
</ng-container>
