export const McPhoneConst: any = {
    paction: 'IN',
    pid_cliente: '',
    pid_tel: '',
    ppais: '',
    ptelefono: '',
    ptipo_tel: '2',
    psw_primario: '',
    pes_usuario: '',
    psw_valida: 0,
    pip: null,
    pso: null,
    papp: null
};
