<div class="filters">
    <form [formGroup]="filtersForm" novalidate>
        <div class="d-flex justify-content-between">
            <div class="d-flex">
                <mat-form-field floatLabel="never">
                    <mat-label>Id cliente</mat-label>
                    <input matInput formControlName="customer">
                    <mat-error *ngIf="filtersForm.controls['customer'].invalid">
                        {{ getNumberErrors() }}
                    </mat-error>
                </mat-form-field>
                <mat-form-field floatLabel="never" class="horizontal-margin">
                    <mat-label>Id empleado</mat-label>
                    <input matInput formControlName="employee">
                    <mat-error *ngIf="filtersForm.controls['employee'].invalid">
                        {{ getNumberErrors() }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="filter-options">
                <div>
                    <button mat-flat-button
                        class="rounded-pill py-1 px-4 role-action-btn me-3"
                        (click)="getFilters()">
                        Buscar
                    </button>
                    <button mat-flat-button
                        class="rounded-pill py-1 px-4 link-action-btn"
                        (click)="changeFilterState()">
                        Filtrar
                        <span class="material-symbols-rounded">
                        {{ (showFilters) ? 'expand_less' : 'expand_more' }}
                    </span>
                    </button>
                </div>
            </div>
        </div>
        <div>
<!--            <div class="d-flex">-->
<!--                <mat-form-field floatLabel="never">-->
<!--                        <mat-label>Id cliente</mat-label>-->
<!--                        <input matInput formControlName="customer">-->
<!--                        <mat-error *ngIf="filtersForm.controls['customer'].invalid">-->
<!--                            {{ getNumberErrors() }}-->
<!--                        </mat-error>-->
<!--                    </mat-form-field>-->
<!--                <mat-form-field floatLabel="never" class="horizontal-margin">-->
<!--                        <mat-label>Id empleado</mat-label>-->
<!--                        <input matInput formControlName="employee">-->
<!--                        <mat-error *ngIf="filtersForm.controls['employee'].invalid">-->
<!--                            {{ getNumberErrors() }}-->
<!--                        </mat-error>-->
<!--                    </mat-form-field>-->
<!--            </div>-->
            <div class="d-flex justify-content-between" *ngIf="showFilters">
                <div class="d-flex flex-row align-items-center">
                    <mat-form-field floatLabel="never">
                        <mat-label>Nombre</mat-label>
                        <input matInput formControlName="name">
                        <mat-error *ngIf="filtersForm.controls['name'].invalid">
                            {{ getStringErrors() }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field floatLabel="never" class="horizontal-margin">
                        <mat-label>Apellido paterno</mat-label>
                        <input matInput formControlName="lastname">
                        <mat-error *ngIf="filtersForm.controls['lastname'].invalid">
                            {{ getStringErrors() }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="checkboxes">
                    <section>
                        <mat-checkbox formControlName="balance" (change)="getFilters()">
                            Con saldo
                        </mat-checkbox>
                    </section>
                    <section>
                        <mat-checkbox formControlName="gift_card">
                            Tarjeta de regalo
                        </mat-checkbox>
                    </section>
                </div>
            </div>
        </div>
    </form>
</div>