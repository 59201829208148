import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import {AppUtils} from '../../app-utils';
import {mobile} from '../../consts/utilities';

export enum TemplateType {
  WIDE = 'wide',
  EXTENDED = 'extended',
  WIDE_WITH_OPTIONS = 'wide_with_options'
}

export enum IconType {
  MATERIAL_SYMBOL = 'material_symbol',
  CUSTOM = 'custom',
  IMAGE = 'image'
}

@Component({
  selector: 'app-dashboard-banner',
  templateUrl: './dashboard-banner.component.html',
  styleUrls: ['./dashboard-banner.component.scss']
})
export class DashboardBannerComponent implements OnInit, AfterViewInit {

  @Input() title = 'No title';
  @Input() description = 'No description';
  @Input() iconType = IconType.MATERIAL_SYMBOL;
  @Input() icon: string;
  @Input() imagePath: string;
  @Input() showIcon = true;
  @Input() showImageForMobile = false;
  @Input() showImage = false;
  @Input() applyCustomClass = false;
  @Input() templateType = 'wide';
  @Input() showPill = false;
  @Input() pillText = 'Im a pill';

  // Stylization
  @Input() templateIconColor = '#000000';
  @Input() templateTitleColor = '#000000';
  @Input() templateDescColor = '#00000080';
  @Input() templateBackground = '#ffffff';
  @Input() templatePillBackground = '#dddddd';
  @Input() templatePillColor = '#000000';

  @Input() showActions = false;
  @Input() actionButtonText = 'Accept';
  @Input() actionButtonClass: string;

  @Input() actionHandler: void;

  // Child instances
  @ViewChild('template', { static: false }) template: ElementRef;
  @ViewChild('templatePill', { static: false }) templatePill: ElementRef;
  @ViewChild('templateTitle', { static: false }) templateTitle: ElementRef;
  @ViewChild('templateDesc', { static: false }) templateDesc: ElementRef;
  @ViewChild('templateIcon', { static: false }) templateIcon: ElementRef;
  @ViewChild('templateButton', { static: false }) templateOkButton: ElementRef;

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.setTemplateBackground(this.templateBackground);
    this.setTemplateTextAreaTitleColor(this.templateTitleColor);
    this.setTemplateTextAreaDescColor(this.templateDescColor);
    this.setTemplatePillBackgroundColor(this.templatePillBackground);
    this.setTemplatePillTextColor(this.templatePillColor);
    this.setTemplateIconAreaColor(this.templateIconColor);
  }

  setTemplateBackground(color: string): void {
    this.changeStyleProperty(this.template, 'background', color);
  }

  setTemplatePadding(padding: string): void {
    this.changeStyleProperty(this.template, 'padding', padding);
  }

  setTemplateIconAreaColor(color: string): void {
    this.changeStyleProperty(this.templateIcon, 'color', color);
  }

  setTemplateTextAreaTitleColor(color: string): void {
    this.changeStyleProperty(this.templateTitle, 'color', color);
  }

  setTemplateTextAreaDescColor(color: string): void {
    this.changeStyleProperty(this.templateDesc, 'color', color);
  }

  setTemplatePillBackgroundColor(color: string): void {
    this.changeStyleProperty(this.templatePill, 'background', color);
  }

  setTemplatePillTextColor(color: string): void {
    this.changeStyleProperty(this.templatePill, 'color', color);
  }

  changeStyleProperty(el: any, propertyName: string, propertyValue: any): void {
    this.renderer.setStyle(el.nativeElement, propertyName, propertyValue);
  }

  get isMobile(): boolean {
      return mobile();
  }

}
