import {Injectable, OnDestroy} from '@angular/core';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsReporterService {

    constructor() {}

    public logEvent(eventName: string): void {
        console.log(`Recording event: ${eventName}`);
        const app = initializeApp(environment.firebaseOptions);
        const analytics = getAnalytics(app);
        logEvent(analytics, eventName);
    }

    public setDataCollectionState(enabled: boolean): void {
        // this.analytics.setAnalyticsCollectionEnabled(enabled);
    }
}
