import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {TermsConditionsComponent} from './terms-and-conditions/terms-conditions.component';

const routes: Routes = [
    {
        component: PrivacyPolicyComponent,
        path: 'privacy-policy'
    },
    {
        component: TermsConditionsComponent,
        path: 'terms-and-conditions',
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PoliciesRoutingModule {}
