export const McEmailConst: any = {
    paction: 'IN',
    pid_cliente: '',
    pid_email: '',
    pemail: '',
    ptipo_email: 'APP',
    psw_primario: '',
    psw_valida: 0,
    pes_usuario: '',
    pip: '',
    pso: '',
    papp: ''
};
