import { Component, Input, OnChanges, Output, SimpleChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnChanges {

  @Input()
  accountData: any;
  @Input()
  showBtn: boolean;
  @Output()
  goToPersonalFormStep = new EventEmitter<any>();
  name: string;
  lastName: string;
  motherLastName: string;

  ngOnChanges(changes: SimpleChanges): void {
    this.setAccountData(changes.accountData);
  }

  setAccountData({ currentValue }): void {
    if (!Object.keys(currentValue).length) {
      return;
    }

    const { name, lastName, motherLastName } = currentValue;

    this.name = name;
    this.lastName = lastName;
    this.motherLastName = motherLastName;
  }

  personalFormStep(value): void {
    this.goToPersonalFormStep.emit(value);
  }

}
