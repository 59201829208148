import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpperCaseDirective } from '../uppercase.directive';
import { CardDescPipe } from '../card.desc.pipe';
import { CapitalizePipe } from '../capitalize.pipe';
import { MultipleCapitalizePipe } from '../multiple-capitalize.pipe';
import { WeightPipe } from '../weight.pipe';
import { DigitsPipe } from '../digits.pipe';
import { ResponsiveTextInputComponent } from './responsive-text-input/responsive-text-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { DashboardBannerComponent } from './dashboard-banner/dashboard-banner.component';
import { CardLast4Pipe } from '../dashboard-module/cards/card/card-last4.pipe';
import {AuthInputComponent} from './auth-input/auth-input.component';
import {ResponsiveTextInputNoFrmComponent} from './responsive-text-input-no-frm/responsive-text-input-no-frm.component';
import {AuthInputNfrComponent} from './auth-input-nfr/auth-input-nfr.component';
import {OrderFirstNamePipe} from '../order-first-name.pipe';
import {MaterialModule} from '../material.module';
import { CustomIconColorDirective } from './custom-icon-color.directive';
import { CustomItemHoverColorDirective } from './custom-item-hover-color.directive';
// import {PersonalDataRequestScreenComponent} from '../profile/personal-data-request-screen/personal-data-request-screen.component';
import { PaymentInfoRequestViewComponent } from './payment-info-request-view/payment-info-request-view.component';
import { EcommercePdpViewComponent } from './ecommerce-pdp-view/ecommerce-pdp-view.component';
import {ProfileModule} from '../profile/profile.module';
import { UserQuestionComponent } from './user-question/user-question.component';
import { H1Component } from './h1/h1.component';
import { H2Component } from './h2/h2.component';
import { TextlineComponent } from './textline/textline.component';

@NgModule({
    declarations: [
        UpperCaseDirective,
        CardDescPipe,
        OrderFirstNamePipe,
        CapitalizePipe,
        MultipleCapitalizePipe,
        WeightPipe,
        DigitsPipe,
        CardLast4Pipe,
        ResponsiveTextInputComponent,
        ResponsiveTextInputNoFrmComponent,
        DashboardBannerComponent,
        AuthInputComponent,
        AuthInputNfrComponent,
        CustomIconColorDirective,
        CustomItemHoverColorDirective,
        PaymentInfoRequestViewComponent,
        EcommercePdpViewComponent,
        UserQuestionComponent,
        H1Component,
        H2Component,
        TextlineComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskModule,
        MaterialModule,
        ProfileModule,
    ],
    exports: [
        ResponsiveTextInputComponent,
        ResponsiveTextInputNoFrmComponent,
        DashboardBannerComponent,
        AuthInputComponent,
        AuthInputNfrComponent,
        CardDescPipe,
        OrderFirstNamePipe,
        CapitalizePipe,
        MultipleCapitalizePipe,
        WeightPipe,
        DigitsPipe,
        CardLast4Pipe,
        CustomIconColorDirective,
        CustomItemHoverColorDirective,
        PaymentInfoRequestViewComponent,
        UserQuestionComponent
    ]
})
export class SharedComponentsModule { }
