<!-- Declare tabs and your content in backend -->
<div class="profile-container container my-4" @fadeSlideInOut>
    <div class="profile-header card-theme-color theme-shadow cmx-mark" @fadeSlideInOutHeader>
        <div class="d-flex align-items-center flex-column">
            <img [src]="picture"
                 class="card-theme-color theme-shadow large-rounded-picture card-theme-border-color"
                 alt="Profile picture">
            <div class="profile-header-text text-center mt-3">
                <p class="profile-full-name app-module-title m-0">
                    <strong>{{ username | titlecase }} {{ surname | titlecase }}</strong></p>
                <p class="medium-label glance-card-theme-color py-1 px-3 rounded-5 m-0"
                    style="backdrop-filter: blur(32px)">
                    {{ account }}
                </p>
            </div>
        </div>
    </div>
    <div class="profile-content">
        <p class="small-label my-4 text-uppercase spaced-label">Detalles de la cuenta</p>
        <div class="profile-options">
            <div mat-ripple matRippleColor="#CCCCCC25"
                 class="profile-option card-theme-color theme-shadow"
                 [routerLink]="['/app/profile/personal']">
                <div class="d-flex flex-row align-items-center">
                    <span class="material-symbols-rounded profile-option-icon">account_circle</span>
                    <div class="ms-3">
                        <p class="profile-option-title m-0">Datos personales</p>
                        <p class="x-small-label m-0">Detalles de tu información personal.</p>
                    </div>
                </div>
                <span class="material-symbols-rounded">arrow_forward</span>
            </div>
            <div mat-ripple matRippleColor="#CCCCCC25"
                 class="profile-option card-theme-color theme-shadow"
                 [routerLink]="['/app/profile/contact']">
                <div class="d-flex flex-row align-items-center">
                    <span class="material-symbols-rounded profile-option-icon">contacts</span>
                    <div class="ms-3">
                        <p class="profile-option-title m-0">Contacto</p>
                        <p class="x-small-label m-0">Cuentas de correo y numeros telefonicos.</p>
                    </div>
                </div>
                <span class="material-symbols-rounded">arrow_forward</span>
            </div>
            <div mat-ripple matRippleColor="#CCCCCC25"
                 class="profile-option card-theme-color theme-shadow"
                 [routerLink]="['/app/profile/addresses']">
                <div class="d-flex flex-row align-items-center">
                    <span class="material-symbols-rounded profile-option-icon">home</span>
                    <div class="ms-3">
                        <p class="profile-option-title m-0">Direcciones</p>
                        <p class="x-small-label m-0">Domicilios registrados en tu cuenta.</p>
                    </div>
                </div>
                <span class="material-symbols-rounded">arrow_forward</span>
            </div>
<!--            <div mat-ripple matRippleColor="#CCCCCC25"-->
<!--                 class="profile-option card-theme-color theme-shadow"-->
<!--                 [routerLink]="['/dashboard/profile/contact']">-->
<!--                <div class="d-flex flex-row align-items-center">-->
<!--                    <span class="material-symbols-rounded profile-option-icon">credit_card</span>-->
<!--                    <div class="ms-3">-->
<!--                        <p class="profile-option-title m-0">Medios de pago</p>-->
<!--                        <p class="x-small-label m-0">Administra tus formas de pago.</p>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <span class="material-symbols-rounded">arrow_forward</span>-->
<!--            </div>-->
        </div>
        <p class="small-label my-4 text-uppercase spaced-label">Mas opciones</p>
        <div class="profile-options">
            <div mat-ripple matRippleColor="#CCCCCC25" class="profile-option card-theme-color theme-shadow"
                 (click)="deleteAccount()">
                <div class="d-flex flex-row align-items-center">
                    <span class="material-symbols-rounded profile-option-icon delete">delete</span>
                    <div class="ms-3">
                        <p class="profile-option-title m-0">Borrar cuenta</p>
                        <p class="x-small-label m-0">Eliminar definitivamente su cuenta.</p>
                    </div>
                </div>
<!--                <span class="material-symbols-rounded">arrow_forward</span>-->
            </div>
            <div mat-ripple matRippleColor="#CCCCCC25" class="profile-option card-theme-color theme-shadow"
                 (click)="logOut()">
                <div class="d-flex flex-row align-items-center">
                    <span class="material-symbols-rounded profile-option-icon">logout</span>
                    <div class="ms-3">
                        <p class="profile-option-title m-0">Cerrar sesión</p>
                        <p class="x-small-label m-0">Salir de la aplicación.</p>
                    </div>
                </div>
<!--                <span class="material-symbols-rounded">arrow_forward</span>-->
            </div>
        </div>
    </div>

    <div class="profile-bottom"></div>
</div>

<ng-template #failedUpdate>
    <div>
        <img src="../../../assets/img/delete-100.svg"
             alt="Failed Update">
        <h5>
            Error al actualizar perfil
            <p class="load-state-label">
                Ocurrió un error al actualizar la información del perfil.
                Por favor intenta más tarde.
            </p>
        </h5>
    </div>
</ng-template>
