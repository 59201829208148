import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {forkJoin, Observable} from 'rxjs';
import { ProfileService } from '../../profile.service';
import {ProfileItemModelService} from '../../profile-item-model.service';

@Injectable({
    providedIn: 'root'
})
export class ProfilePersonalResolverService implements Resolve<any> {
    constructor(
        private profileService: ProfileService,
        private profileModelService: ProfileItemModelService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin({
            personalResponse: this.profileService.clienteAPI('SL', null),
            personalConfigResponse: this.profileModelService.getProfileModel(),
        });
    }
}
