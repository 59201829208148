import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {animate, state, style, transition, trigger} from '@angular/animations';

import {SPJS100Out} from '../../../models/sp_js_100';
import {AppTitleService} from 'src/app/app-title.service';
import Swiper, {Autoplay, FreeMode, Navigation, Pagination, SwiperOptions} from 'swiper';
import {AnalyticsReporterService} from '../../../services/analytics-reporter.service';
import {SwiperComponent} from 'swiper/angular';
import {mobile} from 'src/app/consts/utilities';
import {AdsService} from '../../../services/ads.service';
import {Subscription} from 'rxjs';

Swiper.use([Navigation, Pagination, Autoplay, FreeMode]);

@Component({
    selector: 'app-consultar-tarjetas',
    templateUrl: './consultar-tarjetas.component.html',
    styleUrls: ['./consultar-tarjetas.component.scss'],
    animations: [
        trigger('fade', [
            state(
                'void',
                style({opacity: 0, transform: 'translateX(-80px)'})
            ),
            transition(':enter, :leave', [animate('300ms ease-in')])
        ])
    ]
})
export class ConsultarTarjetasComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() cards: SPJS100Out[] = [];
    cardsIsValid: boolean;
    selectedCard: string;

    routesList: any = [
        {
            path: '/app/dashboard/cards/add/card/owner',
            isVisible: false,
        },
        {
            path: '/app/scan-and-go',
            isVisible: false,
        },
        {
            path: '/app/profile',
            isVisible: false,
        },
        {
            path: '/app/tickets',
            isVisible: false,
        },
        {
            path: '/app/scan-and-go/account',
            isVisible: false,
        }
    ];

    @ViewChild('swiperAdsSlide') swiperSlideShow: SwiperComponent;

    // Dashboard ads
    @Input() ads: any[] = [];

    config: SwiperOptions = {
        autoHeight: true,
        freeMode: true,
        slidesPerView: 2,
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 2,
                spaceBetween: 16,
                slidesOffsetAfter: 16,
                slidesOffsetBefore: 16,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3,
                spaceBetween: 16
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3,
                spaceBetween: 16
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 16
            },
            1024: {
                slidesPerView: 5,
                spaceBetween: 32
            }
        }
        // navigation: true,
        // pagination: { clickable: true },
        // scrollbar: { draggable: true },
    };
    adsConfig: SwiperOptions = {
        autoHeight: true,
        slidesPerView: 'auto',
        // height: 150,
        // navigation: true,
        spaceBetween: 16,
        pagination: {clickable: true},
        // scrollbar: { draggable: true },
    };

    pinValidationIn = false;
    termsPreference: number;

    public nameToShow = '';

    private resolverSub: Subscription;
    private adsSub: Subscription;

    constructor(
        public route: Router,
        private adsService: AdsService,
        private analyticsService: AnalyticsReporterService,
        private activeRoute: ActivatedRoute,
        private titleService: AppTitleService,
    ) {
        this.titleService.setTitle('Inicio');
    }

    ngOnInit(): void {
        this.resolverSub = this.activeRoute.data.subscribe((value: any) => {
            const { response } = value;
            const { cards } = response;

            if (cards instanceof HttpErrorResponse) {
                this.cardsIsValid = false;
                return;
            }

            const { data: cardsData } = cards;

            if (cardsData.length > 0) {
                this.cards = this.filterCards(cardsData);
                const haveCards = this.cards.length > 0;

                sessionStorage.setItem('have_cards', `${haveCards}`);
            }

            if (cardsData.length === 0) {
                this.cardsIsValid = false;
            }
        });
        this.handleAds();
        this.getUserName();
    }

    ngAfterViewInit(): void {
        this.swiperSlideShow.swiperRef.autoplay.start();
    }

    ngOnDestroy(): void {
        this.resolverSub.unsubscribe();
        this.adsSub.unsubscribe();
    }

    filterCards(data: SPJS100Out[]): SPJS100Out[] {
        return data.filter(card => card.codigo === 0);
    }

    identify(item: any, index: any) {
        return item.cl_id;
    }

    updateTermPref(event) {
        this.termsPreference = event;
    }

    selectCard($event) {
        this.selectedCard = $event;
    }

    handleAds(): void {
        this.adsSub = this.adsService.getAdsFromBackend().subscribe(
            response => {
                this.ads = response;
            },
            error => {
                console.error(`ADS Error: ${JSON.stringify(error)}`);
            }
        );
    }

    private getUserName(): void {
        const name = localStorage.getItem('name');
        this.nameToShow = name;
    }

    public onRequestCardDeletion($event: any): void {
        this.cards = this.cards.filter(c => c.id !== $event.id);
        this.analyticsService.logEvent('user_deleted_card');
    }

    get mobile(): boolean {
        return mobile();
    }
}

