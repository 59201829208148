import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VirtualEmpCardService {

  url: string = environment.serverURL;

  constructor(
    private _http: HttpClient
  ) { }

  getCardByUserType(cardId: number, beneficiaryId: number): Observable<any> {
      return this._http.post(`${this.url}/virtual-card/get-card-by-type`,
          {
              card_id: cardId,
              beneficiary_id: beneficiaryId,
          }
      );
  }
}
