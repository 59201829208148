import { Pipe, PipeTransform } from '@angular/core';
// import { last } from 'rxjs/operators';

@Pipe({
    name: 'hideCardNumber'
})
export class HideCardNumberPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        try {
            const lastDigits = value.substr(value.length - 4, value.length);
            const firstDigits = value.substr(0, 4);
            let xxxx = '';
            for (let x = 4; x < value.length - lastDigits.length; x++) {
                if (x % 4 === 0) {
                    xxxx += ' •';
                } else {
                    xxxx += '•';
                }
            }
            return firstDigits + ' ' + xxxx + ' ' + lastDigits;
        } catch (e) {
            return '****';
        }
    }
}
