import { Injectable, OnDestroy} from '@angular/core';
import { DynamicClassLoader } from './dynamic.class.loader';
import { MatDialog } from '@angular/material/dialog';
import { MCConfigGroup } from './models/mc.group.config.model';
import { ValidatorFn, Validators } from '@angular/forms';
import {BehaviorSubject, Subject} from 'rxjs';
import {ProfileDataService} from './profile-data.service';
import {AddInfoComponent} from './add-info/add-info.component';
import {EditProfileComponent} from './edit-profile/edit-profile.component';
import {ComponentType} from '@angular/cdk/overlay';
import {ProfileActionResult} from './models/profile.action.result.model';
import {HttpStatusCode} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProfileManagerService implements OnDestroy {

    public updateChangesSub$ = new Subject<{ result: any, type: string }>();
    public addingChangesSub$ = new Subject<{ result: any, type: string }>();

    public updateChanges$ = this.updateChangesSub$.asObservable();
    public addingChanges$ = this.addingChangesSub$.asObservable();

    constructor(
        private dialog: MatDialog,
        private profileData: ProfileDataService,
    ) {
        this.checkForAddingChanges();
        this.checkForUpdateChanges();
    }

    ngOnDestroy(): void {
        this.updateChangesSub$.unsubscribe();
        this.addingChangesSub$.unsubscribe();
    }

    private checkForUpdateChanges(): void {
        if (this.updateChangesSub$.closed) {
            this.resetUpdateSubscription();
        }
    }

    private checkForAddingChanges(): void {
        if (this.addingChangesSub$.closed) {
            this.resetAddingSubscription();
        }
    }

    private resetUpdateSubscription(): void {
        this.updateChangesSub$ =
            new Subject<{ result: any, type: string }>();

        this.updateChanges$ = this.updateChangesSub$.asObservable();
    }

    private resetAddingSubscription(): void {
        this.addingChangesSub$ =
            new Subject<{ result: any, type: string }>();

        this.addingChanges$ = this.addingChangesSub$.asObservable();
    }

    // Load edition form or dedicated form
    public loadEditor(
        type: string,
        reqLevel: number,
        data: any,
        configuration: MCConfigGroup,
        customEditionComponent?: ComponentType<any>
    ): void {
        if (reqLevel === 3) { return; }

        if (customEditionComponent) {
            this.openEditorDialog(type, data, configuration, customEditionComponent);
            return;
        }

        this.openEditorDialog(type, data, configuration);
    }

    // tslint:disable: no-string-literal
    private openEditorDialog(
        type: string,
        data: any,
        model: any,
        component?: ComponentType<any>
    ): void {
        const dialog = this.dialog.open(
            (component) ? component : EditProfileComponent,
            {
                data: { type, payload: data, model, action: 'UP' },
            }
        );

        dialog.afterClosed().subscribe(response => {
            if (response) {
                this.checkEditorResult(response);
            }
        });
    }

    private checkEditorResult(response: any): void {
        if (response.canceled) {
            return;
        }

        this.requestUpdate(response);
    }

    // Load adding form or dedicated form
    public loadAdding(
        type: string,
        className: string,
        configuration: MCConfigGroup,
        customAdditionComponent?: ComponentType<any>,
    ): void {
        if (customAdditionComponent) {
            this.openAddingDialog(type, className, configuration, customAdditionComponent);
            return;
        }

        this.openAddingDialog(type, className, configuration);
    }

    // tslint:disable: no-string-literal
    private openAddingDialog(
        type: string,
        className: string,
        model: any,
        component?: ComponentType<any>
    ): void {
        const classInstance = new DynamicClassLoader(className);
        const dialog = this.dialog.open(
            (component) ? component : AddInfoComponent,
            {
                data: {
                    type,
                    payload: classInstance,
                    model,
                    action: 'IN',
                }
            }
        );

        dialog.afterClosed().subscribe(
            response =>  {
                if (response) {
                    this.checkAddingResult(response);
                }
            }
        );
    }

    private checkAddingResult(response: any): void {
        if (response.canceled) {
            return;
        }

        this.requestAdding(response);
    }

    // Called when user add new data
    private requestAdding(response: ProfileActionResult): void {
        const { type, data, payload, canceled, model } = response;
        const newPayload = this.updatePayload(model, payload, data);
        const event = model.caller;

        this.profileData[event]('IN', newPayload, (result: any) => {
            const { data: cmxData } = result;
            const { codigo, statuscode } = cmxData[0];

            if (codigo === 0 || statuscode === HttpStatusCode.Ok) {
                this.addingChangesSub$.next({ result, type });
            }
        });
    }

    // Called when user update existing data
    private requestUpdate(response: any): void {
        const { type, data, payload, _, model } = response;
        const newPayload = this.updatePayload(model, payload, data);
        const event = model.caller;

        this.profileData[event]('UP', newPayload, (result: any) => {
            const { data: cmxData } = result;
            const { codigo, statuscode } = cmxData[0];

            if (codigo === 0 || statuscode === HttpStatusCode.Ok) {
                this.updateChangesSub$.next({ result, type });
            }
        });
    }

    private updatePayload(model: any, payload: any, data: any): any {
        const template = this.parseJson(model.template);

        template.forEach(item => {
            const newValue = data[item.id];
            payload[item.id] = newValue;
        });

        return JSON.stringify(payload);
    }

    public getInputValidators(item: any): ValidatorFn[] {
        const validators: ValidatorFn[] = [Validators.required];

        const pattern = (item.pattern) ? item.pattern : null;
        const minLength = (item.minLength) ? item.minLength : null;
        const maxLength = (item.maxLength) ? item.maxLength : null;
        // const accountValidation = (item.accountValidation);

        if (pattern) {
            validators.push(Validators.pattern(new RegExp(pattern)));
        }

        if (minLength) {
            validators.push(Validators.minLength(minLength));
        }

        if (maxLength) {
            validators.push(Validators.maxLength(maxLength));
        }

        return validators;
    }

    parseJson(json: string): any {
        return JSON.parse(json);
    }
}
