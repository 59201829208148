<div class="moves">
  <div class="container">

    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a [routerLink]="['/app/dashboard/saldos-y-movimientos']">Saldos y Movimientos</a>
        </li>
        <li class="breadcrumb-item">
          <a [routerLink]="['/app/dashboard/saldos-y-movimientos/tarjetas', customer_id]">Tarjetas</a>
        </li>
      </ol>
    </nav>

    <app-summary-cards></app-summary-cards>

    <div class="card-theme-color theme-shadow">
      <div class="table">
        <div class="header" fxLayout="row" class="d-flex">
          <mat-form-field>
            <mat-label>Buscar por fechas</mat-label>
            <mat-select [(ngModel)]="selectedDate" (ngModelChange)="getMovementsByDate()">
              <mat-option [value]=0>Desde el principio</mat-option>
              <mat-option *ngFor="let month of months" [value]="month.value">
                {{ month.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div>

          <mat-table *ngIf="movements && movements.length > 0" [dataSource]="dataSource">

            <!-- Date Column -->
            <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef> Fecha </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="date"> {{ row.transaction_date | date: 'dd/MM/yyyy HH:mm:ss': 'UTC' }} </mat-cell>
            </ng-container>

            <!-- Transaction Column -->
            <ng-container matColumnDef="transaction">
              <mat-header-cell *matHeaderCellDef> Operacion </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="transaction"> {{ row.card_transaction }} </mat-cell>
            </ng-container>

            <!-- In Column -->
            <ng-container matColumnDef="in">
              <mat-header-cell *matHeaderCellDef> Entrada </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="in"> {{ row.transaction_in | currency }} </mat-cell>
            </ng-container>

            <!-- Out Column -->
            <ng-container matColumnDef="out">
              <mat-header-cell *matHeaderCellDef> Salida </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="out"> {{ row.transaction_out | currency }} </mat-cell>
            </ng-container>

             <!-- Balance Column -->
             <ng-container matColumnDef="balance">
              <mat-header-cell *matHeaderCellDef> Saldo </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="balance"> {{ row.balance | currency }} </mat-cell>
            </ng-container>

             <!-- Reference Column -->
             <ng-container matColumnDef="reference">
              <mat-header-cell *matHeaderCellDef> Referencia </mat-header-cell>
              <mat-cell *matCellDef="let row" data-label="reference"> {{ row.reference || '-' }} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
          </mat-table>

          <div *ngIf="!!movements &&  movements.length == 0" class="py-5 mt-5 text-center">
            <h3 class="py-5 mb-0 custom-title">
              No hay movimientos.
            </h3>
          </div>

        </div>
      </div>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>

  </div>
</div>

