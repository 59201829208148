// tslint:disable: variable-name
export class MCAddress {
   calle: string;
   ciudad: string;
   ciudad_id: number;
   cliente_id: string;
   codigo_postal: string;
   colonia: string;
   domicilio_id: string;
   estado: string;
   estado_id: number;
   mensaje: string;
   numero_ext: string;
   numero_int: number;
   referencias: string;
   statuscode: number;
   lat = 0.0;
   lng = 0.0;
   origen_coords = 2;

   constructor(
      calle: string = '',
      ciudad: string = '',
      ciudad_id: number = 0,
      cliente_id: string = '',
      codigo_postal: string = '',
      colonia: string = '',
      domicilio_id: string = '',
      estado: string = '',
      estado_id: number = 0,
      mensaje: string = '',
      numero_ext: string = '',
      numero_int: number = 0,
      referencias: string = '',
      statuscode: number = 0
   ) {
      this.calle = calle;
      this.ciudad =  ciudad;
      this.ciudad_id =  ciudad_id;
      this.cliente_id =  cliente_id;
      this.codigo_postal =  codigo_postal;
      this.colonia =  colonia;
      this.domicilio_id =  domicilio_id;
      this.estado =  estado;
      this.estado_id =  estado_id;
      this.mensaje =  mensaje;
      this.numero_ext =  numero_ext;
      this.numero_int =  numero_int;
      this.referencias =  referencias;
      this.statuscode =  statuscode;
   }
}
