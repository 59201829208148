<div class="w-100 question-presenter container">
    <div class="question-presenter-top-area"></div>
    <div class="question-presenter-middle-area">
        <img [src]="iconPath"
             class="mb-4"
             width="84"
             height="84"
             alt>
        <h3 class="sg-present-title gradient-accent-color text-center">
            <strong>{{ title }}</strong>
        </h3>
        <div class="scan-and-go-terms">
            <p class="small-label">
                {{ description }}
            </p>
        </div>
    </div>
    <div class="question-presenter-bottom-area multiple-separation-bottom-actions">
        <button *ngIf="showPrimaryAction" mat-flat-button
                class="role-action-btn w-100"
                (click)="onPrimaryAction()">
            {{ primaryActionText }}
        </button>
        <button *ngIf="showSecondaryAction" mat-flat-button
                class="link-action-btn w-100"
                (click)="onSecondaryAction()">
            {{ secondaryActionText }}
        </button>
    </div>
</div>

