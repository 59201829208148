import { Component, ElementRef, Input, OnChanges } from '@angular/core';
import { ControlContainer, Validators, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.css']
})
export class PersonalInformationComponent implements OnChanges {

  @Input()
  personalDataConfig: any;
  onlyLetters = '^[a-za-zA-Z][a-z\s]*$';
  defaultValidators: ValidatorFn[] = [];
  hideName = false;
  hideLastName = false;
  hideMotherLastName = false;
  hideBirthday = false;

  @Input() viewElementRef: ElementRef<any>;

  constructor(public container: ControlContainer) {}

  ngOnChanges(changes): void {
    if (changes.personalDataConfig) {
      this.formConfig(changes.personalDataConfig);
    }
  }

  get invalidName(): boolean {
    return this.container.control.get('name').invalid && this.container.control.get('name').touched;
  }

  get invalidLastName(): boolean {
    return this.container.control.get('lastName').invalid && this.container.control.get('lastName').touched;
  }

  get invalidMotherLastName(): boolean {
    return this.container.control.get('motherLastName').invalid && this.container.control.get('motherLastName').touched;
  }

  get invalidBirthday(): boolean {
    return this.container.control.get('birthday').invalid && this.container.control.get('birthday').touched;
  }

  formConfig({ currentValue }): void {

    if (!Object.keys(currentValue).length) {
      return;
    }

    const { name, lastName, motherLastName, birthday, account } = currentValue;

    this.controlsConfig(currentValue);

    const customerName = name.value || account.name;
    const customerLastName = lastName.value || account.lastName;
    const customerMotherLastName = motherLastName.value || account.motherLastName;

    let formatBirtday = '';

    console.log(birthday);

    if (birthday.value !== undefined) {
      formatBirtday = birthday.value;
    }

    this.container.control.setValue({
      name: customerName || null,
      lastName: customerLastName || null,
      motherLastName: customerMotherLastName || null,
      birthday: formatBirtday || null
    });
  }

  controlsConfig(fields): void {
    const filteredFields = Object.keys(fields)
      .filter(value => value !== 'personalTotalToHide');

    console.log(fields);

    Object.values(filteredFields).forEach(field => {
      const control = this.container.control;

      this.defaultValidators = [
        Validators.minLength(3),
        Validators.maxLength(80),
        // Validators.pattern(this.onlyLetters)
      ];

      // Hidden fields
      if (fields[field].required === 0) {
        control.get(field).disable();
        const hiddenField = 'hide' + field.replace(/\b\w/g, l => l.toUpperCase());
        this[hiddenField] = true;
      }

      // Optional fields
      if (fields[field].required === 1) {
        control.get(field).setValidators(this.defaultValidators);
      }

      // Require fileds
      if (fields[field].required === 2) {
        this.defaultValidators.push(Validators.required);

        control.get(field).setValidators([...new Set(this.defaultValidators)]);
      }

      // Read only fields
      if (fields[field].required === 3) {
        control.get(field).disable();
      }

      this.container.control.updateValueAndValidity();
    });
  }
}
