import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DashboardService, MatToolbarInterface} from '../../dashboard.service';
import {Router, NavigationEnd} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {SaldosMovimientosService} from './saldos-movimientos.service';
import {EmployeeData} from '../../models/Employee.model';
import {CardActivationDialogComponent} from './components/card-activation-dialog/card-activation-dialog.component';
import * as jwt from 'jwt-decode';
import {AccessToken} from 'src/app/models/accessToken';
import {AppTitleService} from '../../app-title.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-saldos-movimientos',
    templateUrl: './saldos-movimientos.component.html',
    styleUrls: ['./saldos-movimientos.component.scss'],
    animations: [
        trigger('fadeSlideInOut', [
            transition(':enter', [
                style({opacity: 0, transform: 'translateY(30px)'}),
                animate('300ms', style({opacity: 1, transform: 'translateY(0)'})),
            ]),
            transition(':leave', [
                animate('300ms', style({opacity: 0, transform: 'translateY(30px)'})),
            ]),
        ])
    ]
})
export class SaldosMovimientosComponent implements OnInit, OnDestroy {

    matMenuState: MatToolbarInterface;
    // matElevationValue: string;
    displayedColumns = ['company', 'employee', 'name', 'balance', 'movements', 'cards'];
    employees = [];
    company: any = {};
    dataSource: MatTableDataSource<EmployeeData>;
    mySubscription: any;
    // accessToken: AccessToken;

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private _dashboardService: DashboardService,
        private _router: Router,
        private _saldosMovimientosService: SaldosMovimientosService,
        private _matDialog: MatDialog,
        private titleService: AppTitleService
    ) {

        if (localStorage.getItem('company-data') === null) {
            this._router.navigate(['/dashboard/cards']);
        }

        this._router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.mySubscription = this._router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._router.navigated = false;
            }
        });

        this.company = JSON.parse(localStorage.getItem('company-data'));

        // this.accessToken = jwt<AccessToken>(sessionStorage.getItem('access_token'));

        const title = this.company.company;

        this.matMenuState = {
            title: title,
            action: 'back',
            color: 'warn',
            icon: 'arrow_back',
            isButtonVisible: true,
            route: '/dashboard/tarjetas'
        };

        this._dashboardService.matMenuObs$.next(this.matMenuState);
        this._dashboardService.executeFn$.subscribe(action => {
            this.back(action);
        });

        this.titleService.setTitle(title);
    }

    ngOnInit() {}

    ngOnDestroy(): void {
        if (this.mySubscription) {
            this.mySubscription.unsubscribe();
        }
    }

    back(route: string) {
        this._router.navigateByUrl(route);
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }

    public handlePageBottom(event: PageEvent) {
        this.paginator.pageSize = event.pageSize;
        this.paginator.pageIndex = event.pageIndex;
        this.paginator.page.emit(event);
    }

    getFilterEmployees(filters: any) {
        filters.company_id = this.company.company_id;

        this._saldosMovimientosService.getFilterEmployees(filters).subscribe(
            response => {
                this.employees = response.data;

                this.dataSource = new MatTableDataSource(this.employees);

                this.dataSource.paginator = this.paginator;
            },
            _ => {
                this.employees = [];
            }
        );
    }

    openCardActivationDialog(row: any) {
        const dialogConfig = new MatDialogConfig();

        const data = {
            // tslint:disable-next-line: radix
            customer_id: parseInt(row.customer_id),
            email: row.email,
            phone: row.phone
        };

        dialogConfig.data = data;
        dialogConfig.disableClose = true;
        dialogConfig.width = '335px';

        this._matDialog.open(CardActivationDialogComponent, dialogConfig);
    }
}
