<div class="my-3">
    <form [formGroup]="container.control">
        <div class="row justify-content-center mb-3">
            <div class="col-8">
                <mat-label>Ingrese sus datos de fiscales</mat-label>
            </div>
        </div>

        <div class="row justify-content-center mb-3">
            <div class="col-8">
                <div class="form-group">
                    <input type="text"
                        id="rfc"
                        class="form-control form-control-lg text-center"
                        placeholder="RFC"
                        formControlName="rfc"
                        [class.is-invalid]="invalidRfc"
                        [hidden]="hideRfc"
                    />
                    <small class="text-danger" *ngIf="invalidRfc">
                        <img src="../../assets/img/error-circle-100.svg" 
                            width="32" 
                            height="32" 
                            alt="Account Error">
                        RFC invalido
                    </small>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mb-3">
            <div class="col-8">
                <div class="form-group">
                    <input type="text"
                        id="curp"
                        class="form-control form-control-lg text-center"
                        placeholder="CURP"
                        formControlName="curp"
                        [class.is-invalid]="invalidCurp"
                        [hidden]="hideCurp"
                    />
                    <small class="text-danger" *ngIf="invalidCurp">
                        <img src="../../assets/img/error-circle-100.svg" 
                            width="32" 
                            height="32" 
                            alt="Account Error">
                        CURP invalida
                    </small>
                </div>
            </div>
        </div>
    </form>
</div>
