import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MainRouteMatch } from '../consts/route.stages';

@Injectable({
  providedIn: 'root'
})
export class MenuStateService implements OnDestroy {
    private menuStateSubject =
        new BehaviorSubject<{ isOnMainRoute: boolean }>(
            { isOnMainRoute: true }
        );
    public menuStateChanges = this.menuStateSubject.asObservable();

    // Router sub
    private routerSubscription: Subscription;

    constructor(private router: Router) {
        this.routerSubscription = this.router.events.subscribe(route => {
            if (route instanceof NavigationEnd) {
                // console.log(route);
                const isOnMainRoute = this.checkRoute(route.url);

                this.menuStateSubject.next({ isOnMainRoute });
            }
        });
    }

    ngOnDestroy(): void {
        this.menuStateSubject.unsubscribe();
        this.routerSubscription.unsubscribe();
    }

    private checkRoute(currentRoute: string): boolean {
        const isMainRoute = MainRouteMatch(currentRoute);
        return isMainRoute;
    }
}
