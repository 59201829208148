<div class="personal-info-container container pt-4">
    <div class="personal-info-header">
        <div class="ms-3" [hidden]="externalInstance">
            <h5 class="app-module-title m-0">
                Datos personales
            </h5>
            <p class="small-label m-0">Aquí puedes administrar tus datos personales.</p>
        </div>
        <div class="ms-3" *ngIf="externalInstance">
            <h3 class="app-module-title m-0">
                <strong>Por favor completa los siguientes datos</strong>
            </h3>
            <p class="small-label m-0 pt-2">Se requiere para procesar tu pago y personalizar tu experiencia al utilizar Scan & Go o Comprar en tienda.</p>
        </div>
    </div>
    <div class="personal-info-content" *ngIf="personal; else errorTemplate">
        <div class="profile-subgroup">
            <div class="profile-subgroup-item"
                 [hidden]="personal.req_nombre === 0 || hideName">
                <app-profile-info-item
                    title="Nombre"
                    [value]="personal.nombre"
                    [payload]="personal"
                    [scope]="personal.req_nombre"
                    [control]="nameControl"
                    identifier="nombre"
                    [config]="configurations.nombre">
                </app-profile-info-item>
            </div>
            <div class="profile-subgroup-item"
                 [hidden]="personal.req_ap_pat === 0 || hideLastName">
                <app-profile-info-item
                                       title="Apellidos"
                                       [value]="personal.ap_paterno"
                                       [payload]="personal"
                                       [scope]="personal.req_ap_pat"
                                       [control]="lastNameControl"
                                       identifier="ap_paterno"
                                       [config]="configurations.ap_paterno">
                </app-profile-info-item>
            </div>
            <div class="profile-subgroup-item"
                 [hidden]="personal.req_ap_mat === 0 || hideLastName2">
                <app-profile-info-item
                                       title="Apellido Materno"
                                       [value]="personal.ap_materno"
                                       [payload]="personal"
                                       [scope]="personal.req_ap_mat"
                                       [control]="lastName2Control"
                                       identifier="ap_materno"
                                       [config]="configurations.ap_materno">
                </app-profile-info-item>
            </div>
            <div class="profile-subgroup-item"
                 [hidden]="personal.req_rfc === 0 || hideRfc">
                <app-profile-info-item
                                       title="RFC"
                                       [value]="personal.rfc"
                                       [payload]="personal"
                                       [scope]="personal.req_rfc"
                                       [control]="rfcControl"
                                       identifier="rfc"
                                       [config]="configurations.rfc">
                </app-profile-info-item>
            </div>
            <div class="profile-subgroup-item"
                 [hidden]="personal.req_f_nacimiento === 0 || hideDob">
                <app-profile-info-item
                                       title="Fecha de nacimiento"
                                       type="date"
                                       [value]="personal.f_nacimiento"
                                       [payload]="personal"
                                       [scope]="personal.req_f_nacimiento"
                                       [control]="dobControl"
                                       identifier="f_nacimiento"
                                       [config]="configurations.f_nacimiento">
                </app-profile-info-item>
            </div>
            <div class="profile-subgroup-item"
                 [hidden]="personal.req_nss === 0">
                <app-profile-info-item #ssn
                                       title="Numero de seguridad social"
                                       [value]="personal.nss"
                                       [payload]="personal"
                                       [scope]="personal.req_nss"
                                       identifier="nss"
                                       [config]="configurations.nss">
                </app-profile-info-item>
            </div>
            <div class="profile-subgroup-item"
                 [hidden]="personal.req_curp === 0 || hideCurp">
                <app-profile-info-item
                                       title="CURP"
                                       [value]="personal.curp"
                                       [payload]="personal"
                                       [scope]="personal.req_curp"
                                       [control]="curpControl"
                                       identifier="curp"
                                       [config]="configurations.curp">
                </app-profile-info-item>
            </div>
        </div>
    </div>
    <div class="personal-info-bottom">
        <button mat-flat-button
                class="role-action-btn w-100"
                    (click)="confirmData()">
            Confirmar
        </button>
    </div>
    <ng-template #errorTemplate>
        <div class="no-tickets-layout">
            <div>
                <!--                <img class="mb-3"-->
                <!--                     src="../../assets/img/empty-tickets.svg"-->
                <!--                     width="128"-->
                <!--                     height="128"-->
                <!--                     alt="">-->
                <h5 class="app-module-title">
                    Ocurrio un problema al cargar esta pagina
                </h5>
                <p class="app-module-subtitle small-label m-0">
                    Quiza debas volver a iniciar sesion.
                </p>
            </div>
        </div>
    </ng-template>
</div>
