<div class="my-3 w-100">
    <form [formGroup]="container.control">
        <!-- <div>
            <div class="mb-3">
                <mat-label class="small-label">
                    Ingrese los datos de la tarjeta a vincular
                </mat-label>
            </div>
        </div> -->
        <div class="form-responsive">
            <div>
                <app-responsive-input id="cardNumber"
                    controlName="cardNumber"
                    type="text"
                    placeholder="Numero de tarjeta o empleado"
                    [showIcon]="false"
                    [enableLabel]="true"
                    labelText="Numero de tarjeta"
                    [enableEnterEvent]="true"
                    [viewElementRef]="viewElementRef"
                    (enter)="onEnterEvent()">
                </app-responsive-input>
                <mat-error class="text-danger" *ngIf="invalidCard">
                    <img src="../../assets/img/error-circle-100.svg"
                        width="32"
                        height="32"
                        alt="Account Error">
                    Número de tarjeta invalido.
                </mat-error>
                <div class="d-flex pt-3">
                    <span class="material-symbols-rounded primary-icon me-3">{{ cardIcon }}</span>
                    <p class="small-text" *ngIf="cardType !== '' && container.control.valid">
                        Tarjeta de {{ cardType }}: <strong>{{ cardNumber | mask: mask }}</strong>
                    </p>
                </div>
            </div>
        </div>
    </form>
</div>
