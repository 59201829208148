import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import {Observable, of, forkJoin } from 'rxjs';
import { ProfileService } from '../../profile.service';
import {ProfileItemModelService} from '../../profile-item-model.service';
import {catchError, map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProfileContactResolverService implements Resolve<any> {
    constructor(
        private profileService: ProfileService,
        private profileModelService: ProfileItemModelService,
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return forkJoin({
                emailResponse: this.profileService.emailAPI('SL', null)
                    .pipe(catchError(err => of(err))),
                emailConfigResponse: this.profileModelService.getProfileModel('email')
                    .pipe(catchError(err => of(err))),
                phoneResponse: this.profileService.phoneAPI('SL', null)
                    .pipe(catchError(err => of(err))),
                phoneConfigResponse: this.profileModelService.getProfileModel('telefono')
                    .pipe(catchError(err => of(err))),
        });
    }
}
